<table class="table table-hover">
  <thead>
  <tr>
	<th scope="col">Name</th>
	<th scope="col">Display Name</th>
	<th scope="col">Description</th>
	<th scope="col">Display Order</th>
	<th scope="col">URL</th>
	<th scope="col">Media</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let prod of products" (click)='selectProduct(prod)' [class]="prod == selectedProduct ? 'selected' : ''">
	<td>{{prod.name}}</td>
	<td>{{prod.displayName}}</td>
	<td>{{prod.description}}</td>
	<td>{{prod.display_order}}</td>
	<td>{{prod.video_url}}</td>
	<td>{{prod.media}}</td>
  </tr>
  </tbody>
</table>
