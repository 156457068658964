import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppFacade } from '../../../facade/app.facade';
import { ISession, ISessionSummary } from '@common/model/session';
import { ViewSummariesComponent } from '../../components/view-summaries/view-summaries.component';
import { NativeUtil } from './../../../utils/native';
import { NavService } from '../../../service/nav.service';
import { EVCAlertService } from '../../../service/alert.service';
import { UtilService } from '@common/service/util.service';

@Component({
    selector: 'evc-client-footer',
    templateUrl: './client-footer.component.html',
    styleUrls: ['./client-footer.component.scss']
})
export class ClientFooterComponent implements OnInit {

    session: ISession;
    sessionSub: Subscription;

    summaryCount = 0;
    summaryList: ISessionSummary[] = [];
    firstSummary: ISessionSummary;

    loading: boolean = false;

    constructor(
        private modalService: NgbModal,
        private appFacade: AppFacade,
        private alertService: EVCAlertService,
        private navService: NavService
    ) { }

    ngOnInit(): void {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: ISession) => { this.setSession(session); }
        );
    }

    setSession(session: ISession) {
        console.log("setSession: ", session);
        this.session = session;

        if (this.session && this.session.submittedSessions) {
            this.summaryList = this.session.submittedSessions;
            this.firstSummary = this.summaryList && this.summaryList.length > 0 ? this.summaryList[0] : null;
            this.summaryCount = this.summaryList.length;
            //this.parseSummaryList(this.summaryList);
        } else {
            this.summaryList = [];
            this.summaryCount = 0;
        }
        console.log("summaryCount=", this.summaryCount);
    }

    viewSummaries() {
        console.log("viewSummaries");
        var modalRef = this.modalService.open(ViewSummariesComponent, {
            size: 'xl',
            //scrollable: true,
            windowClass: 'viewsum fixed',
            centered: true
        });

        modalRef.result.then((result) => {
            console.log("View Summaries modal closed: ", result);
        }, (reason) => {
            console.log("View Summaries modal dismissed", reason);
        });

    }

    getPDF(event: any, sessSummary: ISessionSummary, lang: 'en-US' | 'en-CA' | 'fr-CA') {
        console.log("getPDF: ", lang, sessSummary);
        console.log("click event: ", JSON.stringify(event, null, 2));

        this.loading = true;

        this.appFacade.getPDF(sessSummary.evc_session_id, lang)
            .subscribe(async v => {
                console.log("getPDF result: ", v);
                if (v.success) {
                    await NativeUtil.download({
                        base64data: v.data.pdf,
                        filename: v.data.filename,
                        contentType: 'application/pdf',
                        element: event.target//this.downloadButton.nativeElement
                    });
                } else {
                    this.alertService.showError("Error generating PDF for session: ", UtilService.getErrorMessage(v.error));
                }
                this.loading = false;
            });
    }

    goHome() {
        console.log("goHome");

        this.navService.goHome();
    }


}
