import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as rxjs from 'rxjs';
import { tap, mapTo, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";

import { IUser } from '@common/model/user';
import { IAuthResp, IAuthInfo } from '@common/model/service/auth-service';

const JWT_TOKEN = 'jwt_token';
const USER_DATA = 'user_data';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /*
      jwtToken contains the salesforce refresh_token, access_token, instance_url that are needed to make back-end calls to Salesforce in addition to being used to confirm the user is logged in.
      The first time a user hits the site, they won't have the jwt.  Standard login process will be followed (oauth web app handshake with Salesforce for sso).
      As the user is using the app, the jwt will be updated to keep them logged in as long as they're using the app.  This is done by monitoring for 401 (unauthorized) errors, upon which it refreshes the jwt.
      When a user re-visits the site/refreshes the browser, the jwt will be passed to the backend in an attempt to re-login the user using the refresh_token from Salesforce.  If this works, they're returned an updated jwt and are considered logged in.
      If the refresh fails, the user is re-directed to the standard Salesforce SSO login page.
    */

    private jwtToken: string; // jwt token
    private userData: IUser;

    private permissions: string[];

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        console.log("AuthService constructor");

        window.addEventListener('storage', this.syncLogout);

    }

    logout() {
        console.warn("logout");
        return this.http.post<any>('/api/auth/logout', {
        }).pipe(
            tap(() => this.doLogoutUser()),
            mapTo(true),
            catchError(error => {
                alert(error.error);
                return rxjs.of(false);
            }));

    }

    private doLogoutUser() {
        console.log("doLogoutUser");

        delete this.jwtToken;
        delete this.userData;
        this.removeTokens();

        window.localStorage.setItem('logout', Date.now().toString())

        this.router.navigate(['/home']);
    }

    private removeTokens() {
        localStorage.removeItem(JWT_TOKEN);
        localStorage.removeItem(USER_DATA);
    }

    public isLoggedIn(): boolean {
        //console.log("isLoggedIn");
        var isLoggedIn = false;

        var userData = this.userData,
            jwtToken = this.getToken();

        if (userData == null) {
            console.log("No userData available, user is not logged in.");
            isLoggedIn = false;
        } else {
            var jwtHelper = new JwtHelperService();
            if (jwtHelper.isTokenExpired(jwtToken)) {
                console.log("jwt is expired, user is not logged in.");
                isLoggedIn = false;
            } else {
                //console.log("jwt is valid, user is logged in");
                isLoggedIn = true;
            }
        }

        return isLoggedIn;

    }

    public isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    public loginWithCode(code: string, targetRoute?: string): Observable<IAuthResp> {
        console.log('loginWithCode: ', code, targetRoute);

        return this.http.post<IAuthResp>('/api/auth/code', { authCode: code })
            .pipe(
                tap((resp: IAuthResp) => {
                    // Returns success, jwt_token, user_data
                    console.log("Results of call to /auth/code: ", resp);

                    if (!resp || resp.success != true) {
                        console.error("Login failed: ", resp);
                        this.router.navigate(['/auth/no_access']);
                        //                        this.logout();
                        return;
                    } else if (targetRoute) {
                        this.router.navigate([targetRoute]);
                    }

                    this.setAuthSession(resp.authInfo);

                }),
                mapTo(true),
                catchError((err: Error) => {
                    console.error('Error calling loginWithCode: ', err);
                    alert(JSON.stringify(err, null, 2));//err.error);
                    //TODO: Show error to user.  Login failed.
                    return rxjs.of(null);
                }));
    }

    private setAuthSession(authResult: IAuthInfo) {
        //console.log("setAuthSession: ", authResult);

        const jwtToken = authResult.jwt_token,
            userData = authResult.user,
            perms = authResult.permissions;

        //console.log("jwtToken=", jwtToken);

        localStorage.setItem(USER_DATA, JSON.stringify(userData));
        localStorage.setItem(JWT_TOKEN, JSON.stringify(jwtToken));
        this.jwtToken = jwtToken;
        this.userData = userData;

        this.permissions = perms;
    }

    getToken(): string {
        if (!this.jwtToken) {
            var token = localStorage.getItem(JWT_TOKEN);
            if (token) {
                this.jwtToken = JSON.parse(token);
            }
        }
        return this.jwtToken;
    }

    public getUserDetails(): IUser {
        // console.log("getUserDetails");
        const userDataStr = localStorage.getItem(USER_DATA);
        var decoded = userDataStr ? JSON.parse(userDataStr) : null;
        // console.log("decoded=", decoded);
        return decoded;
    }

    /*
     * User is currently not logged in.
     * If a refresh token is not available, show the standard login popup.
     * Else first try to login using the refresh token.
     * Failing that (refresh token is expired), fall back to standard login.
     */
    showLogin(targetUrl?: string) {
        console.log("showLogin: ", targetUrl);

        console.log("Redirecting to idp: ", '/api/auth/idp');
        window.location.href = '/api/auth/idp';

    }

    /*
     * User is currently not logged in.
     * If a refresh token is not available, show the standard login popup.
     * Else first try to login using the refresh token.
     * Failing that (refresh token is expired), fall back to standard login.
     */
    doLogin(targetUrl?: string) {
        console.log("doLogin: ", targetUrl);

        let me = this,
            jwtToken = this.getToken();

        if (!jwtToken) {
            console.warn("No jwtToken available, going standard login route...");
            this.showLogin(targetUrl);
            return;
        }

        this.callRefreshToken(targetUrl).subscribe(
            (resp: IAuthResp) => {
                console.log("callRefreshToken returned: ", resp);
                if (!resp || resp.success != true) {
                    console.log("jwtToken failed, going standard login route...");
                    me.showLogin(targetUrl);
                    return;
                }
            },
            (err: Error) => {
                console.log("refreshToken threw error, going standard login route...", err);
                me.showLogin(targetUrl);
                return;
            }
        );
    }

    // Handles user logging out with multiple tabs open...
    private syncLogout(event: any) {
        if (event.key === 'logout') {
            console.log('logged out from storage!')
            //TODO: Router.push('/login')
        }
    }

    public callRefreshToken(targetUrl?: string): Observable<IAuthResp> {
        console.log("callRefreshToken");

        return this.http.get<IAuthResp>('/api/auth/refresh_token')
            .pipe(
                tap((refreshResp: IAuthResp) => {
                    console.log("refreshResp=", refreshResp);

                    // Handle user notification when refresh path fails.
                    // Likely just make them login again...
                    if (!refreshResp || refreshResp.success != true) {
                        console.error("refreshToken failed: ", refreshResp);
                        this.logout();
                        return;
                    }

                    this.setAuthSession(refreshResp.authInfo);

                    if (targetUrl) {
                        console.log("refreshToken succeeded.  Redirecting to target url: ", targetUrl);
                        this.router.navigate([targetUrl]);
                    }

                }),
                catchError(error => {
                    console.log("catchError: ", error);
                    return null;
                }));
    }

    public hasAccess(permName: string): boolean {
        console.log("hasAccess: " + permName);
        if (this.permissions && this.permissions.find(perm => perm == permName)) {
            console.log("true");
            return true;
        }
        console.log("false");
        return false;
    }

}
