<div class="viewsum-modal">
  <evc-loading-indicator msg="Downloading..." [loading]="loading"></evc-loading-indicator>
  <header class="viewsum-header" >
	  <button class="cancel-button"
			  title="Close"
			  (click)="closeModal()">
		    <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
			  <title>Close</title>
			  <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#cancel"></use>
			</svg>
	  </button>
  </header>

  <div class="viewsum-modal-body">
    <span class="viewsum-table-body">
	  <table class="spot-data-table spot-data-table--clickable" style="width:100%;">
		<thead>
		  <tr style="border-bottom: 1px solid black">
			<th>Submission Date</th>
			<th>IDEXX Representative</th>
			<th></th>
		  </tr>
		</thead>
		<tbody>
		  <tr *ngFor="let sum of summaryList" (click)="selectSummary(sum)">
			<td>
			  {{sum.displayName}}
			</td>
			<td>
			  {{sum.created_by.name}}
			</td>
			<td>
			  <ng-container *ngIf="session.isCanada == true">
				<a class="spot-button spot-button--link" (click)="getPDF($event, sum, 'en-CA')">English</a>
				|
				<a class="spot-button spot-button--link" (click)="getPDF($event, sum, 'fr-CA')">French</a>
			  </ng-container>
			  <ng-container *ngIf="session.isCanada != true">
				<a class="spot-button spot-button--link" (click)="getPDF($event, sum, 'en-US')">View/download</a>
			  </ng-container>
			</td>
		  </tr>
		</tbody>
	  </table>
	</span>
  </div>
  <footer class="viewsum-footer">
    <span class="viewsum-footer-sapid">
      <div class="viewsum-footer-label">SAP ID:</div>
      <div class="viewsum-footer-value">{{session?.account?.sap_id}}</div>
    </span>
    <span class="viewsum-footer-practicename">
      <div class="viewsum-footer-label">Practice name:</div>
      <div class="viewsum-footer-value">{{session?.account?.name}}</div>
    </span>
    <span class="viewsum-footer-location">
      <div class="viewsum-footer-label">Location:</div>
      <div class="viewsum-footer-value">{{session?.account?.city}}<ng-container *ngIf="session && session.account && (session.account.city && session.account.state)">,</ng-container>{{session?.account?.state}}</div>
    </span>
  </footer>
</div>

