import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminFacade } from '../../../facade/admin.facade';

import { EVCAlertService } from '../../../service/alert.service';

import { IProduct } from '@common/model/product';
import { IActivity } from '@common/model/activity';
import { IProductDetails } from '@common/model/product-details';

@Component({
    selector: 'evc-edit-product',
    templateUrl: './edit-product.component.html',
    styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit, AfterViewInit {

    @ViewChild('productForm', { static: false }) productForm: NgForm;
    product: IProduct;

    saveEnabled: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alertService: EVCAlertService,
        private adminFacade: AdminFacade,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            console.log("params here: ", params);
            var prodId = params.prodId;
            console.log("prodId=", prodId);
            this.getProduct(prodId);
        });

        if (!this.product) { // For testing
            this.product = {};
        }

    }

    ngAfterViewInit() {
        //console.log("this.productForm=", this.productForm);
        var me = this;
        if (this.productForm) {
            this.productForm.statusChanges.subscribe(status => {
                //console.log("productForm status change: ", status);
                this.updateButtons();
            });

            this.productForm.valueChanges.subscribe(value => {
                console.log("value=", value);
                //this.checkDups();
                //console.log("productForm value change: ", value);
                me.findInvalidControls();
            });
        } else {
            console.error("this.productForm doesn't exist!");
        }
    }

    async getProduct(prodId: number): Promise<IProduct> {
        this.alertService.setBusy(true, 'Loading product...');
        try {
            var prodResp = await this.adminFacade.getProduct(prodId);
            if (prodResp && prodResp.success == true) {
                this.product = prodResp.product;
            } else {
                console.warn("Error getting product here: ", prodResp);
            }
            return this.product;
        } catch (err) {
            console.error("Error getting product: ", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    updateButtons() {
        var valid = this.productForm.form.valid;
        // console.log("valid errors: ", this.productForm.form.errors);
        // console.log("this.productForm.form.status=", this.productForm.form.status);
        // console.log("this.productForm.hasError=", this.productForm.hasError);
        // console.log("this.productForm.invalid=", this.productForm.invalid);
        // console.log("this.productForm.options=", this.productForm.options);

        console.log("form valid: " + valid);
        valid = valid && (this.product != null);

        this.saveEnabled = valid;
    }

    saveProduct() {
        console.log("TODO: saveProduct");
    }

    cancel() {
        console.log("TODO: cancel");
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.productForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log("invalid=", invalid);
        return invalid;
    }

    public editActivity(act: IActivity) {
        console.log("editActivity", act);
        this.router.navigate(['/admin/edit-activity', act.evc_activity_id]);
    }

    public editProductDetails(med: IProductDetails) {
        console.log("editProductDetails", med);

        this.router.navigate(['/admin/product-details', med.evc_product_detail_id]);

    }
}
