import { IProductInfo } from './session-product';
import { IActivityInfo } from './session-activity';
import { IAccount } from './account';
import { IAccountSettings } from './account-settings';
//import { DateLiteral } from './../type/date-literal';
import { IUser } from './user';
import { IAccountResponse } from './service/account-service';
import { isDate, format, parseISO } from 'date-fns';

export const DEFAULT_LOCALE = 'en-US';

export interface ISession {
    evc_session_id?: number;
    sap_id?: string;
    account_id?: string; // Salesforce id
    account?: IAccount;
    account_settings?: IAccountSettings;

    products?: IProductInfo[];
    lastUpdated?: Date;

    valid?: boolean;

    selectedProducts?: IProductInfo[];
    selectedProduct?: IProductInfo;

    profitGrowth: number;

    //userEmail?: string;
    hasSellProducts?: boolean;

    hasProducts?: boolean;

    isCanada?: boolean;
    locale?: string;
    currencyCd?: string;
    currencyMaskOptions?: any;

    totalSavings?: number;
    totalPetsHelped?: number;

    submitDate?: Date;

    submitting?: boolean;

    marked_for_deletion?: boolean;

    user?: IUser;

    created_by?: IUser;
    created_on?: Date;
    updated_by?: IUser;
    updated_on?: Date;

    showProfit?: boolean;

    growthPercent: number;
    growthYears: number;

    submittedSessions?: ISessionSummary[];

    metaInfo?: ISessionMeta;

}

export interface ISessionSummary {
    evc_session_id?: number;
    sap_id?: string;
    account?: IAccount;
    locale?: string;
    submitDate?: Date;
    displayName?: string;

    created_by?: IUser;
    created_on?: Date;
    updated_by?: IUser;
    updated_on?: Date;

    metaInfo?: ISessionMeta;
}

export class Session implements ISession {
    public evc_session_id?: number;

    public sap_id?: string;

    public account_id?: string; // Salesforce id

    public account?: IAccount;

    public account_settings?: IAccountSettings;

    public products?: IProductInfo[] = [];

    public lastUpdated?: Date;

    public valid?: boolean;

    public selectedProducts?: IProductInfo[];

    public selectedProduct?: IProductInfo;

    public profitGrowth: number = 0;

    //userEmail?: string;
    public currencyCd?: string;

    public hasSellProducts?: boolean;

    public hasProducts?: boolean;

    public isCanada?: boolean;

    public locale?: string;

    public currencyMaskOptions: any = {
        "align": "right",
        "allowNegative": true,
        "allowZero": true,
        "decimal": ".",
        "precision": 2,
        "prefix": "$ ",
        "suffix": "",
        "thousands": ",",
        "nullable": true
    };

    public totalSavings?: number;

    public totalPetsHelped?: number;

    public submitDate?: Date;

    public submitting?: boolean;

    public marked_for_deletion: boolean = false;

    public user?: IUser;

    public created_by?: IUser;

    public created_on?: Date;

    public updated_by?: IUser;

    public updated_on?: Date;

    public showProfit?: boolean;

    public growthPercent: number = 5;

    public growthYears: number = 6;

    public submittedSessions?: ISessionSummary[];

    public metaInfo?: ISessionMeta;

    public constructor(acctResp?: IAccountResponse, locale?: string, oldSession?: ISession) {
        console.log('New session');//, acctResp);
        if (!acctResp || acctResp.success != true) {
            console.warn('No account info.  Empty session: ', acctResp);
            return;
        }

        this.account = acctResp.account;
        this.products = [];
        if (acctResp.usageData) {
            if (acctResp.usageData.productUsage) {
                this.products = acctResp.usageData.productUsage;
            }
            this.lastUpdated = acctResp.usageData.lastUpdated;
        }
        this.account_id = this.account.sf_id;
        this.account_settings = acctResp.account_settings;
        this.user = acctResp.user;
        //this.userEmail = acctResp.user.email;
        this.currencyCd = this.account.currency_cd;
        this.locale = locale;

        this.selectedProducts = [];

        this.sap_id = this.account.sap_id;

        this.isCanada = SessionUtils.isCanada(this);

        if (oldSession) {
            this.retrieveSession(oldSession);
        }

        this.submittedSessions = this.parseSubmittedSessions(acctResp.submittedSessions);

        //console.log('startSession: initial session=', this);
    }

    /**
     * Parse the list of submitted sessions and add (1) (2), etc
     * if more than one session was created in a day.
     */
    private parseSubmittedSessions(summaryList: ISessionSummary[]): ISessionSummary[] {
        console.log("parseSummaryList");//: ", summaryList);
        if (!summaryList) {
            return summaryList;
        }
        const dateMap = {};
        for (let sum of summaryList) {
            const dateStr = this.getDateString(sum.submitDate);
            console.log("sum.submitDate=", sum.submitDate);
            console.log("dateStr=", dateStr);
            if (!dateMap[dateStr]) {
                dateMap[dateStr] = [];
            }
            dateMap[dateStr].push(sum);
            sum.displayName = dateStr;
        }
        for (let dateStr of Object.keys(dateMap)) {
            const list = dateMap[dateStr];
            if (list.length > 1) {
                for (let ix = 0; ix < list.length; ix++) {
                    list[ix].displayName = dateStr + " (" + (ix + 1) + ")";
                }
            }
        }

        return summaryList;
    }

    public getDateString(value: Date | string): string {
        // 'MMM d, y, h:mm:ss a'
        const dt = this.toDate(value);
        if (dt) {
            return format(dt, 'MMM d, y, h, h:mm:ss a');
        }
        console.error("Invalid date: ", value);
        return '';
    }

    private toDate(value: Date | string): Date | null {
        if (isDate(value)) {
            return <Date>value;
        }
        if (typeof value === 'string') {
            return parseISO(value);
        }
        return null;
    }

    private retrieveSession(oldSession: ISession): void {
        console.log('retrieveSession');//, oldSession);

        //TODO: Set all the session fields...
        this.evc_session_id = oldSession.evc_session_id;
        this.sap_id = oldSession.sap_id;
        this.account_id = oldSession.account_id;
        this.profitGrowth = oldSession.profitGrowth;
        this.totalSavings = oldSession.totalSavings;
        this.created_by = oldSession.created_by;
        this.created_on = oldSession.created_on;
        this.updated_by = oldSession.updated_by;
        this.updated_on = oldSession.updated_on;
        this.showProfit = oldSession.showProfit;
        this.growthPercent = oldSession.growthPercent;
        this.growthYears = oldSession.growthYears;
        this.metaInfo = oldSession.metaInfo;
        this.totalPetsHelped = 0;

        for (let oldProd of oldSession.products) {
            //console.log('oldProd=', oldProd);
            let prod = this.products.find(function(p: IProductInfo) {
                return p.evc_product_id == oldProd.evc_product_id;
            });

            if (prod) {
                prod = this.retrieveProduct(oldProd, prod);

                this.selectedProducts.push(prod);

                //TODO: Update other UI things like list of selected products, completed hash, etc.

            } else {
                console.error("Didn't find product in new data: ", oldProd, this.products);
            }
        }

        SessionUtils.calcTotals(this);

        //console.log("At end of retrieveSession: ", this);

    }

    retrieveProduct(savedProd: IProductInfo, newProd: IProductInfo): IProductInfo {
        console.log("retrieveProduct");
        newProd.selected = true;
        newProd.completed = true;
        newProd.evc_session_product_id = savedProd.evc_session_product_id;
        newProd.evc_session_id = savedProd.evc_session_id;
        newProd.totalRuns = savedProd.totalRuns;
        newProd.runsPerMonth = savedProd.runsPerMonth;
        newProd.done = savedProd.done;
        newProd.lastUpdated = savedProd.lastUpdated;

        for (let oldAct of savedProd.activities) {
            let act = newProd.activities.find(function(a: IActivityInfo) {
                return a.evc_activity_id == oldAct.evc_activity_id;
            });

            if (act) {
                this.retrieveActivity(oldAct, act);
            }
        }

        //TODO: Update other UI things like list of selected products, completed hash, etc.
        return newProd;
    }

    retrieveActivity(savedAct: IActivityInfo, newAct: IActivityInfo): IActivityInfo {
        console.log("retrieveActivity");
        newAct.compliance = savedAct.compliance;
        newAct.evc_session_product_activity_id = savedAct.evc_session_product_activity_id;
        newAct.evc_session_product_id = savedAct.evc_session_product_id;
        newAct.savings = savedAct.savings;
        //newAct.totalProfit = savedAct.totalProfit;
        newAct.totalRevenue = savedAct.totalRevenue;
        newAct.totalCost = savedAct.totalCost;
        return newAct;
    }
}

export interface ISessionMeta {
    session_meta_id: number;
    evc_session_id: number;

    s3_etag?: string;
    s3_filename?: string;
    s3_error?: string;

    mail_id?: string;
    mail_error?: string;
}

export const SessionUtils = {
    /**
     * Update session info after a successful save call.
     */
    updateSession(session: ISession, savedSession: ISession): ISession {
        console.log("updateSession");//: ", session, savedSession);

        if (!session) {
            return savedSession;
        }
        if (!savedSession) {
            return session;
        }

        session.evc_session_id = savedSession.evc_session_id;

        //TODO: rest of data
        for (var prd of session.products) {
            const foundProduct: IProductInfo = savedSession.products ? savedSession.products.find((p: IProductInfo): boolean => {
                return p.evc_product_id == prd.evc_product_id;
            }) : null;
            if (foundProduct) {
                prd.evc_session_product_id = foundProduct.evc_session_product_id;
                prd.evc_session_id = foundProduct.evc_session_id;

                if (prd.activities) {
                    for (let act of prd.activities) {
                        const foundAct: IActivityInfo = foundProduct.activities ? foundProduct.activities.find((a: IActivityInfo) => {
                            return a.evc_activity_id == act.evc_activity_id;
                        }) : null;
                        if (foundAct) {
                            act.evc_session_product_activity_id = foundAct.evc_session_product_activity_id;
                        }
                    }
                }
            } else {
                console.error("Didn't find product: ", prd, savedSession.products);
            }
        }

        return session;
    },

    getProduct(session: ISession, prodName: string): IProductInfo {
        console.log("getProduct: ", prodName);
        if (!session) { // For testing
            console.error("Session is null");
            return null;
        }
        let prod = session.products.find(function(pu: IProductInfo) {
            return pu.name == prodName;
        });
        if (!prod) {
            console.error("Product not found: ", prodName, session.products);
        }
        return prod;
    },

    validateSelectedProducts(session: ISession) {
        console.log("validateSelectedProducts");
        // Make sure all selected products are done.  Specifically, if user
        // selects 4dx, make sure they select pathType of defense vs sell
        var selectedProducts = this.getSelectedProducts(session),
            isValid = true;
        if (!selectedProducts || selectedProducts.length < 1) {
            console.log("No selected products here");
            isValid = false;
        }
        //cmp.set("v.isValid", isValid);
        console.log("isValid=" + isValid);
    },

    getSelectedProducts(session: ISession): IProductInfo[] {
        //console.log("getSelectedProducts: ", session);
        if (session != null) {
            //console.log("getSelectedProducts returning: ", JSON.stringify(session.selectedProducts, null, 2));
            var retVal = session.selectedProducts || [];
            // console.log("retVal = ", retVal);
            return retVal;
        }
        console.log("session is null");
        return null;
    },

    // toDateLiteral(d: Date | string): DateLiteral {
    //     console.log("toDateLiteral: ", d, (typeof d));
    //     if (d) {
    //         if (typeof d == "string") {
    //             var dt = DateLiteral.fromStringIgnoreTime(d);
    //             //console.log("dt=", dt);
    //             return dt;
    //         } else {
    //             return this.toDateLiteral(d.toISOString());
    //             // var dl = new DateLiteral();
    //             // dl.setDate(d.getDate());
    //             // console.log("dl=", dl);
    //             // return dl;
    //         }
    //     }
    //     return null;
    // },

    isCanada(session: ISession): boolean {
        var isCanadian = false;
        if (session && session.account) {
            isCanadian = session.account.country_cd == "CA";
        }
        return isCanadian;
    },

    hasSellProducts(session: ISession): boolean {
        if (session && session.products) {
            for (let prod of session.products) {
                return true;
            }
        }
        return false;
    },

    /**
     * Returns the first incomplete product in list of selected products.
     */
    getNextProduct(session: ISession): IProductInfo {
        console.log("getNextProduct");
        //console.trace("here");
        var selectedProducts = this.getSelectedProducts(session),
            prodDef: IProductInfo;

        if (selectedProducts) {
            for (var ix = 0; ix < selectedProducts.length; ix++) {
                console.log("ix=", ix);
                var pDef = selectedProducts[ix];
                if (pDef.done != true) {
                    prodDef = pDef;
                    break;
                }
            }
        }
        //console.log("nextProd = ", prodDef);
        return prodDef;
    },

    calcProdProfit(product: IProductInfo) {
        console.log('calcProdProfit', product ? product.name : null);

        if (!product) {
            console.log("No product selected, not calculating profit...");
            return;
        }

        var activities = product.activities,
            profit = 0,
            profitPer = 0,
            flatProfitPer: number = 0,
            price;

        activities.forEach(function(act: IActivityInfo) {
            var compliance = act.compliance;

            price = Number(act.savings);
            // console.log('compliance=' + compliance);
            // console.log('price=' + price, typeof price);

            //act.price = price; // configured price/savings for this activity
            act.savings = price;
            act.totalProfit = act.savings * product.runsPerMonth * compliance / 100;

            // console.log("act.savings=", act.savings);
            // console.log("act.totalProfit=", act.totalProfit);

            profitPer += parseFloat(act.totalProfit + '');
            flatProfitPer += parseFloat(price + '');
            // console.log("flatProfitPer=", flatProfitPer, typeof flatProfitPer);
        });

        profit = profitPer;// * product.runsPerMonth;

        product.totalSavings = profit;

        product.flatProfitPer = flatProfitPer;

        product.savingsPer = profitPer;

        // console.log("prod.inc_percent=", product.inc_percent);
        // console.log("prod.runsPerMonth=", product.runsPerMonth);
        product.num_pets_helped = product.runsPerMonth * product.inc_percent / 100;
        //console.log("product.num_pets_helped=", product.num_pets_helped);

        //console.log("product after calc: ", product);

    },

    calcTotals(session: ISession) {
        console.log('calcTotals');
        var me = this,
            selectedProducts = this.getSelectedProducts(session),
            totalProfit = 0;

        session.totalPetsHelped = 0;

        selectedProducts.forEach(function(sProd: IProductInfo) {

            // Recalculate the totals for each product if done.
            console.log("sProd.done=" + sProd.done);
            if (sProd.done) {
                me.calcProdProfit(sProd);
            }
            if (sProd.num_pets_helped) {
                session.totalPetsHelped += sProd.num_pets_helped;
            }

            if (sProd.totalSavings) {
                totalProfit += sProd.totalSavings;
            }

            //session.totalPetsHelped += sProd.num_pets_helped;

        });

        // console.log("totalPetsHelped = ", session.totalPetsHelped);
        session.totalPetsHelped = Math.round(session.totalPetsHelped);
        // console.log("totalPetsHelped rounded = ", session.totalPetsHelped);

        // console.log('totalProfit=' + totalProfit);

        session.totalSavings = totalProfit;

        session.profitGrowth = this.calcGrowth(session.growthYears,
            session.growthPercent,
            totalProfit);

        // Update calcs for selected product on screen.
        //this.setSelectedProduct(this.getSelectedProduct());
    },

    calcGrowth(years: number, pct: number, profitMonth: number): number {
        console.log("calcGrowth: ", years, pct, profitMonth);

        // Formula for growth provided by business.
        //var profitGrowth = (totalProfit * 66.307575);
        var percent: number = pct / 100;
        var profitGrowth: number;
        if (percent == 0) {
            profitGrowth = (profitMonth * 12) * years;
        } else {
            profitGrowth = (profitMonth * 12) * (Math.pow((1 + percent), years) - 1) / percent;
        }
        return profitGrowth;
    },

    updateSelectedProducts(session: ISession): void {
        console.log("updateSelectedProducts");
        const products = session.products;
        const newUsage = [];
        const selectedProducts: IProductInfo[] = [];
        products.forEach(function(ud: IProductInfo) {
            // console.log("ud=", ud);
            if (ud.selected == true) {
                ud.completed = {};

                selectedProducts.push(ud);
            }
            newUsage.push(ud);
        });
        //console.log("selectedProducts=", selectedProducts);

        session.selectedProducts = selectedProducts;

        session.hasProducts = selectedProducts.length > 0;

    }
};

export interface ISessionEvent {
    name: string;
    session: ISession;
}
