import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppFacade } from '../../../facade/app.facade';
import { ISession } from '@common/model/session';
import { EditGrowthComponent } from '../../components/edit-growth/edit-growth.component';

import { EmailDialogComponent } from '../../components/email-dialog/email-dialog.component';

@Component({
    selector: 'evc-done',
    templateUrl: './done.component.html',
    styleUrls: ['./done.component.scss']
})
export class DoneComponent implements OnInit {

    session: ISession;
    countryName: string;

    constructor(
        private router: Router,
        private appFacade: AppFacade,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.session = this.appFacade.getSession();

        if (this.session && this.session.account) {
            this.countryName = this.session.account.country_cd;
            //TODO: Add countryName mapping to Heroku Connect.  This is a last-minute change for code freeze...
            if (this.countryName == 'CA') {
                this.countryName = 'Canada';
            }
        } else {
            this.countryName = 'US';
        }
        console.log("countryName=", this.countryName);

    }

    editProducts() {
        console.log("editProducts");
        this.router.navigate(['/select-products']);
    }

    async editGrowth() {
        console.log("editGrowth");
        const ref = this.modalService.open(EditGrowthComponent, {
            animation: true,
            centered: true// ,
            // size: 'lg'// ,
            // windowClass: 'confirmation-leaving'
        });
        try {
            var result = await ref.result;
            console.log("editGrowth response = ", result);
        } catch (err) {
            console.error("error with editGrowth: ", err);
        }

    }

    async emailEVC() {
        console.log("emailEVC");
        //TODO: Popup modal dialog allowing email of results

        const ref = this.modalService.open(EmailDialogComponent, {
            animation: true,
            //            centered: true,
            size: 'lg'// ,
            // windowClass: 'confirmation-leaving'
        });
        ref.componentInstance.email = this.session.user.email;
        ref.componentInstance.isCanada = this.session.isCanada;
    }

}
