<div class="spot-modal" style="max-width:100%;width:100%;overflow:auto;">
  <header class="spot-modal__header">
	<div class="spot-modal__titles">
	  <h2 class="spot-modal__title" style="font-size:26px;">Customize growth forecast</h2>
	</div>
	<button class="spot-modal__header-cancel-button"
			title="Close"
			(click)="closeModal()">
	  <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
		<title>Close</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#cancel"></use>
	  </svg>
	</button>
  </header>
  <div class="spot-modal__content" style="overflow:initial;">
	<div class="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top"></div>
	<div class="spot-modal__content-wrapper" style="margin:1em;">
	  <form id="growthForm" #growthForm="ngForm" name="growthForm" style="width:300px;margin-left:auto;margin-right:auto;font-size:18px;" class="growth">
		<div class="form-group">
		  <label for="years">Years</label>
		  <div class="row">
			<div class="col">
			  <input type="range" name="years" min="1" max="10" step="1" [(ngModel)]="yearsGrowth" class="slider evc-slider" id="yearRange" (change)="recalcTotals()">
			</div>
			<div class="col">
			  <input id="growthYears" type="number" step="1" min="1" max="10" [(ngModel)]="yearsGrowth" name="growthYears" required="true" class="form-control" style="width: 100px;" (change)="recalcTotals()"/>
			</div>
		  </div>
		</div>
		<div class="form-group">
		  <label for="percent">Expected annual growth</label>
		  <div class="input-group" style="width:100px">
			<input type="number" step="1" class="form-control" name="percent" [(ngModel)]="percentGrowth" (change)="recalcTotals()"/>
			<div class="input-group-append">
			  <span class="input-group-text" style="border:none;background-color:transparent;font-size:18px;">%</span>
			</div>
		  </div>
		</div>
		<br/>
		<br/>

		<span class="highlightNumbers">
		  {{profitGrowth | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
		</span>
		<p>Additional profit from partnering with IDEXX over {{yearsGrowth}} years </p>
		<!--<p style="text-align: right;">({{percentGrowth}}% growth)</p>-->
	  </form>
	</div>
	<div class="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom"></div>
  </div>
  <footer class="spot-modal__footer">
	<button class="spot-modal__footer-cancel-button spot-button--large" title="Cancel" (click)="closeModal()">Cancel</button>
	<button class="spot-button spot-button--primary spot-button--large"
			title="Send"
			[disabled]="saving"
			(click)="save()">Save</button>
  </footer>
</div>
