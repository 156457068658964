<div class="evcCard">
  <div class="prodTitle">
	<table>
	  <tr>
		<td rowspan="2" style="width: 70px;">
		  <mat-icon aria-hidden="false" aria-label="Summary" svgIcon="summary.svg" class="mediumIcon fillTeal"></mat-icon>
		</td>
		<td>
		  Summary
		</td>
	  </tr>
	  <tr>
		<td><span class="prodStep">&nbsp;</span></td>
	  </tr>
	</table>
  </div>

  <div class="prodBody">
	<table class="prodSummary">
	  <tr>
		<td></td>
		<td class="prodName">Select diagnostic tests</td>
		<td></td>
		<td></td>
		<td><button class="spot-button spot-button--secondary" (click)="viewSelectProducts()" >Update</button></td>
	  </tr>

	  <ng-container>
		<tr>
		  <td colspan="5">&nbsp;</td>
		</tr>

		<tr style="border-top: 1px solid #BABDBF">
		  <th></th>
		  <th>IDEXX test(s) evaluated</th>
		  <th style="text-align:center;">Average tests per month</th>
		  <th style="text-align:right;">Monthly profit</th>
		  <th></th>
		</tr>

		<tr *ngFor="let sProd of session?.selectedProducts">
		  <td>
			<mat-icon aria-hidden="false" aria-label="{{sProd.name}}" [svgIcon]="sProd.iconName + '.svg'" class="smallIcon fillTeal"></mat-icon>
		  </td>
		  <td class="prodName">
			{{ sProd.displayName }}
		  </td>
		  <td class="highlightNumbers" style="text-align:center;">
			{{ sProd.runsPerMonth  | number:'1.0':'en-US'}}
		  </td>
		  <td class="highlightNumbers" style="text-align:right;">
			<span *ngIf="sProd.totalSavings">
		  	  {{sProd.totalSavings | currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
			</span>
		  </td>
		  <td align="center">
			<button *ngIf="sProd.done == true" class="spot-button spot-button--secondary" (click)="editProduct(sProd)" value="{{ sProd.name }}" >Update</button>
			<button *ngIf="sProd.done != true" class="spot-button spot-button--primary" (click)="editProduct(sProd)" value="{{ sProd.name }}" >Start</button>
		  </td>
		</tr>
		<tr style="border-top: 1px solid #BABDBF">
		  <td></td>
		  <td></td>
		  <td colspan=2 style="text-align: right;">Total profit:&nbsp;
			<span class="highlightNumbers">
			  {{session?.totalSavings | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
			</span>
		  </td>
		  <td></td>
		</tr>
	  </ng-container>


	  <tr>
		<td colspan="5">&nbsp;</td>
	  </tr>
	  <tr style="border-top: 1px solid #BABDBF">
		<td colspan="4">&nbsp;</td>
		<td><button class="spot-button spot-button--primary brand" (click)="goDone()" >Generate summary</button></td>
	  </tr>

	</table>
  </div>

</div>
