import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as rxjs from 'rxjs';

import { AppFacade } from '../facade/app.facade';

@Injectable({
    providedIn: 'root'
})
export class AccountGuard implements CanActivate, CanActivateChild {
    constructor(
        //public auth: AuthService,
        public router: Router,
        public appFacade: AppFacade) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): rxjs.Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("canActivate");
        try {
            if (!this.hasAccount()) {
                console.log("No account");
                this.router.navigate(['/search']);
                return false;
            }
            console.log("Returning true");
            return true;
        } catch (e) {
            console.error("Exception: ", e);
            return false;
        }
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): rxjs.Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("canActivateChild");
        try {
            if (!this.hasAccount()) {
                this.router.navigate(['/search']);
                return false;
            }
            console.log("Returning true");
            return true;
        } catch (e) {
            console.error("Exception: ", e);
            return false;
        }
    }

    private hasAccount(): boolean {
        var session = this.appFacade.getSession();
        return (session && session.account != null);
    }

}
