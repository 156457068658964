import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(
        public authService: AuthService,
        public router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;
        console.log("expectedRole=", expectedRole);
        const data = JSON.parse(localStorage.getItem('user_data'));
        console.log("user_data=", data);
        // decode the token to get its payload
        if (!this.authService.isLoggedIn() ||
            !data.role ||
            data.role.developer_name !== expectedRole) {
            this.router.navigate(['/search']);
            return false;
        }
        return true;
    }
}
