<div style="display:flex;flex-direction:row;" class="productProgress">
	<div class="around-small" alignmentBump="left">
	  <button [title]="'Average tests per month: ' + selectedProduct.runsPerMonth" (click)="editProductRuns()" [disabled]="subPage != 'editRuns' && selectedProduct.completed?.editRuns != true" name="{{ selectedProduct.name }}" [class]="'spot-button spot-button--secondary productProgressButton ' + (subPage == 'editRuns' ? 'activeProductNav' : '')"><i class="fas fa-flask fa-2x"></i> <span class="badge runsBadge">{{selectedProduct.runsPerMonth | number:'1.0':'en-US'}}</span></button>
	</div>
	<div class="around-small">
	  <button title="Profit" (click)="editProductProfit()" [disabled]="subPage != 'adjustPrice' && selectedProduct.completed?.adjustPrice != true" [name]="selectedProduct.name" [class]="'spot-button spot-button--secondary productProgressButton ' + (subPage == 'adjustPrice' ? 'activeProductNav' : '')"><i class="fas fa-hand-holding-usd fa-2x"></i></button>
	</div>
	<div class="around-small">
	  <button title="Compliance" (click)="editProductCompliance()" [disabled]="subPage != 'adjustCompliance' && selectedProduct.completed?.adjustCompliance != true" [name]="selectedProduct.name" [class]="'spot-button spot-button--secondary productProgressButton ' + (subPage == 'adjustCompliance' ? 'activeProductNav' : '')"><i class="fas fa-handshake fa-2x"></i></button>
	</div>
	<div class="around-small">
	  <button title="Summary" (click)="editProductSummary()" [disabled]="subPage != 'doneProduct' && selectedProduct.completed && selectedProduct.completed?.doneProduct != true" [name]="selectedProduct.name" [class]="'spot-button spot-button--secondary productProgressButton ' + (subPage == 'doneProduct' ? 'activeProductNav' : '')"><i class="fas fa-receipt fa-2x"></i></button>
	</div>

