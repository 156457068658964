<evc-loading-indicator msg="Downloading..." [loading]="loading"></evc-loading-indicator>
<footer class='pageFooter'>
  <div *ngIf="session && session.account != null" style="display:flex;flex-direction:row">
	<div>
	  <div style="max-width:600px;">
		{{ session?.account.name }} - {{ session?.account.sap_id }}<br/>
		{{ session?.account.city }}, {{ session?.account.state }}
	  </div>
	  <div *ngIf="session?.lastUpdated" style="padding-top:10px;font-size:12px;">
		Utilization data last updated {{session?.lastUpdated | date }}<br/>
		Supporting data on file at IDEXX
	  </div>
	</div>
	<div style="flex:1;">
	  <a class="spot-button spot-button--link spot-button--small" style="height:16px;" (click)="goHome()">change account</a>
	</div>
	<div>
	  <div>
		<span style="font-weight:bold;">Latest EVC Summary</span>
		<ng-container *ngIf="summaryCount == 0">
		  <div style="color:red;margin-top:10px;">No records available</div>
		</ng-container>
		<ng-container *ngIf="summaryCount > 0">
		  <span style="float:right;">1 of {{summaryList.length}} records</span>
		</ng-container>
	  </div>
	  <ng-container *ngIf="summaryCount > 0">
		<div style="margin-top:10px;display:flex;flex-direction:row;justify-content:space-between;width:100%;align-items:center;">
		  <span style="margin-right:1em;">
			{{ firstSummary.displayName }}
			{{ firstSummary.created_by.name }}
		  </span>
		  <span *ngIf="session.isCanada != true">
			<a class="spot-button--link" (click)="getPDF($event, firstSummary, 'en-US')">View/download</a>
		  </span>
		  <span *ngIf="session.isCanada == true" style="white-space:nowrap;">
			<a class="spot-button spot-button--link" style="padding:0 2px;height:inherit;" (click)="getPDF($event, firstSummary, 'en-CA')">English</a>
			|
			<a class="spot-button spot-button--link" (click)="getPDF($event, firstSummary, 'fr-CA')" style="padding:0 2px;height:inherit;">French</a>
		  </span>

		</div>
		<a class="spot-button spot-button--link" style="padding-left:0px;" (click)="viewSummaries()">View all summaries</a>
	  </ng-container>

	</div>
  </div>

</footer>
