<table class="table table-hover">
  <thead>
  <tr>
	<th scope="col">Name</th>
	<th scope="col">Display Name</th>
	<th scope="col">Currency</th>
	<th scope="col">Savings</th>
	<th scope="col">Display Order</th>
	<th scope="col">Product</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let act of activities" (click)='selectActivity(act, $event)' [class]="act == selectedActivity ? 'selected' : ''">
	<td>{{act.name}}</td>
	<td>{{act.display_name}}</td>
	<td>{{act.display_order}}</td>
	<td>{{act.currency_cd}}</td>
	<td>{{act.savings}}</td>
	<td id="product_name">{{act.product?.name}}</td>
  </tr>
  </tbody>
</table>
