import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppFacade } from './facade/app.facade';
import { EVCAlertService } from './service/alert.service';
import { VersionService } from './service/version.service';
import { IconService } from './service/icon.service';

@Component({
    selector: 'evc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'IDEXX Economic Value Calculator';
    version: string;
    updateRequired: boolean = false;

    constructor(
        private appFacade: AppFacade,
        private router: Router,
        public alertService: EVCAlertService,
        private versionService: VersionService,
        iconService: IconService
    ) {
        iconService.initIcons();
    }

    ngOnInit() {
        // this.appFacade.localAuthSetup();
        // this.appFacade.handleAuthCallback();
        this.versionService.version.subscribe(version => {
            if (this.version === undefined || this.version === null) {
                this.version = version;
            }
            if (this.version !== version) {
                console.log('applicationUpdated, suggesting reload');
                this.version = version;
                this.updateRequired = true;
            }
        });

        // Trying to work around an ipad keyboard issue.
        window.addEventListener('keyboardDidShow', (e) => {
            const elementFocused: any = document.querySelector(':focus');
            if (elementFocused) {
                elementFocused.blur();
                elementFocused.focus();
            }
        });
    }

    logout() {
        this.appFacade.logout();
        this.router.navigate(['/auth/login']);
    }

    onUpdateClick() {
        window.location.reload(true);
    }

}

