<div class="page-body">

  <as-split unit="percent" direction="vertical" #split="asSplit">
	<as-split-area size="50" #area1="asSplitArea">
	  <div style="min-height:100px; max-height: 200px;">
	<table matSort (matSortChange)="sortData($event)" style="width:100%;">
	  <tr>
		<th mat-sort-header="name">Name</th>
		<th mat-sort-header="role">Role</th>
		<th mat-sort-header="sfUsername">SF Username</th>
		<th mat-sort-header="email">Email</th>
		<th mat-sort-header="profile">Profile</th>
	  </tr>
	  <tr *ngFor="let user of sortedData" (click)="configUser(user)" [ngClass]="'evc-user ' + (isSelected(user) ? 'selected' : '')">
		<td>{{user.name}}</td>
		<td>{{user.role.name}}</td>
		<td>{{user.sfdcUsername}}</td>
		<td>{{user.email}}</td>
		<td>{{user.sfdcProfile ? user.sfdcProfile.name : ''}}</td>
	  </tr>
	</table>
	</div>
	</as-split-area>

	<as-split-area size="50" #area3="asSplitArea">

	  <div class="page-section" [style]="selectedUser ? '' : 'display:none;'" style="border: 1px solid #ccc;overflow:initial;">

	  <form style="background-color:#DDEEFF;padding:10px;" >
		<ng-container *ngIf="selectedUser">

		  <div class="form-group">
			<label for="name">Display Name</label>
			<input type="text" class="form-control" name="name" [(ngModel)]="selectedUser.name" readonly/>
		  </div>

		  <div class="form-group">
			<label>Role</label>
			<select name="role" class="form-control" [(ngModel)]='selectedUser.role' (change)="roleSelected()" required [compareWith]="compareRole">
			  <option name='' value=''></option>
			  <option *ngFor="let role of roles" [ngValue]="role">{{role.name}}</option>
			</select>
		  </div>

		  <div class="form-group">
			<label for="email">Email</label>
			<input type="text" class="form-control" name="email" [(ngModel)]="selectedUser.email" readonly/>
		  </div>

		  <div class="form-group">
			<label for="profile">Profile</label>
			<input type="text" class="form-control" name="profile" [(ngModel)]="selectedUser.sfdcProfile.name" readonly/>
		  </div>

		</ng-container>
	  </form>
	  </div>
	</as-split-area>
  </as-split>
  <div *ngIf="selectedUser">
	<div *ngIf="message" class="profileMessage">
	  {{ message }}
	</div>

	<div class="button-panel">
	  <button type="cancel" [disabled]="saving" class="spot-button spot-button--link" (click)="cancel()">Cancel</button>
	  <button type="submit" [disabled]="saving" class="spot-button spot-button--primary" (click)="save()">Save</button>
	</div>

  </div>
</div>


