import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppFacade } from '../../../facade/app.facade';
import { ISession, ISessionSummary } from '@common/model/session';
import { NativeUtil } from './../../../utils/native';
import { EVCAlertService } from '../../../service/alert.service';
import { UtilService } from '@common/service/util.service';

@Component({
    selector: 'evc-view-summaries',
    templateUrl: './view-summaries.component.html',
    styleUrls: ['./view-summaries.component.scss']
})
export class ViewSummariesComponent implements OnInit {

    session: ISession;
    summaryList: ISessionSummary[];

    loading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private alertService: EVCAlertService,
        private appFacade: AppFacade
    ) { }

    ngOnInit(): void {
        this.session = this.appFacade.getSession();
        if (this.session) { // For testing
            this.summaryList = this.session.submittedSessions;
        }
    }

    closeModal() {
        console.log("closeModal");
        this.activeModal.close(false);
    }

    selectSummary(sum: ISessionSummary) {
        console.log("selectSummary: ", sum);
    }

    //TODO: Move this and the one in client-layout to single point
    getPDF(event: any, sessSummary: ISessionSummary, lang: 'en-US' | 'en-CA' | 'fr-CA') {
        console.log("getPDF");
        this.loading = true;
        this.appFacade.getPDF(sessSummary.evc_session_id, lang)
            .subscribe(async v => {
                console.log("getPDF result: ", v);
                if (v.success) {
                    await NativeUtil.download({
                        base64data: v.data.pdf,
                        filename: v.data.filename,
                        contentType: 'application/pdf',
                        element: event.target//this.downloadButton.nativeElement
                    });
                } else {
                    this.alertService.showError("Error generating PDF for session: ", UtilService.getErrorMessage(v.error));
                }
                this.loading = false;
            });
    }
}
