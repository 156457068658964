import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'evc-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

    @Input() loading: boolean = false;
    @Input() msg: string = 'Loading...';

    constructor() { }

    ngOnInit(): void {
    }

}
