import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("canActivate");
        if (this.authService.isLoggedIn()) {
            console.log("isLoggedIn=true");
            return true;
        }

        console.warn("Not logged in, opening login to Salesforce...");

        var targetUrl = this.getTargetUrl(state.url);
        this.authService.doLogin(targetUrl);
        return false;
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("canActivateChild");
        if (this.authService.isLoggedIn()) {
            console.log("isLoggedIn=true");
            return true;
        }

        console.warn("Not logged in, opening login popup to Salesforce...");

        var targetUrl = this.getTargetUrl(state.url);
        this.authService.doLogin(targetUrl);
        return false;
    }

    getTargetUrl(destUrl) {
        console.log("getTargetUrl: " + destUrl);
        var targetUrl = destUrl;
        if (destUrl) {
            // If the user isn't logged in, then after they do log in, they need to be sent back to the beginning.
            // It should re-load from database the latest state (even for client once we hook that up to store session as we move through).
            if (destUrl.startsWith('/admin')) {
                targetUrl = '/admin';
            } else {
                targetUrl = '/search';
            }
        }
        console.log("targetUrl=" + targetUrl);
        return targetUrl;
    }

}
