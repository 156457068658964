import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { AppFacade } from '../../../facade/app.facade';
import { NavService } from '../../../service/nav.service';
import { ISession } from '@common/model/session';

@Component({
    selector: 'evc-client-layout',
    templateUrl: './client-layout.component.html',
    styleUrls: ['./client-layout.component.scss']
})
export class ClientLayoutComponent implements OnInit {
    page: string;

    session: ISession;
    sessionSub: Subscription;

    constructor(
        private router: Router,
        private appFacade: AppFacade,
        private navService: NavService
    ) { }

    ngOnInit() {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: ISession) => { this.setSession(session); }
        );
    }

    setSession(session: ISession) {
        console.log("setSession: ", session);
        this.session = session;
    }

    adminClicked() {
        console.log("adminClicked");
        this.navService.showAdmin();
    }

    goHome() {
        console.log("goHome");

        this.navService.goHome();

    }

    editProducts() {
        console.log("TODO: editProducts");
        this.router.navigate(['/evc-summary']);
    }

}
