import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class IconService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
    }

    // Need this to be called to initialize icons in the app, but icon service
    // isn't directly used other than in build-template, so calling it for now
    // from app.component.ts
    public initIcons() {
        console.log("initIcons");
        this.addIcon('4dx.svg', '/assets/images/svg/');
        this.addIcon('cbc.svg', '/assets/images/svg/');
        this.addIcon('compliance.svg', '/assets/images/svg/');
        this.addIcon('EVC_app_icon.svg', '/assets/images/svg/');
        this.addIcon('EVC_icons.svg', '/assets/images/svg/');
        this.addIcon('fecal.svg', '/assets/images/svg/');
        this.addIcon('profit.svg', '/assets/images/svg/');
        this.addIcon('results.svg', '/assets/images/svg/');
        this.addIcon('sdma.svg', '/assets/images/svg/');
        this.addIcon('summary.svg', '/assets/images/svg/');
        this.addIcon('utilization.svg', '/assets/images/svg/');
        this.addIcon('video_play.svg', '/assets/images/svg/');
    }

    private addIcon(name: string, path: string = '/assets/images/') {
        console.log("addIcon: ", name);
        this.matIconRegistry.addSvgIcon(
            name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(path + name)// + '.svg')
        );
    }

    // getIconDefByString(iconStr: string): IconDef {
    //     var iconDef: IconDef;
    //     if (iconStr) {
    //         iconDef = ICONS.find(function(iDef: IconDef) { return iDef.icon == iconStr });
    //     }
    //     return iconDef;
    // }

    // getIconDef(species: ISpecies, lifeStage: string): IconDef {
    //     var iconDef: IconDef;
    //     if (species && lifeStage) {
    //         iconDef = ICONS.find(function(iDef: IconDef) {
    //             return iDef.species.value == species.developer_name
    //                 && iDef.stage_name == lifeStage;
    //         });
    //     }
    //     return iconDef;
    // }

    // getLifeStages(): string[] {
    //     return Object.values(STAGES);
    // }

    // getLifeStage(icon: string): string {
    //     console.log("getLifeStage: ", icon);

    //     var stage: string;
    //     var iconDef: IconDef = this.getIconDefByString(icon);
    //     if (iconDef) {
    //         stage = iconDef.stage_name;
    //     } else if (icon == 'fa-dog'
    //         || icon == 'fa-cat') {
    //         stage = STAGES.ADULT;
    //     } else if (icon == 'fa-cat') {
    //         stage = STAGES.ADULT;
    //     } else if (icon == 'plus.svg') {
    //         stage = null;
    //     }
    //     if (!stage) {
    //         console.error("Icon not recognized: ", icon);
    //     }

    //     return stage;

    // }
}

export interface IconDef {
    icon: string,
    //    species: SpeciesEnum,
    stage_name: string
}
