import { Component, OnInit, Input } from '@angular/core';

import { AppFacade } from '../../../facade/app.facade';
import { IProductInfo } from '@common/model/session-product';

@Component({
    selector: 'evc-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {

    @Input() usage: IProductInfo = {};

    iconPath: string;

    constructor(
        private appFacade: AppFacade
    ) { }

    ngOnInit(): void {
        console.log("usage=", this.usage);
        if (this.usage) {
            this.iconPath = '/assets/images/svg/' + this.usage.iconName + '.svg#icon';
        }
    }

    onCheckProduct(prod: IProductInfo, event: any) {
        console.log("onCheckProduct", prod, event);

        var prodName = prod.name,
            isSelected = event.currentTarget.checked;

        this.appFacade.productSelectChanged(prodName, isSelected);

    }

}
