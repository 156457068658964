import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EditActivityComponent } from './views/edit-activity/edit-activity.component';
import { AdminComponent } from './views/admin/admin.component';
import { EditProductComponent } from './views/edit-product/edit-product.component';
import { ProductsComponent } from './views/products/products.component';
import { UsageComponent } from './views/usage/usage.component';
import { UserComponent } from './views/user/user.component';
import { CodesComponent } from './views/codes/codes.component';
import { SessionsComponent } from './views/sessions/sessions.component';
import { ActivitiesComponent } from './views/activities/activities.component';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { ResourceComponent } from './views/resource/resource.component';

import { AuthGuard } from '../auth/auth.guard';

import { RoleGuardService } from '../auth/role.guard';

const data = {
    expectedRole: 'MAD_ADMIN'
};

const routes: Routes = [
    {
        path: 'system',
        data,
        component: AdminComponent,
        canActivate: [AuthGuard, RoleGuardService]
    },
    {
        path: 'user',
        data,
        component: UserComponent,
        canActivate: [AuthGuard, RoleGuardService]
    },
    {
        path: 'activities',
        data,
        component: ActivitiesComponent,
        canActivate: [AuthGuard, RoleGuardService]
    },
    {
        path: 'edit-activity/:actId',
        data,
        component: EditActivityComponent,
        canActivate: [AuthGuard, RoleGuardService]
    },
    {
        path: 'products',
        data,
        component: ProductsComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'usage',
        data,
        component: UsageComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'product-details/:prodDetailId',
        data,
        component: ProductDetailsComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'resource',
        data,
        component: ResourceComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'codes',
        data,
        component: CodesComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'sessions',
        data,
        component: SessionsComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'edit-product/:prodId',
        data,
        component: EditProductComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: 'home',
        data,
        component: AdminComponent,
        canActivate: [RoleGuardService]
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'// ,
        // canActivate: [RoleGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
