<div class="prodTitle">
  <table style="flex:1;width:inherit;">
	<ng-container *ngIf="true" >
	  <tr>
		<td rowspan="2" style="width: 70px;">
		  <mat-icon aria-hidden="false" aria-label="{{selectedProduct.name}}" [svgIcon]="selectedProduct.iconName + '.svg'" class="mediumIcon fillTeal"></mat-icon>
		</td>
		<td>
		  {{ selectedProduct.displayName }}
		</td>
	  </tr>
	  <tr>
		<td><span class="prodStep">{{ pageInfo?.displayName }}</span></td>
	  </tr>
	</ng-container>

  </table>
  <evc-progress-bar [selectedProduct]="selectedProduct" [subPage]="subPage"></evc-progress-bar>
</div>

<div class="prodBody" style="display:flex;flex-direction:column;height:100%;">
  <div style="flex:1;margin-top: 20px;">
	  <div style="display:flex;flex-direction:column;height:100%;">
		<div class="slds-align_absolute-center" style="flex:2;">

		  <ng-container *ngIf="subPage == 'editRuns'">
			<div class="form-group">
			  <p class="heading" style="margin: 10px;">Average tests per month?</p>
			  <input id="runs" type="number" min="0" name="runsPerMonth" [(ngModel)]="selectedProduct.runsPerMonth" (change)="recalcTotals()" class="highlightSavings2 centerInput evcUtil form-control"/>
			</div>
		  </ng-container>

		  <ng-container *ngIf="subPage == 'adjustPrice'">
			<div>
			  <p class="heading">
				Additional profit generated from recommended follow-up actions.
			  </p>
			  <br/>
			  <br/>
			  <br/>
			  <table class="profitTable">
				<tr>
				  <th style="flex:1;">
					Recommended follow-up
				  </th>
				  <th style="flex:1;text-align:right;">
					Additional profit per test ordered
				  </th>
				  <th style="width:65px;">
					<span *ngIf="hasEdits()">Edited</span>
				  </th>
				</tr>

				<tr *ngFor="let act of selectedProduct.activities" class="prodRow">
				  <td class="actName">
					{{ act.display_name }}
				  </td>
				  <td class="highlightNumbers" style="text-align:right;">
					{{act.savings |  currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
					<button class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon" (click)="adjustPrice(act)" style="box-shadow:none;width:20px;">
					  <svg class="spot-icon spot-button__icon" aria-labelledby="title">
						<title>Edit</title>
						<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#edit"></use>
					  </svg>
					</button>
				  </td>
				  <td *ngIf="hasEdits()">
					<button *ngIf="act.savings != act.origSavings" class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon" (click)="adjustPrice(act)" style="box-shadow:none;width:20px;">
					  <svg class="spot-icon change-checkmark" aria-labelledby="title">
						<title>Check</title>
						<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#checkmark"></use>
					  </svg>
					</button>
				  </td>

				</tr>

			  </table>
			</div>
		  </ng-container>

		  <ng-container *ngIf="subPage == 'adjustCompliance'">
			<div>
			  <p class="heading">
				Indicate the compliance rate(s) among pet owners in your practice for recommended follow-up products and services.
			  </p>
				<br/>
				<br/>
				<br/>

			  <ng-container *ngIf="selectedProduct.activities">
				<br/>

				<table class="profitTable">
				  <tr style="vertical-align:bottom;">
					<th>
					  Recommended follow-up
					</th>
					<th style="text-align:right;">
					  Additional profit per test ordered
					</th>
					<th colspan="2" style="text-align:center;">
					  Estimated compliance rate
					</th>
				  </tr>
				  <tr *ngFor="let act of selectedProduct.activities" class="prodRow">
					<td class="actName" style="width:50%">
					  {{ act.display_name }}
					</td>
					<td class="highlightNumbers" style="text-align:right;width:15%;">
					  {{act.savings |  currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
					</td>
					<td class="complianceSlider" style="width:20%;">
					  <div class="slidecontainer">
						<input type="range" [name]="'compliance.' + act.evc_activity_id" min="0" max="100" [(ngModel)]="act.compliance" [value]="act.compliance" class="slider evc-slider" id="myRange" (change)="complianceChanged(act)" style="font-size:24px;">
					  </div>
					</td>
					<td class="actSlider highlightNumbers inputCenter" style="width:15%">
					  <div class="input-group">
						<input id="compliance" type="number" step="1" min="0" max="100" [(ngModel)]="act.compliance" (change)="limitCompliance(act)" name="compliance" required="true" class="form-control"/>
						<div class="input-group-append">
						  <span class="input-group-text" style="border:none;background-color:transparent;font-size:24px;">%</span>
						</div>
					  </div>
					</td>
				  </tr>

				</table>

			  </ng-container>
			</div>
		  </ng-container>

		  <ng-container *ngIf="subPage == 'adjustCalc'">
			<div>
			  <p class="heading">
				Adjust compliance and utilization rates as needed.
			  </p>
				<br/>
				<br/>

			  <br/>
			  <table class="profitTable">
				<tr style="vertical-align:bottom;">
				  <th style="width:25%;">Recommended follow-up</th>
				  <th style="width:18%;text-align:right;">
					Average profit/IDEXX test ordered
				  </th>
				  <th style="width:1%;">&nbsp;</th>
				  <th style="width:18%;text-align:center;">
					Estimated compliance rate
				  </th>
				  <th style="width:1%;">&nbsp;</th>
				  <th style="width:18%;text-align:center;">
					Average tests per month
				  </th>
				  <th style="width:1%;">&nbsp;</th>
				  <th style="width:18%;text-align:right;">
					Additional profit per month
				  </th>
				</tr>
				<tr class="prodRow">
				  <td colspan="8"></td>
				</tr>
				<tr *ngFor="let act of selectedProduct.activities; let key = index">
				  <td class="actName">
					{{ act.display_name }}
				  </td>
				  <td style="width:18%;text-align:right;" class="highlightNumbers inputNumber">
					{{act.savings | currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
				  </td>
				  <ng-container *ngIf="key == 0">
					<td style="width:1%;" [rowSpan]="selectedProduct.activities.length" class="calcSymbol">X</td>
				  </ng-container>
				  <td style="width:18%;" class="highlightNumbers inputCenter">
					  <div class="input-group">
						<input id="compliance" type="number" step="1" min="0" max="100" [(ngModel)]="act.compliance" name="compliance" class="form-control" (change)="recalcTotals(act)"/>
						<div class="input-group-append">
						  <span class="input-group-text" style="border:none;background-color:transparent;font-size:24px;">%</span>
						</div>
					  </div>
				  </td>
				  <ng-container *ngIf="key == 0">
					<td style="width:1%;" [rowSpan]="selectedProduct.activities.length" class="calcSymbol">X</td>
				  </ng-container>
				  <ng-container *ngIf="key == 0">
					<td style="width:18%;" class="highlightNumbers inputCenter" [rowSpan]="selectedProduct.activities.length"  style="text-align:center;">
					  <input id="utilization" type="number" step="1" [(ngModel)]="selectedProduct.runsPerMonth" name="utilization" (change)="recalcTotals()" style="width:150px"/>
					</td>
				  </ng-container>
				  <ng-container *ngIf="key == 0">
					<td style="width:1%;" [rowSpan]="selectedProduct.activities.length" class="calcSymbol">=</td>
				  </ng-container>
				  <td style="width:18%;" class="highlightNumbers inputNumber">
					{{act.totalProfit | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
				  </td>
				</tr>

				<tr>
				  <td colspan="7">
					&nbsp;
				  </td>
				  <td class="highlightNumbers inputNumber">
					<div style="border-top: solid 1px green; margin-top: 5px;">
					  {{selectedProduct?.totalSavings | currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
					</div>
				  </td>
				</tr>
			  </table>
			</div>
		  </ng-container>

		  <ng-container *ngIf="subPage == 'adjustPriceWorksheet'">
			<div>
			  <table class="profitTable">
				<tr>
				  <th style="width:50%;">Recommended follow-up</th>
				  <th style="width:50%;text-align:right;">
					Additional profit per test ordered
				  </th>
				</tr>
				<tr>
				  <td class="actName">
					{{ act.display_name }}
				  </td>
				  <td style="width:18%;text-align:right;" class="highlightNumbers inputNumber">
					{{act.savings | currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
				  </td>
				  <ng-container *ngIf="key == 0">
					<td style="width:1%;" [rowSpan]="selectedProduct?.activities.length" class="calcSymbol">X</td>
				  </ng-container>
				  <td style="width:18%;" class="highlightNumbers inputCenter">
					  <div class="input-group">
						<input id="compliance" type="number" step="1" min="0" max="100" [(ngModel)]="act.compliance" name="compliance" class="form-control" (change)="recalcTotals(act)"/>
						<div class="input-group-append">
						  <span class="input-group-text" style="border:none;background-color:transparent;font-size:24px;">%</span>
						</div>
					  </div>
				  </td>
				  <ng-container *ngIf="key == 0">
					<td style="width:1%;" [rowSpan]="selectedProduct?.activities.length" class="calcSymbol">X</td>
				  </ng-container>
				  <ng-container *ngIf="key == 0">
					<td style="width:18%;" class="highlightNumbers inputCenter" [rowSpan]="selectedProduct.activities.length"  style="text-align:center;">
					  <input id="utilization" type="number" step="1" [(ngModel)]="selectedProduct.runsPerMonth" name="utilization" (change)="recalcTotals(act)" style="width:150px"/>
					</td>
				  </ng-container>
				  <ng-container *ngIf="key == 0">
					<td style="width:1%;" [rowSpan]="selectedProduct.activities.length" class="calcSymbol">=</td>
				  </ng-container>
				  <td style="width:18%;" class="highlightNumbers inputNumber">
					{{act.totalProfit | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
				  </td>
				</tr>

				<tr>
				  <td colspan="7">
					&nbsp;
				  </td>
				  <td class="highlightNumbers inputNumber">
					<div style="border-top: solid 1px green; margin-top: 5px;">
					  {{selectedProduct.totalSavings | currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}
					</div>
				  </td>
				</tr>
			  </table>
			</div>
		  </ng-container>

		  <ng-container *ngIf="subPage == 'doneProduct'">
			<div>
			  <ng-container>
				<h2 class="heading" style="text-align:left;">
				  {{ selectedProduct.displayName }} impacts profits in your practice.
				</h2>
				<br/>
				<p class="body">
				  Based on your current testing utilization <span class="highlightNumbers">({{ selectedProduct.runsPerMonth  | number:'1.0':'en-US'}})</span> and compliance rate(s), your practice generates an estimated&nbsp; <span class="highlightNumbers">{{selectedProduct.totalSavings | currency : session.currencyCd : 'symbol-narrow' : '1.2-2' : session.locale}}</span> &nbsp;in additional profit per month.
				</p>
				<br/><br/>
				<p style="border-top: 1px solid #BABDBF;padding-top:10px;">
				  Would you like to adjust the quantity of tests or compliance rate to see how it impacts your monthly profit?&nbsp;
				  <button class="spot-button spot-button--secondary" [name]="selectedProduct.name" (click)="editProductCalcs()" >Update</button>
				</p>
			  </ng-container>

			</div>
		  </ng-container>
		</div>
		<div style="flex:1;">
		  &nbsp;
		</div>
	  </div>
  </div>
  <div *ngIf="isDesktop && (subPage == 'doneProduct')" style="margin-bottom:2em;">
	<div *ngIf="selectedProduct.product_dashboard_url">
	  <a [href]="selectedProduct.product_dashboard_url" target="_new">
		<div style="max-width:300px;display:inline-flex;align-items:center;">
		  <svg class="spot-icon fillIconTeal" aria-labelledby="title">
			<title>Open-New-Window</title>
			<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#open-new-window"></use>
		  </svg>
		  <div class="video">
			{{selectedProduct.displayName}} dashboard
		  </div>
		</div>
	  </a>
	</div>

	<div *ngIf="selectedProduct.patient_results_dashboard_url">
	  <a [href]="selectedProduct.patient_results_dashboard_url" target="_new">
		<div style="max-width:300px;display:inline-flex;align-items:center;">
		  <svg class="spot-icon fillIconTeal" aria-labelledby="title">
			<title>Open-New-Window</title>
			<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#open-new-window"></use>
		  </svg>
		  <div class="video">
			{{selectedProduct.displayName}} patient results dashboard
		  </div>
		</div>
	  </a>
	</div>

  </div>

  <ng-container *ngIf="(subPage == 'adjustPrice' ) && selectedProduct.video_url">
	<a [href]="selectedProduct.video_url" target="_new">
	  <div style="max-width:300px;display:inline-flex;">
		<div>
		  <mat-icon aria-hidden="false" aria-label="Video" svgIcon="video_play.svg" class="smallIcon fillTeal"></mat-icon>
		</div>
		<div class="video">
		  Learn how {{ selectedProduct.displayName }} profitability is calculated
		</div>
	  </div>
	</a>

  </ng-container>
</div>
