<div class="mainLayout vbox">
  <div class="dog-background">
	<div class="sso-panel">

	  <div class="sapHeader">
		<div class="idexxLogo">
		    <img src="/assets/images/IDEXX_logo.resource" class="headerLogo" />
		</div>
	  </div>
	  <div>
		<h1 style="font-size:12px;text-align:center;">IDEXX <strong>Preventative Care Challenge</strong></h1>
	  </div>
	  <div class="sapInput">
		<h1>SSO failed</h1>
		<span style="color:red;">You do not have access to EVC.</span>
		<div style="font-size:13px;">
		  Please contact support to ensure you have an account in Salesforce and that your Salesforce Profile is set up correctly for you to access EVC.
		</div>
	  </div>
	</div>
  </div>
</div>

