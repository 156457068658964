import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavService, IEVCNavEvent, INavItem } from '../../../service/nav.service';
import { AppFacade } from '../../../facade/app.facade';

import { ISession } from '@common/model/session';

@Component({
    selector: 'evc-client-nav',
    templateUrl: './client-nav.component.html',
    styleUrls: ['./client-nav.component.scss']
})
export class ClientNavComponent implements OnInit {

    page: string;

    session: ISession;
    sessionSub: Subscription;

    navStateSubscription: Subscription;

    nextNav: INavItem;
    prevNav: INavItem;

    constructor(
        private appFacade: AppFacade,
        private navService: NavService
    ) { }

    ngOnInit() {
        // Needed for save-and-retrieve where we go from sap search
        // directly to summary page before the client layout is created...
        this.setNextPage(this.navService.getNextNav());
        this.setPrevPage(this.navService.getPrevNav());

        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: ISession) => { this.setSession(session); }
        );
        this.navStateSubscription = this.navService.getNavState().subscribe(
            (navEvent: IEVCNavEvent) => { this.setNavInfo(navEvent); },
            (err: Error) => { console.error('Error with nav state: ', err); },
            () => { console.log("State subscription complete"); }
        );
    }

    setNavInfo(navEvent: IEVCNavEvent) {
        console.log("setNavInfo: ", navEvent);

        if (!navEvent) {
            return;
        }
        if (navEvent.name == 'nav' && navEvent.currentState) {
            this.page = navEvent.currentState.pageName;
        } else if (navEvent.name == 'nextState') {
            this.setNextPage(navEvent.nextState);
        } else if (navEvent.name == 'prevState') {
            this.setPrevPage(navEvent.prevState);
            this.prevNav = navEvent.prevState;
        } else {
            console.log("Ignoring state change");
        }
    }

    setSession(session: ISession) {
        console.log("setSession: ", session);
        this.session = session;
    }

    adminClicked() {
        console.log("adminClicked");
        this.navService.showAdmin();
    }

    goHome() {
        console.log("goHome");

        this.navService.goHome();

    }

    setNextPage(np: INavItem) {
        console.log("setNextPage: ", np);
        this.nextNav = np;
    }

    setPrevPage(np: INavItem) {
        console.log("setPrevPage: ", np);
        this.prevNav = np;
    }

    nextPage() {
        console.log("nextPage");
        //this.navService.goNext(this.nextNav);
        this.navService.nextPage();

        //TODO: REFACTOR all the "complete" flags, etc for progress bar
    }

    submitSession() {
        this.nextPage();
    }

    prevPage() {
        console.log('prevPage');
        this.navService.prevPage();
    }
}
