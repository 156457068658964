import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminFacade } from '../../../facade/admin.facade';

import { EVCAlertService } from '../../../service/alert.service';

import { IActivity } from '@common/model/activity';

@Component({
    selector: 'evc-edit-activity',
    templateUrl: './edit-activity.component.html',
    styleUrls: ['./edit-activity.component.scss']
})
export class EditActivityComponent implements OnInit {

    @ViewChild('activityForm', { static: false }) activityForm: NgForm;
    activity: IActivity;

    saveEnabled: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alertService: EVCAlertService,
        private adminFacade: AdminFacade,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            console.log("params here: ", params);
            var actId = params.actId;
            console.log("actId=", actId);
            this.getActivity(actId);
        });
        if (!this.activity) { // For testing
            this.activity = {};
        }
    }

    ngAfterViewInit() {
        console.log("this.activityForm=", this.activityForm);
        var me = this;
        if (this.activityForm) {
            this.activityForm.statusChanges.subscribe(status => {
                console.log("activityForm status change: ", status);
                this.updateButtons();
            });

            this.activityForm.valueChanges.subscribe(value => {
                console.log("value=", value);
                //this.checkDups();
                console.log("activityForm value change: ", value);
                me.findInvalidControls();
            });
        } else {
            console.error("this.activityForm doesn't exist!");
        }

    }

    async getActivity(prodId: number): Promise<IActivity> {
        this.alertService.setBusy(true, 'Loading activity...');
        try {
            var prodResp = await this.adminFacade.getActivity(prodId);
            if (prodResp && prodResp.success == true) {
                this.activity = prodResp.activity;
            } else {
                console.warn("Error getting activity here: ", prodResp);
            }
            return this.activity;
        } catch (err) {
            console.error("Error getting activity: ", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    updateButtons() {
        var valid = this.activityForm.form.valid;
        console.log("valid errors: ", this.activityForm.form.errors);
        console.log("this.activityForm.form.status=", this.activityForm.form.status);
        console.log("this.activityForm.hasError=", this.activityForm.hasError);
        console.log("this.activityForm.invalid=", this.activityForm.invalid);
        console.log("this.activityForm.options=", this.activityForm.options);

        console.log("form valid: " + valid);
        valid = valid && (this.activity != null);

        this.saveEnabled = valid;
    }

    saveActivity() {
        console.log("TODO: saveActivity");
    }

    cancel() {
        console.log("TODO: cancel");
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.activityForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log("invalid=", invalid);
        return invalid;
    }


}
