import { Component, OnInit } from '@angular/core';
import { AdminFacade } from '../../../facade/admin.facade';
import { Router } from '@angular/router';
import { IProduct } from '@common/model/product';

@Component({
    selector: 'evc-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
    products: IProduct[];
    selectedProduct: IProduct;

    constructor(
        private router: Router,
        private adminFacade: AdminFacade
    ) {

    }

    async ngOnInit() {
        try {
            var prodResp = await this.adminFacade.getProducts();
            this.products = prodResp.products;
        } catch (err) {
            console.error("Error retrieving products: ", err);
        }
    }

    selectProduct(prod: IProduct) {
        console.log("selectProduct: ", prod);
        this.selectedProduct = prod;

        this.router.navigate(['/admin/edit-product', prod.evc_product_id]);
    }
}
