import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminFacade } from '../../../facade/admin.facade';

import { EVCAlertService } from '../../../service/alert.service';

import { IProductDetails } from '@common/model/product-details';

@Component({
    selector: 'evc-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

    @ViewChild('productDetailForm', { static: false }) productDetailForm: NgForm;
    productDetail: IProductDetails;

    saveEnabled: boolean = false;

    //TODO: Data-ize this.  Move to db somewhere
    countries: string[] = [
        'US', 'CA'
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alertService: EVCAlertService,
        private adminFacade: AdminFacade,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            console.log("params here: ", params);
            var prodDetailId = params.prodDetailId;
            console.log("prodDetailId=", prodDetailId);
            this.getProductDetails(prodDetailId);
        });
        if (!this.productDetail) { // For testing
            this.productDetail = {};
        }
    }

    ngAfterViewInit() {
        console.log("this.productDetailForm=", this.productDetailForm);
        var me = this;
        if (this.productDetailForm) {
            this.productDetailForm.statusChanges.subscribe(status => {
                console.log("productDetailForm status change: ", status);
                this.updateButtons();
            });

            this.productDetailForm.valueChanges.subscribe(value => {
                console.log("value=", value);
                //this.checkDups();
                console.log("productDetailForm value change: ", value);
                me.findInvalidControls();
            });
        } else {
            console.error("this.productDetailForm doesn't exist!");
        }

    }

    async getProductDetails(prodDetailId: number): Promise<IProductDetails> {
        this.alertService.setBusy(true, 'Loading product details...');
        try {
            var prodResp = await this.adminFacade.getProductDetails(prodDetailId);
            if (prodResp && prodResp.success == true) {
                this.productDetail = prodResp.productDetail;
            } else {
                console.warn("Error getting product details here: ", prodResp);
            }
            return this.productDetail;
        } catch (err) {
            console.error("Error getting product details: ", err);
        } finally {
            this.alertService.setBusy(false);
        }
    }

    updateButtons() {
        var valid = this.productDetailForm.form.valid;
        console.log("valid errors: ", this.productDetailForm.form.errors);
        console.log("this.productDetailForm.form.status=", this.productDetailForm.form.status);
        console.log("this.productDetailForm.hasError=", this.productDetailForm.hasError);
        console.log("this.productDetailForm.invalid=", this.productDetailForm.invalid);
        console.log("this.productDetailForm.options=", this.productDetailForm.options);

        console.log("form valid: " + valid);
        valid = valid && (this.productDetail != null);

        this.saveEnabled = valid;
    }

    compareCountry(r1: string, r2: string): boolean {
        //console.log("compareCountry: ", r1, r2);
        var retVal = r1 === r2;
        //console.log("retVal=", retVal);
        return retVal;
    }

    save() {
        console.log("TODO: saveProductDetails", this.productDetail);
    }

    cancel() {
        console.log("cancel");
        this.router.navigate(['/admin/home']);
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.productDetailForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log("invalid=", invalid);
        return invalid;
    }

}
