import { Injectable, Injector } from '@angular/core';

import * as rxjs from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { SessionService } from '../service/session.service';

import { ISessionResponse, ICleanSessionsRequest } from '@common/model/service/session-service';
import { IAccount } from '@common/model/account';
import { IUser } from '@common/model/user';
import { IAccountResponse } from '@common/model/service/account-service';
import { ISession } from '@common/model/session';

@Injectable()
export class AppFacade {

    private _authService: AuthService;
    private _sessionService: SessionService;

    constructor(
        private injector: Injector
    ) {
    }

    public get authService(): AuthService {
        if (!this._authService) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }

    public get sessionService(): SessionService {
        if (!this._sessionService) {
            this._sessionService = this.injector.get(SessionService);
        }
        return this._sessionService;
    }

    getSession(): ISession {
        return this.sessionService.getSession();
    }

    clearSession() {
        return this.sessionService.clearSession();
    }

    // endSession(): Promise<ISession> {
    //     return this.sessionService.endSession();
    // }

    async startSession(acctResp: IAccountResponse, locale: string, oldSession?: ISession, retrieveOld?: boolean): Promise<ISession> {
        return this.sessionService.startSession(acctResp, locale, oldSession, retrieveOld);
    }

    setSession(sess: ISession) {
        this.sessionService.setSession(sess);
    }

    saveSession(): Promise<ISessionResponse> {
        return this.sessionService.saveSession(this.sessionService.getSession());
    }

    getCurrentSession(): rxjs.Observable<ISession> {
        return this.sessionService.getCurrentSession();
    }

    async cleanupSessions(cleanReq: ICleanSessionsRequest): Promise<ISessionResponse> {
        return this.sessionService.cleanupSessions(cleanReq);
    }

    submitEVC(session?: ISession): rxjs.Observable<ISessionResponse> {
        return this.sessionService.submitEVC(session);
    }

    getUserDetails(): IUser {
        return this.authService.getUserDetails();
    }

    logout() {
        return this.authService.logout();
    }

    setLocale(locale: string) {
        this.sessionService.setLocale(locale);
    }

    getLocale(): string {
        return this.sessionService.getLocale();
    }

    async getUsageData(sapId: string, accountId: string): Promise<IAccountResponse> {
        return this.sessionService.getUsageData(sapId, accountId);
    }

    findPartialAccount(sapId: string): rxjs.Observable<IAccount[]> {
        return this.sessionService.findPartialAccount(sapId);
    }

    setLocaleSubject(localeSubject: rxjs.BehaviorSubject<string>) {
        this.sessionService.localeSubject = localeSubject;
    }

    getLocaleSubject(): rxjs.BehaviorSubject<string> {
        return this.sessionService.localeSubject;
    }

    async sendEmail(lang: string): Promise<boolean> {
        return this.sessionService.sendEmail(lang);
    }

    checkSessionStatus(idVal?: any) {
        return this.sessionService.checkSessionStatus(idVal);
    }

    getPDF(sessionId: number, lang: 'en-US' | 'en-CA' | 'fr-CA') {
        return this.sessionService.getPDF(sessionId, lang);
    }

    productSelectChanged(prodName: string, isSelected: boolean) {
        this.sessionService.productSelectChanged(prodName, isSelected);
    }

}
