import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as rxjs from 'rxjs';
import { tap, map, shareReplay, share } from 'rxjs/operators';

import { IAccount } from '@common/model/account';
import { IUser } from '@common/model/user';
import { ISession, Session, SessionUtils, DEFAULT_LOCALE } from '@common/model/session';
import { ISessionResponse, ICleanSessionsRequest, ISessionsResponse } from '@common/model/service/session-service';

import { SessionAPI } from './../../../server/services/session/session.api';

import { OutputPDFAPI } from 'server/services/output-pdf/output-pdf.api';
//import { DateLiteral } from '@common/type/date-literal';
//import { IAccountResponse } from '@common/model/service/account-service';
import { IAdminResponse } from '@common/model/service/admin-response';

/**
 * EVC Session Service
 */

const enum API {
    getSessions = '/api/admin/sessions',
    getProducts = '/api/admin/products',
    getActivities = '/api/admin/activities',
    getProduct = '/api/admin/product/',
    getActivity = '/api/admin/activity/',
    getProductDetails = '/api/admin/product-details/',
    getUsageData = '/api/account/',
    getUsers = '/api/admin/users',
    getRoles = '/api/admin/roles',
    getCodes = '/api/admin/codes',
    saveUserRole = '/api/admin/userrole',
    checkSessionStatus = '/api/session/status',
    getPDF = '/api/pdf',
    getEnrollment = '/api/admin/enrollment/',
    sendEmail = '/api/pdf/email',
    saveSession = '/api/session',
    //    submitEVC = '/api/enroll',
    findMatchingProfile = '/api/profiles',
    findMatchingProfile2 = '/api/profile/match',
    getPrice = '/api/price',
    getReferencePrice = '/api/ref_price',
    getPrices = '/api/price',
    cleanupSessions = '/api/session/clean',
    lockEnrollments = '/api/admin/session/lock'
}

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    private session: ISession;
    private currentSession: rxjs.BehaviorSubject<ISession>
    localeSubject = new rxjs.BehaviorSubject<string>(DEFAULT_LOCALE);

    constructor(
        private http: HttpClient
    ) {
        //        this.session = new Session();
        this.currentSession = new rxjs.BehaviorSubject<ISession>(this.session);
    }

    async getSessions(): Promise<ISessionsResponse> {
        console.log("getSessions");

        console.log("Calling: ", (API.getSessions));

        return this.http.get<ISessionsResponse>(API.getSessions)
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("getSessions resp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling getSessions: ", err);
                    })
            ).toPromise();

    }

    async getProducts(): Promise<IAdminResponse> {
        console.log("getProducts");

        console.log("Calling: ", (API.getProducts));

        return this.http.get<IAdminResponse>(API.getProducts)
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("getProducts resp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling getProducts: ", err);
                    })
            ).toPromise();
    }

    async getActivities(): Promise<IAdminResponse> {
        console.log("getActivities");

        console.log("Calling: ", (API.getActivities));

        return this.http.get<IAdminResponse>(API.getActivities)
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("getActivities resp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling getActivities: ", err);
                    })
            ).toPromise();
    }

    async getProduct(prodId: number): Promise<IAdminResponse> {
        console.log("getProduct");

        console.log("Calling: ", (API.getProduct));

        return this.http.get<IAdminResponse>(API.getProduct + prodId)
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("getProduct resp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling getProduct: ", err);
                    })
            ).toPromise();
    }

    async getActivity(actId: number): Promise<IAdminResponse> {
        console.log("getActivity");

        console.log("Calling: ", (API.getActivity));

        return this.http.get<IAdminResponse>(API.getActivity + actId)
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("getActivity resp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling getActivity: ", err);
                    })
            ).toPromise();
    }

    async getProductDetails(prodDetailId: number): Promise<IAdminResponse> {
        console.log("getProductDetails");

        console.log("Calling: ", (API.getProductDetails));

        return this.http.get<IAdminResponse>(API.getProductDetails + prodDetailId)
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("getProductDetails resp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling getProductDetails: ", err);
                    })
            ).toPromise();
    }

    getCurrentSession(): rxjs.Observable<ISession> {
        console.log("getCurrentSession...");
        //console.trace("here");
        return this.currentSession.asObservable();
    }

    clearSession() {
        console.log("clearSession");
        this.setSession(new Session());
    }

    async startAdminSession(acctInfo: IAccount): Promise<Session> {
        console.log("startAdminSession: ", acctInfo);
        const session = this.session = new Session();

        session.account = acctInfo;

        return session;
    }

    setSession(sess: ISession) {
        console.log("setNewSession: ", sess);
        //console.trace("here");
        this.session = sess;
        this.currentSession.next(sess);
    }

    getLocale() {
        return this.session && this.session.locale ? this.session.locale : DEFAULT_LOCALE;
    }

    setLocale(locale: string) {
        console.log("setLocale: " + locale);
        this.session.locale = locale;

        this.localeSubject.next(locale);
    }
    checkSessionStatus({ id }: { id: number }) {
        return this.http.get<SessionAPI['api']['/session/status']['get']>(
            `${API.checkSessionStatus}`, {
            params: { id: id + '' }
        }
        ).pipe(
            share(),
            shareReplay()
        )
    }

    getPDF(id: number) {
        return this.http.get<OutputPDFAPI['api']['/pdf']['get']>(
            `${API.getPDF}`,
            {
                params: {
                    id: id.toString(),
                    locale: this.session.locale
                }
            }
        ).pipe(
            share(),
            shareReplay()
        );
    }

    // // Makes server call to controller to update session info with final timestamps and data.
    // async endSession(): Promise<ISession> {
    //     console.log('endSession');
    //     var me = this,
    //         selectedProducts = SessionUtils.getSelectedProducts(this.session);

    //     try {

    //         var auditData = {
    //             evcSessionId: this.session.evc_session_id, //TODO: status.auditSessionId,
    //             prodList: []
    //         };

    //         // selectedProducts.forEach(function(prodDef) {
    //         //     auditData.prodList.push(SessionUtils.convertToAuditInfo(me.session, prodDef));
    //         // });

    //         var auditStr = JSON.stringify(auditData);
    //         console.log("auditStr=", auditStr);
    //         return this.session;

    //         //TODO: Call updateSession on backend.
    //         // // Calling Apex class controller 'updateSession' method
    //         // var action = cmp.get('c.updateSession');
    //         // action.setParam('auditSessionStr', auditStr);
    //         // action.setParam('operation', 'endSession');
    //         // action.setCallback(this, function(res) {
    //         //     console.log("c.updateSession success=", res.getState());
    //         //     var state = res.getState();
    //         //     if (state == 'ERROR') {
    //         //         console.log('updateSession error response=', JSON.stringify(res.getError(), null, 2));
    //         //     }
    //         //     console.log('updateSession response=', res.getState(), JSON.stringify(res.getReturnValue(), null, 2));
    //         // });
    //         // $A.enqueueAction(action);
    //     } catch (e) {
    //         console.error("Error calling notify: ", e);
    //     }

    // }

    /**
     *  Generate a pdf of this session server side and email to user.
     */
    async sendEmail(): Promise<boolean> {
        if (this.session.submitting) {
            return false;//  return rxjs.of({ success: false })
        }
        var id = this.session.evc_session_id;
        try {
            const result = await this.http.post<OutputPDFAPI['api']['/pdf/email']['post']>(
                `${API.sendEmail}`, {
                id
            }
            ).toPromise();
            console.log("sendEmail result=", result);
            return result.success == true;
        } catch (err) {
            console.error("Error sending pdf: ", err);
            return false;
        }
    }

    saveSessionPromise: Promise<ISessionResponse>;

    async saveSession(session: ISession): Promise<ISessionResponse> {
        console.log("saveSession: ", session);
        if (!session) {
            return { success: false, error: new Error('Null session') }
        }

        // saveSessionPromise is the promise returned by prior call to
        // saveSession.
        // It may or may not be completed yet.
        // If it's completed, this should continue through.
        // If its not completed, this will hang here until previous save is complete.
        if (this.saveSessionPromise) {
            var lastValue = await this.saveSessionPromise;
            console.log("lastValue=", lastValue);

            console.log("Proceeding with new save...");
            return this.saveSessionInner(session);
        } else {
            console.log("No save in process");
            return this.saveSessionInner(session);
        }

    }

    private async saveSessionInner(session: ISession): Promise<ISessionResponse> {
        console.log("saveSessionInner");
        try {
            // Hold onto promise containing last save call.
            //this.saveSessionPromise = this.http.post<ISessionResponse>(API.saveSession, session);
            console.log("Got observable");

            const saveObservable = this.saveSessionPromise = this.http.post<ISessionResponse>(API.saveSession,
                session)
                .pipe(
                    map(saveResp => {
                        console.log("saveResp: ", saveResp);

                        if (saveResp && saveResp.success == true && saveResp.session) {
                            SessionUtils.updateSession(session, saveResp.session);
                        }
                        return saveResp;
                    }),
                    //share(),
                    //shareReplay(),
                    tap(tapResp => {
                        console.log("tap: ", tapResp);
                    },
                        err => {
                            console.error("Error calling saveSession: ", err);
                            return rxjs.of({ success: false, error: err } as ISessionResponse);
                        })
                ).toPromise();

            // saveObservable.subscribe(() => {
            //     console.log("Done saving", arguments);
            // }, () => {
            //     console.log("Done saving2", arguments);
            // });

            var saveResp = await saveObservable;//.toPromise();
            return saveResp;

        } catch (err) {
            console.error("Error here: ", err);
            return null;
        }
    }

    // Saves final enrollment info to database.
    // Generates PDF
    // Emails enrollment info.
    // Returns success information as well as generated PDF doc (?)
    // TODO: Remember what final state of EVC is?  Just emailing user with details?
    // submitEVC(session: ISession = this.session): rxjs.Observable<ISessionResponse> {
    //     if (session.submitting) {
    //         return rxjs.of({ success: false })
    //     }
    //     session.submitting = true;
    //     session.submitDate = new DateLiteral();

    //     const result = this.http.post<ISessionResponse>(API.submitEVC, { ...this.session, locale: this.session.locale })
    //         .pipe(
    //             share(),
    //             shareReplay(),
    //             tap(enrollResp => {
    //                 console.log("enrollResp: ", enrollResp);
    //             },
    //                 err => {
    //                     console.error("Error calling submitEVC: ", err);
    //                 })
    //         );
    //     result.subscribe(() => {
    //         session.submitting = false;
    //     }, () => {
    //         session.submitting = false;
    //     });
    //     return result;
    // }

    getSession(): ISession {
        return this.session;
    }

    /**
     * When a user gets prompted if they want to re-use an old session, we need to
     * clean up all the old sessions.  If a user chooses to not re-use the old
     * session, we should clean it up as well.
     * So this call does the equivalent of deleting all non-submitted rows in the
     * enrollment table for this account.  If they re-use a session, delete all
     * but that one.
     */
    async cleanupSessions(cleanRequest: ICleanSessionsRequest): Promise<ISessionResponse> {
        console.log("cleanupSessions: ", cleanRequest);

        return this.http.post<ISessionResponse>(API.cleanupSessions, cleanRequest)
            .pipe(
                tap(cleanResp => {
                    console.log("Result of cleanup sessions call: ", cleanResp);
                },
                    err => {
                        console.error("Error calling cleanup sessions: ", err);
                    }
                )).toPromise();
    }

    validateSelectedProducts(session: ISession) {
        console.log("validateSelectedProducts");
        // Make sure all selected products are done.  Specifically, if user
        // selects 4dx, make sure they select pathType of defense vs sell
        var selectedProducts = SessionUtils.getSelectedProducts(session),
            isValid = true;
        if (!selectedProducts || selectedProducts.length < 1) {
            console.log("No selected products here");
            isValid = false;
        }
        //cmp.set("v.isValid", isValid);
        console.log("isValid=" + isValid);
    }

    getUsers(): rxjs.Observable<IAdminResponse> {
        return this.http.get<IAdminResponse>(API.getUsers);
    }

    getRoles(): rxjs.Observable<IAdminResponse> {
        return this.http.get<IAdminResponse>(API.getRoles);
    }

    getCodes(): Promise<IAdminResponse> {
        return this.http.get<IAdminResponse>(API.getCodes).toPromise();
    }

    saveUserRole(user: IUser): Promise<IAdminResponse> {
        console.log("saveUserRole: ", user);
        return this.http.post<IAdminResponse>(API.saveUserRole, user)
            .pipe(
                tap(saveResp => {
                    console.log("saveResp: ", saveResp);
                },
                    err => {
                        console.error('Error saving user role: ', err);
                        return rxjs.of({ success: false, error: err } as IAdminResponse);
                    })).toPromise();
    }


}
