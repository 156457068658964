import { Injectable, Injector } from '@angular/core';

import * as rxjs from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { AdminService } from '../service/admin.service';
import { SessionService } from '../service/session.service';

import { IAccountSettings } from '@common/model/account-settings';
import { IProduct } from '@common/model/product';
import { IAccount } from '@common/model/account';
import { IAdminResponse } from '@common/model/service/admin-response';
import { IUser } from '@common/model/user';
//import { IProfileMatchRequest, IProfileMatchResponse } from '@common/model/service/profile-match-service';
//import { ISystemSettingsResponse, ISystemSettings, IContextType } from '@common/model/system-settings';
import { ISession } from '@common/model/session';

//import { IDataResponse } from '@common/model/data-response';

@Injectable()
export class AdminFacade {

    private _authService: AuthService;
    private _adminService: AdminService;
    private _sessionService: SessionService;

    constructor(
        private injector: Injector
    ) {
    }

    public get adminService(): AdminService {
        if (!this._adminService) {
            this._adminService = this.injector.get(AdminService);
        }
        return this._adminService;
    }

    public get authService(): AuthService {
        if (!this._authService) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }

    public get sessionService(): SessionService {
        if (!this._sessionService) {
            this._sessionService = this.injector.get(SessionService);
        }
        return this._sessionService;
    }

    getSession(): ISession {
        return this.sessionService.getSession();
    }

    clearSession() {
        return this.sessionService.clearSession();
    }

    performAction(action: string): boolean {
        console.log("TODO: performAction: " + action);
        return true;
    }



    getUserDetails(): IUser {
        return this.authService.getUserDetails();
    }

    logout() {
        return this.authService.logout();
    }

    async getProducts(): Promise<IAdminResponse> {
        return this.adminService.getProducts();
    }

    async getActivities(): Promise<IAdminResponse> {
        return this.adminService.getActivities();
    }

    async getProduct(prodId: number): Promise<IAdminResponse> {
        return this.adminService.getProduct(prodId);
    }

    async getActivity(prodId: number): Promise<IAdminResponse> {
        return this.adminService.getActivity(prodId);
    }

    async getProductDetails(prodDetailId: number): Promise<IAdminResponse> {
        return this.adminService.getProductDetails(prodDetailId);
    }

    getUsers(): rxjs.Observable<IAdminResponse> {
        return this.adminService.getUsers();
    }

    getRoles(): rxjs.Observable<IAdminResponse> {
        return this.adminService.getRoles();
    }

    async saveUserRole(user: IUser): Promise<IAdminResponse> {
        return this.adminService.saveUserRole(user);
    }

    getPDF(sessionId: number, lang: 'en-US' | 'en-CA' | 'fr-CA') {
        return this.sessionService.getPDF(sessionId, lang);
    }

    async getCodes(): Promise<IAdminResponse> {
        return this.adminService.getCodes();
    }

}
