import { Injectable } from '@angular/core';
//import { Test } from './../utils/test';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { IAuthResp } from '@common/model/service/auth-service';
import * as rxjs from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

const FORBIDDEN = 403,
    UNAUTHORIZED = 401;
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    isRefreshing: boolean = false;
    refreshTokenSubject: rxjs.BehaviorSubject<any> = new rxjs.BehaviorSubject<any>(null);

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): rxjs.Observable<any> {//HttpEvent<any>> {
        //console.log("intercept: ", req);
        const jwtToken = this.authService.getToken();

        //        Test._serviceInject(req);

        //console.log("jwtToken=", jwtToken);
        if (jwtToken) {
            req = this.addToken(req, jwtToken);

            return next.handle(req).pipe(
                // tap((resp: any) => {
                //console.log('auth interceptor tap here!', resp);
                // }),//=> this.parseHeaders(resp)),
                catchError(err => {
                    console.log("Auth Error here: ", err);
                    if (err instanceof HttpErrorResponse) {

                        switch ((<HttpErrorResponse>err).status) {
                            case UNAUTHORIZED:
                                return this.handle401Error(req, next);
                            case FORBIDDEN:
                                return <any>this.authService.showLogin();
                            default:
                                return rxjs.throwError(err);
                        }
                    } else {
                        return rxjs.throwError(err);
                    }
                }));
        } else {
            return next.handle(req);
        }

    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        console.log("handle401Error");
        console.log("this.isRefreshing: " + this.isRefreshing);
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.callRefreshToken(null).pipe(
                switchMap((resp: IAuthResp) => {
                    console.log("After refresh token call: ", resp);
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(resp.authInfo.jwt_token);
                    return next.handle(this.addToken(request, resp.authInfo.jwt_token));
                }));
        } else {
            return this.refreshTokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(this.addToken(request, token));
                    }));
        }
    }

    private addToken(request: HttpRequest<any>, jwtToken: string) {
        //console.log("addToken: request=", request);

        request = request.clone({
            withCredentials: true,
            setHeaders: {
                'Authorization': 'Bearer ' + jwtToken
            }
        });
        //console.log("Cloned request=", request);

        return request;
    }

}
