<form id="activityForm" #activityForm="ngForm" name="activityForm">
  <div class="form-group">
	<label for="name">Name</label>
	<input type="text" class="form-control" name="name" [(ngModel)]="activity.name"/>
  </div>
  <div class="form-group">
	<label for="displayName">Display Name</label>
	<input type="text" class="form-control" name="display_name" [(ngModel)]="activity.display_name"/>
  </div>
  <!-- <div class="form-group"> -->
  <!-- 	<label for="localName">localName</label> -->
  <!-- 	<input type="text" class="form-control" name="localName" [(ngModel)]="activity.localName"/> -->
  <!-- </div> -->
  <!-- <div class="form-group"> -->
  <!-- 	<label for="description">description</label> -->
  <!-- 	<input type="text" class="form-control" name="description" [(ngModel)]="activity.description"/> -->
  <!-- </div> -->
  <!-- <div class="form-group"> -->
  <!-- 	<label for="display_order">display_order</label> -->
  <!-- 	<input type="text" class="form-control" name="display_order" [(ngModel)]="activity.display_order"/> -->
  <!-- </div> -->
  <!-- <div class="form-group"> -->
  <!-- 	<label for="active">active</label> -->
  <!-- 	<input type="text" class="form-control" name="active" [(ngModel)]="activity.active"/> -->
  <!-- </div> -->
  <!-- <div class="form-group"> -->
  <!-- 	<label for="pathType">pathType</label> -->
  <!-- 	<input type="text" class="form-control" name="pathType" [(ngModel)]="activity.pathType"/> -->
  <!-- </div> -->
  <!-- <div class="form-group"> -->
  <!-- 	<label for="video_url">video_url</label> -->
  <!-- 	<input type="text" class="form-control" name="video_url" [(ngModel)]="activity.video_url"/> -->
  <!-- </div> -->

  <!-- <h2>Activities</h2> -->
  <!-- <table class="table table-hover"> -->
  <!-- 	<thead> -->
  <!-- 	  <tr> -->
  <!-- 		<th scope="col">ID</th> -->
  <!-- 		<th scope="col">Name</th> -->
  <!-- 		<th scope="col">Developer Name</th> -->
  <!-- 		<th scope="col">Compliance</th> -->
  <!-- 		<th scope="col">Active?</th> -->
  <!-- 		<th scope="col">Savings</th> -->
  <!-- 		<th scope="col">Currency</th> -->
  <!-- 		<th scope="col">Display Order</th> -->
  <!-- 	  </tr> -->
  <!-- 	</thead> -->
  <!-- 	<tbody> -->
  <!-- 	  <tr *ngFor="let act of activity.activities" (click)="editActivity(act)"> -->
  <!-- 		<td>{{act.evc_activity_id}}</td> -->
  <!-- 		<td>{{act.display_name}}</td> -->
  <!-- 		<td>{{act.name}}</td> -->
  <!-- 		<td>{{act.compliance}}</td> -->
  <!-- 		<td>{{act.active}}</td> -->
  <!-- 		<td>{{act.savings}}</td> -->
  <!-- 		<td>{{act.currency_cd}}</td> -->
  <!-- 		<td>{{act.display_order}}</td> -->
  <!-- 	  </tr> -->
  <!-- 	</tbody> -->
  <!-- </table> -->

  <!-- <h2>Media</h2> -->
  <!-- <table class="table table-hover"> -->
  <!-- 	<thead> -->
  <!-- 	  <tr> -->
  <!-- 		<th scope="col">ID</th> -->
  <!-- 		<th scope="col">Country</th> -->
  <!-- 		<th scope="col">Name</th> -->
  <!-- 		<th scope="col">URL</th> -->
  <!-- 	  </tr> -->
  <!-- 	</thead> -->
  <!-- 	<tbody> -->
  <!-- 	  <tr *ngFor="let med of activity.mediaList" (click)="editMedia(med)"> -->
  <!-- 		<td>{{med.evc_media_id}}</td> -->
  <!-- 		<td>{{med.country_cd}}</td> -->
  <!-- 		<td>{{med.name}}</td> -->
  <!-- 		<td>{{med.video_url}}</td> -->
  <!-- 	  </tr> -->
  <!-- 	</tbody> -->
  <!-- </table> -->

</form>

<button class="spot-button spot-button--primary" (click)="saveActivity()" [disabled]="!saveEnabled" >Save</button>
<button class="spot-button spot-button--secondary" (click)="cancel()" >Cancel</button>
