import { Injectable } from '@angular/core';

@Injectable()
export class UtilService {

    constructor() {
    }

    static cleanName(name: string) {
        if (!name) {
            return name;
        }
        var cleanName = name.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
        // convert to upper case.
        cleanName = cleanName.toUpperCase();
        // Replace all non-alphanumeric with space.
        cleanName = cleanName.replace(/[^a-z0-9\xE0-\xFF]/gi, ' ');
        cleanName = cleanName.trim();
        cleanName = cleanName.replace(/ /g, '_');
        return cleanName;
    }

    static getErrorMessage(err: any): string {
        var msgParts: string[] = [];
        if (err) {
            if (typeof err == 'string') {
                msgParts.push(err);
            }
            if (err.name) {
                msgParts.push(err.name);
            }
            if (err.message) {
                msgParts.push(err.message);
            } else if (err.message2) {
                msgParts.push(err.message2);
            }
        }
        var msg = msgParts.join(' : ');
        return msg;
    }

    static trimToNull(str: string): string {
        var retVal = str;
        if (str && str.trim) {
            retVal = str.trim();
        }
        if (retVal === '' || retVal === 'null') {
            retVal = null;
        }
        return retVal;
    }

    static getCircularReplacer() {
        const seen = new WeakSet();
        return (key, value) => {
            console.log("key=", key);
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    console.log("Already seen: ", key, "" + value);
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };

}
