import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as rxjs from 'rxjs';
import { tap, map, shareReplay, share } from 'rxjs/operators';

import { IAccount } from '@common/model/account';
import { ISession, Session, ISessionEvent, SessionUtils, DEFAULT_LOCALE } from '@common/model/session';
import { ISessionResponse, ICleanSessionsRequest } from '@common/model/service/session-service';
import { IProductInfo } from '@common/model/session-product';
import { IActivityInfo } from '@common/model/session-activity';

import { SessionAPI } from './../../../server/services/session/session.api';

import { OutputPDFAPI } from 'server/services/output-pdf/output-pdf.api';
//import { DateLiteral } from '@common/type/date-literal';
import { IAccountResponse } from '@common/model/service/account-service';

/**
 * EVC Session Service
 */

const SDMA = 'SDMA',
    HEMA = 'HEMA',
    FECAL = 'FECAL',
    HEARTWORM = 'HEARTWORM';

const enum API {
    getUsageData = '/api/account/',
    findPartialAccount = '/api/isearch/account/',
    checkSessionStatus = '/api/session/status',
    getPDF = '/api/pdf',
    getEnrollment = '/api/admin/enrollment/',
    sendEmail = '/api/pdf/email',
    saveSession = '/api/session',
    submitEVC = '/api/submit',
    getEnrollments = '/api/admin/enrollments',
    findMatchingProfile = '/api/profiles',
    findMatchingProfile2 = '/api/profile/match',
    getPrice = '/api/price',
    getReferencePrice = '/api/ref_price',
    getPrices = '/api/price',
    cleanupSessions = '/api/session/clean',
    lockEnrollments = '/api/admin/session/lock'
}

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private session: ISession;
    private currentSession: rxjs.BehaviorSubject<ISession>
    localeSubject = new rxjs.BehaviorSubject<string>(DEFAULT_LOCALE);

    sessionSubject: rxjs.Subject<ISessionEvent>;

    constructor(
        private http: HttpClient
    ) {
        //        this.session = new Session();
        this.currentSession = new rxjs.BehaviorSubject<ISession>(this.session);

        this.sessionSubject = new rxjs.BehaviorSubject(null);
    }

    getCurrentSession(): rxjs.Observable<ISession> {
        console.log("getCurrentSession...");
        //console.trace("here");
        return this.currentSession.asObservable();
    }

    clearSession() {
        console.log("clearSession");
        this.setSession(new Session());
    }

    async startSession(acctResp: IAccountResponse, locale: string, oldSession?: ISession, retrieve?: boolean): Promise<ISession> {
        console.log("startSession: ", acctResp, retrieve);
        //console.log("oldSession=", oldSession);
        const session = this.session = new Session(acctResp, locale, retrieve ? oldSession : null);
        if (locale) {
            this.setLocale(locale);
        } else {
            this.setLocale(DEFAULT_LOCALE);
        }

        /**
         * For save and retrieve.
         * If user chooses to use old session data, clear out anything else (any
         *   unsubmitted sessions for this account other than oldSession.
         * If user choose to ignore old data, clear out all unsubmitted sessions,
         *   including oldSession.
         */
        if (oldSession) {
            try {
                console.log("Cleaning up old session data...");
                var cleanResp = await this.clearOldSessions(acctResp.account, retrieve ? oldSession : null);
                console.log("cleanResp=", cleanResp);
            } catch (err) {
                console.error("Error calling clearOldSessions: ", err);
            }
        }

        if (this.session.selectedProducts && this.session.selectedProducts.length > 0) {
            // TODO: Refactor.  This initializes navigation for save and retrieve.
            this.productSelectChanged(this.session.selectedProducts[0].name
                , true);
        }

        console.log("Calling saveSession...");
        var savedSession = await this.saveSession(this.session);
        console.log("savedSession = ", savedSession);

        if (savedSession && savedSession.session) {
            session.evc_session_id = savedSession.session.evc_session_id;
        }

        console.log("Publishing currentSession...", session);
        //console.trace("here");
        this.currentSession.next(session);

        return session;
    }

    async startAdminSession(acctInfo: IAccount): Promise<Session> {
        console.log("startAdminSession: ", acctInfo);
        const session = this.session = new Session();

        session.account = acctInfo;

        return session;
    }

    setSession(sess: ISession) {
        console.log("setNewSession: ", sess);
        //console.trace("here");
        this.session = sess;
        this.currentSession.next(sess);
    }

    getLocale() {
        return this.session && this.session.locale ? this.session.locale : DEFAULT_LOCALE;
    }

    setLocale(locale: string) {
        console.log("setLocale: " + locale);
        this.session.locale = locale;

        this.localeSubject.next(locale);
    }

    checkSessionStatus({ id }: { id: number }) {
        return this.http.get<SessionAPI['api']['/session/status']['get']>(
            `${API.checkSessionStatus}`, {
            params: { id: id + '' }
        }
        ).pipe(
            share(),
            shareReplay()
        )
    }

    getPDF(sessionId: number, lang: 'en-US' | 'en-CA' | 'fr-CA') {
        console.log("getPDF: ", sessionId, lang);
        return this.http.get<OutputPDFAPI['api']['/pdf']['get']>(
            `${API.getPDF}`,
            {
                params: {
                    id: sessionId.toString(),
                    locale: lang || this.session.locale
                }
            }
        ).pipe(
            share(),
            shareReplay()
        );
    }

    // // Makes server call to controller to update session info with final timestamps and data.
    // async endSession(): Promise<ISession> {
    //     console.log('endSession');
    //     var me = this,
    //         selectedProducts = SessionUtils.getSelectedProducts(this.session);

    //     try {

    //         var auditData = {
    //             evcSessionId: this.session.evc_session_id, //TODO: status.auditSessionId,
    //             prodList: []
    //         };

    //         selectedProducts.forEach(function(prodDef) {
    //             auditData.prodList.push(SessionUtils.convertToAuditInfo(me.session, prodDef));
    //         });

    //         // var auditStr = JSON.stringify(auditData);
    //         // console.log("auditStr=", auditStr);
    //         return this.session;

    //         //TODO: Call updateSession on backend.
    //         // // Calling Apex class controller 'updateSession' method
    //         // var action = cmp.get('c.updateSession');
    //         // action.setParam('auditSessionStr', auditStr);
    //         // action.setParam('operation', 'endSession');
    //         // action.setCallback(this, function(res) {
    //         //     console.log("c.updateSession success=", res.getState());
    //         //     var state = res.getState();
    //         //     if (state == 'ERROR') {
    //         //         console.log('updateSession error response=', JSON.stringify(res.getError(), null, 2));
    //         //     }
    //         //     console.log('updateSession response=', res.getState(), JSON.stringify(res.getReturnValue(), null, 2));
    //         // });
    //         // $A.enqueueAction(action);
    //     } catch (e) {
    //         console.error("Error calling notify: ", e);
    //     }

    // }

    /**
     *  Generate a pdf of this session server side and email to user.
     */
    async sendEmail(lang: string): Promise<boolean> {
        if (this.session.submitting) {
            return false;//  return rxjs.of({ success: false })
        }
        var id = this.session.evc_session_id;
        try {
            const result = await this.http.post<OutputPDFAPI['api']['/pdf/email']['post']>(
                `${API.sendEmail}`, {
                id,
                lang: lang
            }
            ).toPromise();
            console.log("sendEmail result=", result);
            if (result.success == true) {
                return true;
            } else if (result.error) {
                throw result.error;
            }
            return false;
        } catch (err) {
            console.error("Error sending pdf: ", err);
            throw err;
        }
    }

    async clearOldSessions(acct: IAccount, keepSession: ISession) {
        console.log("clearOldSessions");
        var cleanRequest: ICleanSessionsRequest = {
            sapId: acct.sap_id,
            ignore_evc_session_id: keepSession ? keepSession.evc_session_id : null

        };
        return await this.cleanupSessions(cleanRequest);
    }

    saveSessionPromise: Promise<ISessionResponse>;

    async saveSession(session: ISession): Promise<ISessionResponse> {
        console.log("saveSession: ", session);
        if (!session) {
            return { success: false, error: new Error('Null session') }
        }

        // saveSessionPromise is the promise returned by prior call to
        // saveSession.
        // It may or may not be completed yet.
        // If it's completed, this should continue through.
        // If its not completed, this will hang here until previous save is complete.
        if (this.saveSessionPromise) {
            var lastValue = await this.saveSessionPromise;
            console.log("lastValue=", lastValue);

            console.log("Proceeding with new save...");
            return this.saveSessionInner(session);
        } else {
            console.log("No save in process");
            return this.saveSessionInner(session);
        }

    }

    private async saveSessionInner(session: ISession): Promise<ISessionResponse> {
        console.log("saveSessionInner");
        try {
            // Hold onto promise containing last save call.
            //this.saveSessionPromise = this.http.post<ISessionResponse>(API.saveSession, session);
            console.log("Got observable");

            const saveObservable = this.saveSessionPromise = this.http.post<ISessionResponse>(API.saveSession,
                session)
                .pipe(
                    map(saveResp => {
                        console.log("saveResp: ", saveResp);

                        if (saveResp && saveResp.success == true && saveResp.session) {
                            SessionUtils.updateSession(session, saveResp.session);
                        }
                        return saveResp;
                    }),
                    //share(),
                    //shareReplay(),
                    tap(tapResp => {
                        console.log("tap: ", tapResp);
                    },
                        err => {
                            console.error("Error calling saveSession: ", err);
                            return rxjs.of({ success: false, error: err } as ISessionResponse);
                        })
                ).toPromise();

            // saveObservable.subscribe(() => {
            //     console.log("Done saving", arguments);
            // }, () => {
            //     console.log("Done saving2", arguments);
            // });

            var saveResp = await saveObservable;//.toPromise();
            return saveResp;

        } catch (err) {
            console.error("Error here: ", err);
            return null;
        }
    }

    // Saves final enrollment info to database.
    // Generates PDF
    // Emails enrollment info.
    // Returns success information as well as generated PDF doc (?)
    // TODO: Remember what final state of EVC is?  Just emailing user with details?
    submitEVC(session: ISession = this.session): rxjs.Observable<ISessionResponse> {
        if (session.submitting) {
            return rxjs.of({ success: false })
        }
        session.submitting = true;
        session.submitDate = new Date();//Literal();

        const result = this.http.post<ISessionResponse>(API.submitEVC, { ...this.session, locale: this.session.locale })
            .pipe(
                share(),
                shareReplay(),
                tap(enrollResp => {
                    console.log("enrollResp: ", enrollResp);
                },
                    err => {
                        console.error("Error calling submitEVC: ", err);
                    })
            );
        result.subscribe(() => {
            session.submitting = false;
        }, () => {
            session.submitting = false;
        });
        return result;
    }

    getSession(): ISession {
        return this.session;
    }

    /**
     * When a user gets prompted if they want to re-use an old session, we need to
     * clean up all the old sessions.  If a user chooses to not re-use the old
     * session, we should clean it up as well.
     * So this call does the equivalent of deleting all non-submitted rows in the
     * enrollment table for this account.  If they re-use a session, delete all
     * but that one.
     */
    async cleanupSessions(cleanRequest: ICleanSessionsRequest): Promise<ISessionResponse> {
        console.log("cleanupSessions: ", cleanRequest);

        return this.http.post<ISessionResponse>(API.cleanupSessions, cleanRequest)
            .pipe(
                tap(cleanResp => {
                    console.log("Result of cleanup sessions call: ", cleanResp);
                },
                    err => {
                        console.error("Error calling cleanup sessions: ", err);
                    }
                )).toPromise();
    }

    async getUsageData(sapId: string, accountId: string): Promise<IAccountResponse> {
        console.log("getUsageData: ", sapId, accountId);
        console.log("Calling to", API.getUsageData);
        var acctResp = await this.http.get<IAccountResponse>(API.getUsageData + sapId).toPromise();
        this.parseUsageResults(acctResp);
        return acctResp;
    }

    findPartialAccount(sapId: string): rxjs.Observable<IAccount[]> {
        console.log("findPartialAccount: ", sapId);
        console.log("Calling to", API.findPartialAccount);
        return this.http.get<IAccountResponse>(API.findPartialAccount + sapId)
            .pipe(
                map(partResp => {
                    console.log("partResp:", partResp);
                    if (partResp && partResp.success == true && partResp.accounts) {
                        return partResp.accounts;
                    }
                    return [];
                })// ,
                // tap(tapResp => {
                //     console.log("tap: ", tapResp);
                // },
                //     err => {
                //         console.error("Error calling findPartial: ", err);
                //         return rxjs.of([] as IAccount[]);
                //     })
            );;
    }

    parseUsageResults(accountResp: IAccountResponse) {
        console.log("parseUsageResults", accountResp);

        var usageData = accountResp.usageData,
            account = accountResp.account;
        //            userEmail = accountResp.user.email,
        //currency = account.currency_cd;
        //            session = this.newSession(),
        //            status = this.newStatus();
        //evc_session_id = accountResp.evc_session_id;

        // status.evc_session_id = evc_session_id;
        // session.evc_session_id = evc_session_id;

        // Note that a new session begins when user searches for account id.  This
        // will wipe out all selected products, profit calculations, etc.
        // session.userEmail = userEmail;

        // session.account = account;
        // session.account_id = account.sf_id;

        // var isCanada = (session.account.country_cd == 'CA');
        // session.isCanada = isCanada;

        //console.log("usageSummary = ", usageData);
        //console.log("account currency = ", currency);

        if (usageData) {

            var productUsage = usageData.productUsage;
            productUsage = productUsage.sort(function(a: IProductInfo, b: IProductInfo) {
                return a.display_order - b.display_order;
            });

            if (productUsage && productUsage.length > 0) {
                productUsage.forEach(function(ud: IProductInfo) {
                    ud.origAverageRun = ud.runsPerMonth;

                    if (ud.details) {
                        ud.video_url = ud.details.video_url;
                    }

                    if (ud.name == HEMA) {
                        ud.iconName = 'cbc';
                    } else if (ud.name == FECAL) {
                        ud.iconName = 'fecal';
                    } else if (ud.name == SDMA) {
                        ud.iconName = 'sdma';
                    } else if (ud.name == HEARTWORM) {
                        ud.iconName = '4dx';
                    }

                    if (ud.activities) {
                        ud.activities.forEach(function(act: IActivityInfo) {
                            act.origCompliance = act.compliance;
                            act.origSavings = act.savings;
                        });
                    }

                });
            }

            try {
                // Disable continue button until user selects at least one product...
                this.validateSelectedProducts(this.session);

                // console.log("Calling setSessionData...");
                // this.setSessionData(session);

            } catch (e) {
                console.error("Error: ", e);
            }
        }

    }

    validateSelectedProducts(session: ISession) {
        console.log("validateSelectedProducts");
        // Make sure all selected products are done.  Specifically, if user
        // selects 4dx, make sure they select pathType of defense vs sell
        var selectedProducts = SessionUtils.getSelectedProducts(session),
            isValid = true;
        if (!selectedProducts || selectedProducts.length < 1) {
            console.log("No selected products here");
            isValid = false;
        }
        //cmp.set("v.isValid", isValid);
        console.log("isValid=" + isValid);
    }

    productSelectChanged(prodName: string, isSelected: boolean) {
        console.log("setProductSelected: " + prodName + ": checked=" + isSelected);

        var session = this.session,
            prodDef = this.getUsage(session, prodName);
        console.log("prodDef=", prodDef);

        prodDef.selected = isSelected;

        SessionUtils.updateSelectedProducts(session);

        //this.setSessionData(session);

        this.checkProductPaths(session);

        // this.resetNav();

        // this.setPrevNav(null);

        this.fireEvent(isSelected ? 'productsSelected' : 'productsDeselected');

        SessionUtils.validateSelectedProducts(this.session);

    }

    getSessionSubject(): rxjs.Subject<ISessionEvent> {
        return this.sessionSubject;
    }

    fireEvent(name: string) {
        console.log("fireEvent: ", name);
        var prodEvent: ISessionEvent = {
            name: name,
            session: this.session
        }
        this.sessionSubject.next(prodEvent);
    }

    getUsage(session: ISession, prodName: string) {
        console.log('getUsage: ' + prodName, session);
        var products = session.products;
        for (var ix = 0; ix < products.length; ix++) {
            var usage = products[ix];
            if (usage.name == prodName) {
                return usage;
            }
        }
        console.error('Can\'t find product: ' + prodName);
        return null;
    }

    checkProductPaths(session: ISession) {
        console.log("checkProductPath");

        var hasSell = false;
        if (session.selectedProducts.length > 0) {
            hasSell = true;
        }

        session.hasSellProducts = hasSell;

    }

}
