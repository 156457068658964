import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppFacade } from '../../../facade/app.facade';
import { ISession, SessionUtils } from '@common/model/session';
import { IActivityInfo } from '@common/model/session-activity';
import { IProductInfo } from '@common/model/session-product';

@Component({
    selector: 'evc-adjust-price',
    templateUrl: './adjust-price.component.html',
    styleUrls: ['./adjust-price.component.scss']
})
export class AdjustPriceComponent implements OnInit {
    @Input() selectedProduct: IProductInfo;
    @Input() selectedActivity: IActivityInfo;

    session: ISession;
    saving: boolean = false;
    invalid: boolean = true;

    totalRevenue: number;

    totalCost: number;

    totalProfit: number;
    profitPer: number;

    constructor(
        private activeModal: NgbActiveModal,
        private appFacade: AppFacade
    ) { }

    ngOnInit(): void {
        console.log("selectedActivity=", this.selectedActivity);
        this.session = this.appFacade.getSession();
        this.profitPer = this.selectedActivity ? this.selectedActivity.savings : 0;
        this.totalRevenue = this.selectedActivity.totalRevenue;
        this.totalCost = this.selectedActivity.totalCost;

        // Avoid showing Net Profit unless user has entered revenue and cost here
        if (this.totalRevenue && this.totalCost) {
            this.totalProfit = this.selectedActivity.totalProfit;
        }

        this.recalcTotals();

        //TODO: Get currency and locale from session.

        console.log("totalCost=", this.totalCost);
        console.log("totalRevenue=", this.totalRevenue);
        console.log("totalProfit=", this.totalProfit);
        console.log("profitPer=", this.profitPer);

        this.validateForm();

    }

    // Ensure profit is greater/equal to zero
    validateForm() {
        if (this.totalRevenue > 0
            && this.totalCost > 0
            && this.totalProfit > 0) {
            this.invalid = false;
        } else {
            this.invalid = true;
        }
    }

    closeModal() {
        console.log("closeModal");
        this.activeModal.close(false);
    }

    async save() {
        this.selectedActivity.savings = this.profitPer;
        this.selectedActivity.totalCost = this.totalCost;
        this.selectedActivity.totalRevenue = this.totalRevenue;
        this.selectedActivity.totalProfit = this.totalProfit;
        SessionUtils.calcTotals(this.session);

        this.saving = true;
        await this.appFacade.saveSession();
        this.saving = false;

        this.activeModal.close(true);
    }

    recalcTotals() {
        console.log("recalcTotals()", this.totalRevenue, this.totalCost);

        if (this.totalRevenue) {
            this.totalRevenue = Math.round(this.totalRevenue);
        }
        if (this.totalCost) {
            this.totalCost = Math.round(this.totalCost);
        }

        if (this.totalRevenue >= 0 && this.totalCost >= 0) {
            console.log("act.profit_percent=", this.selectedActivity.profit_percent);
            const profitPct = this.selectedActivity.profit_percent || 100;
            this.totalProfit = Math.round(this.totalRevenue - this.totalCost);
            this.profitPer = this.totalProfit * profitPct / 100;
        }

        // Ensure profit is greater/equal to zero
        this.validateForm();

        console.log("this.totalProfit = ", this.totalProfit);
    }

    updateCostAmount(event: any) {
        console.log("updateCostAmount: ", event.target.value);
        this.totalCost = event.target.value;
    }

    ensureVisible(e: any) {
        console.log("ensureVisible: ", e);
        //        setTimeout(this.scrolling, 500, e.target, 0);
    }

    scrolling(e: any, c: any) {
        e.scrollIntoView();
        if (c < 5) setTimeout(this.scrolling, 300, e, c + 1);
    }

}
