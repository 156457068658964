<div class="evc-layout vbox">

  <header class="pageHeader">
	<div horizontalAlign="stretch" verticalAlign="center" class="evcPageHeader"> <!-- evc header -->
	  <div class="evcBrandLink">
		<button class="spot-button spot-button--icon-only evcHomeLink" (click)="goHome()" title="Home" alternativeText="Home" >
		  <svg class="spot-icon" aria-labelledby="title">
			<title>Home</title>
			<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#home"></use>
		  </svg>
		</button>
		<img src="/assets/images/IDEXX_logo.resource" class="headerLogo"/>
	  </div>
	  <div style="flex:1">
		<h1 class="appName">Economic Value Calculator - Admin</h1>
	  </div>
	  <div class="evcBrandLink">
	  </div>
	  <!-- <ng-template alignmentBump="left"> -->
		<!-- </ng-template> -->

	</div>
  </header>
  <div class="evc-body vbox ">
	<div class="evcBody ">
	  <div id="mainToastDiv"></div>

	  <router-outlet></router-outlet>

	</div>
  </div>

  <footer class='pageFooter'>


  </footer>

</div>

