  <div class="loading-overlay" *ngIf="loading">
    <div id='ajax_loader' class="loading-indicator" style="z-index: 400; background-color: rgba(175, 217, 238,.9); text-align: center;" id="loading-indicator">
	  <svg class="spot-icon spot-loading-spinner spot-loading-spinner--large" style="height:64px;width:64px;" aria-labelledby="title">
		<title>spinner</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#spinner"></use>
	  </svg>
      <div style="background-color: rgba(250,250,250,.05);border-radius: 10px;padding:5px;margin-top: 5px;">
        <font size="+1"><strong>{{msg}}</strong></font>
      </div>
    </div>
  </div>
