<table class="table table-hover" matSort (matSortChange)="sortData($event)" >
  <thead>
  <tr>
	<th scope="col" mat-sort-header="id">ID</th>
	<th scope="col" mat-sort-header="name">Name</th>
	<th scope="col" mat-sort-header="code">Code</th>
	<th scope="col" mat-sort-header="contextCode">Context</th>
	<th scope="col" mat-sort-header="groupName">Product</th>
	<th scope="col" mat-sort-header="active">Active</th>
	<th scope="col" mat-sort-header="level">Level</th>
	<th scope="col" mat-sort-header="created_on">Created On</th>
	<th scope="col" mat-sort-header="updated_on">Updated On</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let code of codes" (click)='selectCode(code)' [class]="code == selectedCode ? 'selected' : ''">
	<td>{{code.evc_test_code_id}}</td>
	<td>{{code.name}}</td>
	<td>{{code.code}}</td>
	<td>{{code.contextCode}}</td>
	<td>{{code.groupName}}</td>
	<td>{{code.active}}</td>
	<td>{{code.level}}</td>
	<td>{{code.created_on}}</td>
	<td>{{code.updated_on}}</td>
  </tr>
  </tbody>
</table>
