import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { VersionAPI } from '../../../server/services/version'
import { AuthService } from 'client/app/auth/auth.service';

@Injectable()
export class VersionService {
    version: BehaviorSubject<VersionAPI['api']['/version']['get']['data']> = new BehaviorSubject(null);
    _request: Observable<VersionAPI['api']['/version']['get']>;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.doTimer();
    }

    doTimer() {
        setTimeout(() => {
            this.tick();
        }, 1000 * 30);
    }

    async tick() {
        if (this.authService.isLoggedIn()) {
            try {
                const result = await this._requestVersion();
                if (this.version.getValue() !== result.data) {
                    this.version.next(result.data);
                }
            } catch (error) {
                console.error(error);
            }
        }
        this.doTimer();
    }

    _requestVersion() {
        return this.http.get<VersionAPI['api']['/version']['get']>(`api/version`).toPromise();
    }
}
