import { Component, OnInit } from '@angular/core';

import { Sort } from '@angular/material/sort';
import { UtilService } from '@common/service/util.service';
import { AdminFacade } from '../../../facade/admin.facade';

import { IUser } from '@common/model/user';
import { IRole } from '@common/model/role';
import { EVCAlertService } from '../../../service/alert.service';
import { IAdminResponse } from '@common/model/service/admin-response';

@Component({
    selector: 'evc-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

    users: IUser[];
    roles: IRole[];

    sortedData: IUser[] = [];
    lastSort: Sort;

    selectedUser: IUser;
    origUser: IUser;

    message: string;
    saving: boolean;

    constructor(
        private adminFacade: AdminFacade,
        private alertService: EVCAlertService
    ) { }

    ngOnInit(): void {
        this.adminFacade.getUsers().subscribe(resp => {
            console.log("Got users: ", resp);
            if (resp.success) {
                this.users = resp.users;

                this.sortedData = this.users.slice();
                this.resort();

            } else {
                console.error("Error retrieving users: ", resp);
            }
        }, err => {
            console.error("Error retrieving users2: ", err);
        });

        this.adminFacade.getRoles().subscribe(resp => {
            console.log("Got roles: ", resp);
            if (resp.success) {
                this.roles = resp.roles;
            } else {
                console.error("Error retrieving roles: ", resp);
            }
        }, err => {
            console.error("Error retrieving roles2: ", err);
        });

    }

    configUser(user: IUser) {
        console.log("configUser: ", user);
        this.origUser = user;
        this.selectedUser = JSON.parse(JSON.stringify(user));
    }

    isSelected(user: IUser): boolean {
        return (user && this.selectedUser && user.user_id == this.selectedUser.user_id);
    }

    roleSelected() {
        console.log("roleSelected: ", this.selectedUser);
    }

    compareRole(r1: IRole, r2: IRole): boolean {
        //console.log("compareRole: ", r1, r2);
        var retVal = r1 && r2 ? r1.role_id === r2.role_id : r1 === r2;
        //console.log("retVal=", retVal);
        return retVal;
    }

    async save() {
        console.log("save user role", this.selectedUser);

        this.alertService.setBusy(true, 'Saving...');

        this.message = "Saving...";
        this.saving = true;

        try {

            const saveResp: IAdminResponse = await this.adminFacade.saveUserRole(this.selectedUser);
            console.log("saveUserRole result: ", saveResp);

            this.alertService.setBusy(false);
            this.saving = false;
            delete this.message;

            if (saveResp.success == true && saveResp.user) {
                this.message = "Save sucessful";
                this.origUser.role = saveResp.user.role;
            } else {
                this.message = 'Error saving user: ' + UtilService.getErrorMessage(saveResp.error);

            }
        } catch (err) {
            console.error("Error saving user: ", err);
            this.message = 'Error saving user: ' + UtilService.getErrorMessage(err);
            this.alertService.setBusy(false);

            this.saving = false;

        }
    }

    cancel() {
        //TODO: Edit on temp object.  After save, update local user.
        delete this.selectedUser;
    }

    resort() {
        console.log("resort");
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({ active: "context", direction: "asc" })
        }
        console.log("lastSort not defined, not sorting...");
    }

    sortData(sort: Sort) {
        console.log("sortData: ", sort);
        const data = this.users.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a: IUser, b: IUser) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name': return compare(a.name, b.name, isAsc);
                case 'role': return compare(a.role.name, b.role.name, isAsc);
                case 'sfUsername': return compare(a.sfdcUsername, b.sfdcUsername, isAsc);
                case 'email': return compare(a.email, b.email, isAsc);
                case 'profile': return compare(a.sfdcProfile.name, b.sfdcProfile.name, isAsc);
                default: return 0;
            }
        });
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
