<div class="mainLayout vbox">
  <div class="dog-background">
	<div class="sso-panel">

	  <div class="sapHeader">
		<div class="idexxLogo">
		    <img src="/assets/images/IDEXX_logo.resource" class="headerLogo" />
		</div>
	  </div>
	  <div>
		<h1 style="font-size:12px;text-align:center;">IDEXX <strong>Preventative Care Challenge</strong></h1>
	  </div>
	  <div class="sapInput">
		<h1>SSO successful </h1>
		Login complete
	  </div>
	</div>
  </div>
</div>
