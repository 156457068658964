import { Component, OnInit } from '@angular/core';

import { AuthService } from '../auth.service';

@Component({
    selector: 'evc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    error: string;

    constructor(
        private auth: AuthService,
    ) { }

    ngOnInit() {
        console.log("login screen here...");
    }

    public submit() {
        this.auth.doLogin();
    }
}
