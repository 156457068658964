import { Component, OnInit } from '@angular/core';
import { AdminFacade } from '../../../facade/admin.facade';
import { Router } from '@angular/router';
import { IProduct } from '@common/model/product';
import { IActivity } from '@common/model/activity';

@Component({
    selector: 'evc-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
    activities: IActivity[];
    selectedActivity: IActivity;

    constructor(
        private router: Router,
        private adminFacade: AdminFacade
    ) { }

    async ngOnInit() {
        try {
            var actResp = await this.adminFacade.getActivities();
            this.activities = actResp.activities;
        } catch (err) {
            console.error("Error retrieving activities: ", err);
        }
    }

    selectActivity(act: IActivity) {
        console.log("selectActivity: ", act, arguments);
        this.selectedActivity = act;

        this.router.navigate(['/admin/edit-activity', act.evc_activity_id]);
    }

}
