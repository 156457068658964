import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IProductInfo } from '@common/model/session-product';
import { ISession } from '@common/model/session';
import { AppFacade } from '../../../facade/app.facade';

@Component({
    selector: 'evc-evc-summary',
    templateUrl: './evc-summary.component.html',
    styleUrls: ['./evc-summary.component.scss']
})
export class EvcSummaryComponent implements OnInit {
    session: ISession;

    constructor(
        private router: Router,
        private appFacade: AppFacade
    ) { }

    ngOnInit(): void {
        this.session = this.appFacade.getSession();
    }

    viewSelectProducts() {
        console.log("viewSelectProducts");
        this.router.navigate(['/select-products']);
    }

    editProduct(prod: IProductInfo) {
        this.router.navigate(['product', prod.name, 'editRuns']);
    }

    goDone() {
        this.router.navigate(['/done']);
    }

}
