import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISession } from '@common/model/session';

@Component({
    selector: 'evc-partial-session',
    templateUrl: './partial-session.component.html',
    styleUrls: ['./partial-session.component.scss']
})
export class PartialSessionComponent implements OnInit {

    @Input() oldSession: ISession;

    constructor(
        private activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
    }

    cancelPartial() {
        console.log("cancelPartial");
        this.activeModal.close(false);
    }

    retrievePartial() {
        console.log("retrievePartial");
        this.activeModal.close(true);
    }

}
