import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppFacade } from '../../../facade/app.facade';
import { IProductInfo } from '@common/model/session-product';
import { IActivityInfo } from '@common/model/session-activity';
import { SessionUtils } from '@common/model/session';
import { NavService, INavItem } from '../../../service/nav.service';
import { ISession } from '@common/model/session';
import { AdjustPriceComponent } from '../../components/adjust-price/adjust-price.component';

declare global { interface Window { Sniff: any; } }

@Component({
    selector: 'evc-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    systemError: any;
    selectedProduct: IProductInfo;
    prodName: string;
    pageInfo: INavItem;
    subPage: string;
    session: ISession;
    isDesktop: boolean = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appFacade: AppFacade,
        private navService: NavService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.session = this.appFacade.getSession();

        console.log("route.snapshot.paramMap=", this.route.snapshot.paramMap);
        this.route.paramMap.subscribe(params => {
            console.log("route params changed: ", params);
            this.prodName = params.get('prodName');
            this.subPage = params.get('subPage');

            this.pageInfo = this.navService.findProductNav(this.prodName, this.subPage);
            console.log("pageInfo=", this.pageInfo);

            this.selectedProduct = SessionUtils.getProduct(this.appFacade.getSession(), this.prodName);
            if (this.session) {
                this.session.selectedProduct = this.selectedProduct;
            }
            console.log("selectedProduct=", this.selectedProduct);
            if (this.selectedProduct) {
                console.log("selectedProduct.activities=", this.selectedProduct.activities);
            }
            //this.pageInfo = this.navService.getActiveNav();
        });
        if (!this.selectedProduct) { // For testing
            this.selectedProduct = {};
        }

        // Only show dashboard links when user is not on ipad...
        this.isDesktop = this.getIsDesktop();
        window.onresize = () => {
            this.isDesktop = this.getIsDesktop();
        };
    }

    editProductCalcs() {
        this.router.navigate(['/product', this.prodName, 'adjustCalc']);
    }

    complianceChanged(act: IActivityInfo) {
        console.log("complianceChanged: ", act);
    }

    recalcTotals(act: IActivityInfo) {
        console.log("recalcTotals");

        if (act) {
            this.limitCompliance(act);
        }
        SessionUtils.calcTotals(this.session);
    }

    // Limit compliance value to a max of 100%
    limitCompliance(act: IActivityInfo) {
        console.log("limitCompliance: ", act);
        if (act) {
            act.compliance = Math.min(act.compliance, 100);
        }
    }

    adjustPrice(act: IActivityInfo) {
        console.log("adjustPrice: ", act);
        const ref = this.modalService.open(AdjustPriceComponent, {
            animation: true,
            //centered: true,
            size: 'xl',
            windowClass: 'adjust-price'
        });
        if (ref.componentInstance) {
            ref.componentInstance.selectedProduct = this.selectedProduct;
            ref.componentInstance.selectedActivity = act;
        }
    }

    hasEdits() {
        var retVal = false;
        if (this.selectedProduct && this.selectedProduct.activities) {
            for (let act of this.selectedProduct.activities) {
                if (act.totalCost || act.totalRevenue) {
                    retVal = true;
                    break;
                }
            }
        }
        return retVal;
    }

    getIsDesktop(): boolean {
        console.log("window.sniff=", window.Sniff, window);
        // var sniff = window.sniff;
        // console.log("sniff=", sniff);
        if (window.Sniff) {
            if (window.Sniff.os && window.Sniff.os.name == 'ios') {
                return false;
            } else if (window.Sniff.features && window.Sniff.features.mobile == true) {
                return false;
            }
        }

        const w = document.documentElement.clientWidth;
        const breakpoint = 1025;
        console.log(w);
        if (w < breakpoint) {
            return false;
        } else {
            return true;
        }
    }

}
