import { Component, OnInit } from '@angular/core';
import { AuthService } from 'client/app/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'evc-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            console.log("params=", params);
            const code = params.code;
            this.authService.loginWithCode(code, '/search').subscribe(v => { console.log("v=", v); },
                (err: Error) => {
                    console.error("Error logging in", err);
                });
        });
    }

}
