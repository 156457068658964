import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAccountResponse } from '@common/model/service/account-service';
import { AuthService } from '../../../auth/auth.service';
import { EVCAlertService } from '../../../service/alert.service';
import { AppFacade } from '../../../facade/app.facade';
import { IAccount } from '@common/model/account';
import { NavService } from '../../../service/nav.service';
import { UtilService } from '@common/service/util.service';
import { PartialSessionComponent } from '../../components/partial-session/partial-session.component';
import { ISession } from '@common/model/session';

@Component({
    selector: 'evc-sap-search',
    templateUrl: './sap-search.component.html',
    styleUrls: ['./sap-search.component.scss']
})
export class SAPSearchComponent implements OnInit {

    sapId: number;
    systemError: any;
    searching: boolean = false;
    searchFailed: boolean = false;

    accountError: string;

    locale: string = 'en-US';

    //TODO: Move to nav service
    navInfo: any;
    pageInfo: any;
    page: any;
    subPage: any;

    isValid: boolean;

    hasAdmin: boolean = false;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private appFacade: AppFacade,
        private navService: NavService,
        private alertService: EVCAlertService,
        private authService: AuthService
    ) {
        this.hasAdmin = this.authService.hasAccess('ADMIN_TOOL');
        console.log("hasAdmin=", this.hasAdmin);
    }

    ngOnInit(): void {
        console.log("doInit");

        document.title = "Economic Value Calculator";

        // var platform = cmp.get('v.platform'),
        //     isBrowser = (platform == 'browser');
        // console.log('platform = ' + platform);

        // if (!isBrowser) {
        //     helper.initFromLocalStorage();
        // }

        // if (!isBrowser && helper.getConfigData()) {
        //     console.log("Reloaded saved state");
        // } else {
        //     console.log("Starting from scratch...");

        //     helper.setSessionData(helper.newSession());
        //     helper.setStatusData(helper.newStatus());

        //     helper.initNav();

        //     var accountId,
        //         recordId = cmp.get("v.recordId"),
        //         myPageRef = cmp.get("v.pageReference");
        //     console.log("myPageRef=", myPageRef);

        //     if (recordId) {
        //         accountId = recordId;
        //     } else if (myPageRef) {
        //         accountId = myPageRef.state.recordId;
        //     }

        //     console.log("accountId=", accountId);
        //     if (accountId) {
        //         helper.getUsageData(null, accountId);
        //     }
        // }
    }

    async findAccount() {
        console.log("findAccount: " + this.sapId);
        this.searching = true;
        this.alertService.setBusy(true, 'Searching...');

        try {
            await this.getUsageData(this.sapId, null);
        } catch (err) {
            //TODO: Reflect to user
            console.error("error getting usage data: ", err);
        } finally {
            this.searching = false;
            this.alertService.setBusy(false);
        }
    }

    closeLoadError() {
        console.warn("TODO: closeLoadError");
    }

    sapIdKey(event: any) {
        console.log("sapIdKey: event=", event);
        if (event.key == "Enter") {
            console.log("Enter key pressed", this.sapId, (typeof this.sapId));
            if ((typeof this.sapId == 'number'
                || typeof this.sapId == 'string')
                && this.sapId) {
                this.getUsageData(this.sapId, null);
            }
        }
    }

    //TODO: UNCOMMENT TO ENABLE TYPEAHEAD SEARCHING...
    //findPartial = (text$: Observable<number>) => {
    // text$.pipe(
    //     debounceTime(300),
    //     distinctUntilChanged(),
    //     tap(() => this.searching = true),
    //     switchMap(term =>
    //         this.findPartialAccount(term).pipe(
    //             tap(() => this.searchFailed = false),
    //             catchError(() => {
    //                 this.searchFailed = true;
    //                 return of([]);
    //             }))
    //     ),
    //     tap(() => this.searching = false)
    //)
    //}

    accountSelected(item: NgbTypeaheadSelectItemEvent<IAccount>) {
        console.log("accountSelected: ", item);
        var acct: IAccount = item ? item.item : null;
        if (acct) {
            this.sapId = Number.parseInt(acct.sap_id);
            this.getUsageData(this.sapId, null);
        }
        item.preventDefault
    }

    findPartialAccount(sapId: number): Observable<IAccount[]> {
        console.log("findPartialAccount: ", sapId);
        var obs = this.appFacade.findPartialAccount("" + sapId);
        return obs;
    }

    // Makes server call to controller for data.
    async getUsageData(sapId: number, accountId: string) {
        console.log('getUsageData: ' + sapId, accountId);
        var me = this;

        try {
            // Clear any toast messages hanging around...
            me.clearToast();
            me.clearAccountError();

            var res: IAccountResponse = await this.appFacade.getUsageData("" + sapId, accountId);

            console.log('res=', res);
            if (res.success == true) {
                await me.parseUsageResults(res);
            } else {
                console.log("Error getting usageData: ", res.error);

                this.accountError = UtilService.getErrorMessage(res.error);//.message;
            }

        } catch (e) {
            console.error("Error: ", e);
        }
    }

    async startSession(res: IAccountResponse, usePartial: boolean, showMessage: boolean) {
        console.log("startSession", usePartial);
        try {
            var session = await this.appFacade.startSession(res, this.locale, res.oldSession, usePartial);
            this.navService.setSession(session);
            this.navService.startNav();

            if (usePartial == true && this.hasSelectedProducts(session)) {
                this.router.navigate(['/evc-summary']);

                this.alertService.showToast(null, 'EVC session retrieved');
            } else {
                this.router.navigate(['/select-products']);
                if (showMessage) {
                    this.alertService.showToast(null, 'EVC session discarded');
                } else {
                    console.log("No alert");
                }
            }
        } catch (err) {
            console.error("Error starting session: ", err);
        }
    }

    clearToast() {
        // var div = cmp.find('alertMessageDiv');
        // if (div) {
        //     div.set("v.body", []);
        // }

    }

    clearAccountError() {
        delete this.accountError;
    }

    async parseUsageResults(accountResp: IAccountResponse) {
        console.log("parseUsageResults", accountResp);//, JSON.stringify(accountResp, UtilService.getCircularReplacer, 2));

        console.log("accountResp.oldSession=" + accountResp.oldSession)

        if (accountResp.oldSession && this.hasSelectedProducts(accountResp.oldSession)) {
            // Save and retrieve handling
            var modalRef = this.modalService.open(PartialSessionComponent, {
                animation: true,
                backdrop: 'static', // Don't let user close by clicking on background
                keyboard: false, // Don't let user close by using the Escape key
                centered: true// ,
                // size: 'sm'
            });
            modalRef.componentInstance.oldSession = accountResp.oldSession;
            try {
                var result = await modalRef.result;//.then(({ value }) => {
                console.log("Use partial session?", result);
                this.startSession(accountResp, result, true);
            } catch (e) {
                console.log("catchError: ", e);
                this.startSession(accountResp, false, true);
            }
        } else {
            this.startSession(accountResp, false, false);
        }

    }

    showAdmin() {
        this.navService.showAdmin();
    }

    /*
     * Check if raw session data has any selected products.
     * Note: Can't just see if session.selectedProducts is populated, as that won't be set until we start a new session with this data...
     */
    hasSelectedProducts(session: ISession): boolean {
        var hasSelected = false;
        if (session && session.products) {
            for (let p of session.products) {
                if (p.selected) {
                    hasSelected = true;
                    break;
                }
            }
        }
        return hasSelected;
    }
}
