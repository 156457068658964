const TAP_MOVE_THRESHOLD = 10; //in pixels
export class NativeUtil {
    static download({base64data, filename, element, contentType}: {base64data: string; filename: string; element: HTMLElement; contentType: string}) {
        return new Promise((resolve, reject) => {
            if (window['plugins'] && window['plugins'].socialsharing) {
                const data = `data:${contentType};df:${filename};base64,${base64data}`,
                    shareRect = element.getBoundingClientRect() as any;
                window['plugins'].socialsharing.shareWithOptions({
                    message: '',
                    subject: 'Enrollment',
                    files: [data],
                    iPadCoordinates: shareRect.x + ',' +  shareRect.y + ',' + shareRect.width + ',' + shareRect.height
                }, () => resolve(true), () => reject('error saving file'))
            } else {
                const data = `data:${contentType};base64,${base64data}`,
                link = document.createElement('a');

                link.href = data;
                link.download = filename;
                link.click();
                resolve(true);
            }
        });
    }

    static lockOrientation ({orientation}: {orientation: 'landscape' | 'portrait'}) {
        if (window.screen.orientation && window.screen.orientation.lock) {
            try {
                window.screen.orientation.lock(orientation);
            } catch (e) {
                console.error(e);
            }
        }
    }

    static scrollToElementOnKeyboardShow () {
        window.addEventListener('keyboardDidShow', () => {
            if (document.body.classList) {
                document.body.classList.add('keyboard-open');
            } else {
                document.body.className = 'keyboard-open';
            }
            if (document.activeElement) {
                if (document.activeElement['scrollIntoViewIfNeeded']) {
                    document.activeElement['scrollIntoViewIfNeeded']();
                } else {
                    document.activeElement.scrollIntoView();
                }
            }
        });
        window.addEventListener('keyboardDidHide', () => {
            if (document.body.classList) {
                document.body.classList.remove('keyboard-open');
            }
        });

        const touch_map : {[key: number]: Touch} & {length: number} = {length: 0};
        var forfeit_touches = false;


        window.addEventListener('touchstart', (e) => {
            const touches = e.changedTouches;
            for (var i=0,ln=touches.length;i<ln;i++) {
                var touch = touches.item(i);
                touch_map[touch.identifier] = touch;
                touch_map.length++;
            }
            if (touch_map.length > 1) {
                forfeit_touches = true;
            }
        });
        window.addEventListener('touchmove', (e) => {
            const touches = e.changedTouches;
            for (var i=0,ln=touches.length;i<ln;i++) {
                var changedTouch = touches.item(i);
                var touch: Touch;
                if (touch = touch_map[changedTouch.identifier]) {
                    var d: number;
                    if ( (d = Math.sqrt( (changedTouch.pageX - touch.pageX) ** 2 + (changedTouch.pageY - touch.pageY) ** 2  )) > TAP_MOVE_THRESHOLD) {
                        forfeit_touches = true;
                    }
                }
            }
        })
        window.addEventListener('touchcancel', (e) => {
            const touches = e.changedTouches;
            for (var i=0,ln=touches.length;i<ln;i++) {
                var touch = touches.item(i);
                if (touch_map[touch.identifier]) {
                    delete touch_map[touch.identifier];
                    forfeit_touches = true;
                    touch_map.length--;
                }
            }
            if (touch_map.length <= 0) {
                forfeit_touches = false;
            }
        });
        window.addEventListener('touchend', (e) => {
            const touches = e.changedTouches;
            for (var i=0,ln=touches.length;i<ln;i++) {
                var touch = touches.item(i);
                if (touch_map[touch.identifier]) {
                    delete touch_map[touch.identifier];
                    touch_map.length--;
                }
            }
            if (touch_map.length <= 0) {
                if (!forfeit_touches) {
                    if (document.activeElement instanceof HTMLInputElement || document.activeElement.getAttribute('contenteditable')) {
                        (document.activeElement as HTMLElement).blur();
                    }
                }
                forfeit_touches = false;
            }
        });
    }
}
