<form id="productDetailForm" #productDetailForm="ngForm" name="productDetailForm" style="padding: 1em;">
  <p>Product: {{productDetail.product?.name}}</p>
  <div class="form-group">
	<label for="country_cd" class="spot-form__label">Country</label>
	<div class="spot-form__select">
	  <select name="country_cd" class="spot-form__select-input" [(ngModel)]='productDetail.country_cd' required [compareWith]="compareCountry">
		<option class="spot-form__select-option" name='' value=''></option>
		<option *ngFor="let country_cd of countries" class="spot-form__select-option" [ngValue]="country_cd">{{country_cd}}</option>
	  </select>
      <div class="spot-form__select-inner"></div>
      <span class="spot-form__select-open-indicator">
        <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
          <title>caret-down</title>
          <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#caret-down"></use>
        </svg>
      </span>
	</div>
  </div>
  <div class="form-group">
	<label for="video_url" class="spot-form__label">Video URL</label>
	<input type="url" class="form-control" name="video_url" [(ngModel)]="productDetail.video_url"/>
  </div>
  <div class="form-group">
	<label for="dashboard_url" class="spot-form__label">Product Dashboard</label>
	<input type="url" class="form-control" name="dashboard_url" [(ngModel)]="productDetail.product_dashboard_url"/>
  </div>


  <div class="form-group">
	<label for="patient_dashboard_url" class="spot-form__label">Patient Results Dashboard</label>
	<input type="url" class="form-control" name="patient_dashboard_url" [(ngModel)]="productDetail.patient_results_dashboard_url"/>
  </div>
  <div class="form-group">
	<label for="inc_percent" class="spot-form__label">Incremental Percent</label>
	<input type="number" class="form-control" name="inc_percent" [(ngModel)]="productDetail.inc_percent"/>
  </div>
  <table style="width:100%;	background-color: #f4f6f7;">
	<tr>
	  <td>Created On: {{productDetail.created_on}}</td>
	  <td>Created By: {{productDetail.created_by?.name}}</td>
	  <td>Updated On: {{productDetail.updated_on}}</td>
	  <td>Updated By: {{productDetail.updated_by?.name}}</td>
	</tr>
  </table>

</form>
<footer style="display:flex;justify-content:center;border-top:2px solid #2d2e2f;;padding: 1em;margin: 1em 0;background-color: #f4f6f7;">
  <button class="spot-button spot-button--secondary" style="margin:0 10px;" (click)="cancel()" >Cancel</button>
  <button class="spot-button spot-button--primary" style="margin:0 10px;" (click)="save()" [disabled]="!saveEnabled" >Save</button>
</footer>
