import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
//import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';

//import { LoginComponent } from './login.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LoginComponent } from './login/login.component';
import { RoleGuardService } from './role.guard';

@NgModule({
    declarations: [
        //        LoginComponent,
        AuthCallbackComponent,
        LoginComponent
    ],
    providers: [
        //        AuthService,
        AuthGuard,
        RoleGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        FormsModule
    ],
    exports: [
        //        LoginComponent
    ]
})
export class AuthModule { }
