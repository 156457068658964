import { Component, OnInit, Input } from '@angular/core';
import { IProductInfo } from '@common/model/session-product';
import { Router } from '@angular/router';

@Component({
    selector: 'evc-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    @Input() selectedProduct: IProductInfo;
    @Input() subPage: string;

    prodName: string;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log("subPage=", this.subPage);
        this.prodName = this.selectedProduct.name;
    }

    editProductSummary() {
        this.router.navigate(['/product', this.prodName, 'doneProduct']);
    }

    editProductRuns(): any {
        this.router.navigate(['/product', this.prodName, 'editRuns']);
    }

    editProductProfit() {
        this.router.navigate(['/product', this.prodName, 'adjustPrice']);
    }

    editProductCompliance() {
        this.router.navigate(['/product', this.prodName, 'adjustCompliance']);
    }

    editProductCalcs() {
        this.router.navigate(['/product', this.prodName, 'adjustCalc']);
    }

}
