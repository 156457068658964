import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { AppFacade } from '../../../facade/app.facade';
import { ISession } from '@common/model/session';
import { IProductInfo } from '@common/model/session-product';

@Component({
    selector: 'evc-select-products',
    templateUrl: './select-products.component.html',
    styleUrls: ['./select-products.component.scss']
})
export class SelectProductsComponent implements OnInit {

    products: IProductInfo[] = [];
    session: ISession;
    sessionSub: Subscription;

    constructor(
        private appFacade: AppFacade
    ) { }

    ngOnInit(): void {
        console.log("select-products.onInit");
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: ISession) => { this.setSession(session); }
        );
    }

    setSession(session: ISession) {
        console.log("setSession: ", session);
        this.session = session;
        if (this.session) { // For testing
            this.products = this.session.products;//usageData.productUsage;
        } else {
            this.products = [];
        }

    }

    onCheckProduct(prod: IProductInfo, event: any) {
        console.log("onCheckProduct", prod, event);

        var prodName = prod.name,
            isSelected = event.currentTarget.checked;

        this.appFacade.productSelectChanged(prodName, isSelected);

    }

}
