import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { AccountGuard } from '../guard/account.guard';

import { DoneComponent } from './views/done/done.component';
import { EvcSummaryComponent } from './views/evc-summary/evc-summary.component';
import { HomeComponent } from './views/home/home.component';
import { SAPSearchComponent } from './views/sap-search/sap-search.component';
import { ProductComponent } from './views/product/product.component';
import { SelectProductsComponent } from './views/select-products/select-products.component';

const routes: Routes = [
    {
        path: 'done',
        component: DoneComponent,
        canActivate: [
            AuthGuard,
            AccountGuard
        ]
    },
    {
        path: 'evc-summary',
        component: EvcSummaryComponent,
        canActivate: [
            AuthGuard,
            AccountGuard
        ]
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [
            AuthGuard,
            AccountGuard
        ]
    },
    {
        path: 'product/:prodName/:subPage',
        component: ProductComponent,
        canActivate: [
            AuthGuard,
            AccountGuard
        ]
    },
    {
        path: 'search',
        component: SAPSearchComponent,
        canActivate: [
            AuthGuard,
            AccountGuard
        ]
    },
    {
        path: 'select-products',
        component: SelectProductsComponent,
        canActivate: [
            AuthGuard,
            AccountGuard
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientRoutingModule { }
