<div style="display:flex;flex-direction:column;flex:1;margin-top:10px;">
  <span class="heading" style="font-size:26px;text-align:center;">Select the tests you would like to include in this calculation</span>
  <div class="selectProducts">
	<div class="selectProductsColumn"> <!-- Sell -->
	  <!-- <h3 style="text-align:center;">Profit calculation</h3> -->
	  <ng-container *ngFor="let usage of products">
		<evc-product-item [usage]="usage"></evc-product-item>
	  </ng-container>
	</div>

  </div>
</div>
