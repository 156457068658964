<div class="product-item">
  <input type="checkbox" [id]="usage? usage.name : 'todo: name'" [checked]="usage? usage.selected : false" [(ngModel)]="usage.selected" (change)="onCheckProduct(usage,$event)" name="selectedProducts2" />
  <label for="{{ usage?.name }}">
	<div class="productDisplay">
	  <table>
		<tr>
		  <td rowSpan="2" style="padding-right: 15px;width:75px;">
		    <svg class="spot-icon" aria-labelledby="title" viewBox="0 0 90 90" class="mediumIcon">
			  <title>Home</title>
			  <use [attr.xlink:href]="iconPath"></use>
			</svg>
		  </td>
		  <td>
			<span class="prodDisplayName">
			  {{ usage?.displayName }}
			</span>
			<div class="prodDescription">
			  {{ usage?.description }}
			</div>
		  </td>
		</tr>
		<tr>
		  <td>
			<span class="prodRuns">
			  Average tests per month: <strong>{{ usage?.runsPerMonth  | number:'1.0':'en-US'}}</strong>
			  <ng-container *ngIf="usage?.origAverageRun != usage?.runsPerMonth">
				(Observed: {{ usage?.origAverageRun }})
			  </ng-container>
			</span>
		  </td>
		</tr>
	  </table>
	</div>
  </label>

</div>
