import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import * as rxjs from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ConfirmationComponent } from './../client/components/confirmation/confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ISession, SessionUtils, ISessionEvent } from '@common/model/session';
import { SessionService } from './session.service';
import { AppFacade } from '../facade/app.facade';
import { IProductInfo } from '@common/model/session-product';
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class NavService {

    session: ISession;
    sessionSub: Subscription;

    routePath: any[];

    pages: INavItem[] = [
        {
            uid: 'sapSearch',
            pageName: 'sapSearch',
            displayName: 'Search',
            icon: 'fa-paw',
            link: '/search',
            enabled: true
        },
        {
            uid: 'selectProducts',
            pageName: 'selectProducts',
            displayName: 'Select',
            icon: 'fa-check-double',
            link: '/select-products',
            enabled: true
        },
        {
            uid: 'product',
            pageName: 'product',
            displayName: 'Configure',
            icon: 'fa-flask',
            enabled: true,
            link: '/product',
            children: [
                {
                    uid: 'editRuns',
                    pageName: 'editRuns',
                    displayName: 'Utilization',
                    icon: 'fa-flask',
                    link: '/product',
                    enabled: true
                },
                {
                    pageName: 'adjustPrice',
                    displayName: 'Profitability',
                    icon: 'fa-hand-holding-usd',
                    link: '/product',
                    enabled: true
                },
                {
                    pageName: 'adjustCompliance',
                    displayName: 'Compliance',
                    icon: 'fa-handshake',
                    link: '/product',
                    enabled: true
                },
                {
                    pageName: 'doneProduct',
                    displayName: 'Results',
                    icon: 'fa-receipt',
                    link: '/product',
                    enabled: true
                }
            ]
        },
        {
            uid: 'evc-summary',
            pageName: 'evc-summary',
            displayName: 'Process',
            enabled: true,
            link: '/evc-summary'
        },
        {
            uid: 'done',
            pageName: 'done',
            displayName: 'Finish',
            enabled: true,
            link: '/done'
        }
    ];

    activeNav: INavItem;//TODO: refactor
    //states: INavItem[]; //TODO: refactor
    stateProvider: rxjs.Subject<IEVCNavEvent>;

    prevState: INavItem;
    nextState: INavItem;

    navInfo: INavItem[] = [];

    constructor(
        private router: Router,
        private appFacade: AppFacade,
        private modalService: NgbModal,
        private sessionService: SessionService,
        private domSanitizer: DomSanitizer
    ) {
        console.log("nav.service constructor");

        this.navInfo = this.setupNav();
        // console.log("navInfo after setupNav", this.navInfo);

        // Listen for page changes.  Update the active state accordingly.
        // Also listen for enabling/disabling a page link
        router.events.pipe(
            filter(e => {
                console.log("router events filtering: " + e);
                return e instanceof NavigationEnd
            })
        ).subscribe((val: NavigationEnd) => {
            //console.log("router event: ", val);
            console.log("New route: " + val);

            this.enableState(val.url);
        });

        //TODO: Handle platform variable passed from ios app/browser?
        // var platform = cmp.get('v.platform'),
        //     isBrowser = (platform == 'browser');
        // console.log('platform = ' + platform);

        // TODO: Handle lost session data due to web worker issues.
        //        if (!isBrowser) {
        //            helper.initFromLocalStorage(cmp);
        //        }

        //TODO
        // if (!isBrowser && helper.getConfigData(cmp)) {
        //     console.log("Reloaded saved state");
        // } else {
        console.log("Starting from scratch...");

        // var navInfo = this.initNav2();

        // var defaultState: IEVCNavEvent = {
        //     name: 'nav',//'session',
        //     states: this.navInfo,//null,//this.states,
        //     currentState: null//this.currentState
        // };

        this.stateProvider = new rxjs.BehaviorSubject(null);//defaultState);

        // this.appFacade.getCurrentSession().subscribe(
        //     (session) => {
        //         console.log("nav.service: session changed", session);
        //         this.setSession(session);
        //     }
        // );

        //TODO: Anything to re-implement here?
        // var accountId,
        //     recordId = cmp.get("v.recordId"),
        //     myPageRef = cmp.get("v.pageReference");
        // console.log("myPageRef=", myPageRef);

        // if (recordId) {
        //     accountId = recordId;
        // } else if (myPageRef) {
        //     accountId = myPageRef.state.recordId;
        // }

        // console.log("accountId=", accountId);
        // if (accountId) {
        //     helper.getUsageData(null, accountId, cmp);
        // }
        // //        }

    }

    getCurrentPage(): INavItem {
        console.log("getCurrentPage: ", this.activeNav);
        return this.activeNav;
    }

    /**
     * Maintain progress bar model.
     * Should probably move this and some of the component logic into a separate
     * thing, since this is UI logic, not just data.
     */
    enableState(url: string) {
        console.log("enableState: " + url);
        var refState = this.navInfo.find(function(s: INavItem) {
            console.log("s=", s);
            return s.link == url;
        });
        console.log("refState=", refState);

        if (refState) {
            //this.setActiveNav(refState);
            this.setCurrentPage2(refState);
            // this.currentState = refState;
            // refState.enabled = true;

            var navEvent: IEVCNavEvent = {
                name: 'nav',
                states: this.navInfo,
                currentState: refState
            };

            this.stateProvider.next(navEvent);

            //TODO: REFACTOR THIS LOGIC.  Doesn't belong in a nav service...
            if (url == '/done') {
                console.log("Submitting session...");
                this.appFacade.submitEVC(this.session);
            } else {
                console.log("Saving session...");
                this.appFacade.saveSession();
            }

        } else {
            console.warn("Ignoring url: ", url);
        }

        console.log("this.nextState=", this.nextState);
        console.log("this.prevState=", this.prevState);

    }

    setSession(session: ISession) {
        console.log("setSession: ", session);

        this.session = session;
        // this.states = [];
        // this.states.push(STATES.PROFILES);
        // this.states.push(STATES.ENROLL);
        // this.states.push(STATES.REVIEW);
        // this.currentState = this.states && this.states.length > 0 ? this.states[0] : null;

        // for (let s of this.states) {
        //     s.enabled = false;
        // }
        // if (this.currentState) {
        //     this.currentState.enabled = true;
        // }

        // this.routePath = [];
        // this.routePath.push(STATES.PROFILES.link);
        // this.routePath.push(STATES.ENROLL.link);
        // this.routePath.push(STATES.REVIEW.link);
        // this.routePath.push(STATES.DONE.link);

        // var navEvent: IEVCNavEvent = {
        //     name: 'session',
        //     states: this.states,
        //     currentState: this.currentState
        // };

        //this.stateProvider.next(navEvent);

        if (this.sessionSub) {
            this.sessionSub.unsubscribe();
        }

        this.sessionSub = this.sessionService.getSessionSubject().subscribe(
            (event: ISessionEvent) => { this.sessionEvent(event); }
        );

        //console.log("this.states=", this.states);
        console.log("this.activeNav=", this.activeNav);
        //console.log("this.routePath=", this.routePath);
    }

    sessionEvent(event: ISessionEvent) {
        console.log("sessionEvent: ", event);
        if (!event) {
            return;
        }
        this.resetNav();
        this.setPrevNav(null);
        if (event.name == "productsSelected") {
        } else if (event.name == "productsDeselected") {
        }
    }

    getNavState(): rxjs.Observable<IEVCNavEvent> {
        return this.stateProvider.asObservable();
    }

    getNextUrl(currentUrl: string) {
        console.log("getNextUrl: ", currentUrl);
        if (!currentUrl) {
            return null;
        }
        let ix = this.routePath.findIndex(function(urlInfo: any) {
            return currentUrl == urlInfo.url;
        });
        console.log("ix=", ix);
        if (ix != -1) {
            if (this.routePath.length > ix) {
                var nextRoute = this.routePath[ix + 1];
                console.log("nextRoute = ", nextRoute);
                if (nextRoute) {
                    console.log("returning " + nextRoute.url);
                    return nextRoute.url;
                }
            }
        }
        return null;
    }

    showAdmin() {
        console.log("showAdmin");
        this.sessionService.clearSession();
        this.router.navigate(['/admin']);
    }

    showClient() {
        console.log("showClient");
        this.router.navigate(['/search']);
    }

    isEnabled(st: INavItem): boolean {
        if (typeof st.enabled === 'function') {
            return st.enabled() === true;
        } else {
            return st.enabled === true;
        }
    }

    /**
     * return the doneProduct page for specified product
     */
    findProductNav(prodName: string, subPageName: string): INavItem {
        console.log("findProductNav: ", prodName, subPageName);
        var navInfo = this.getNavInfo(),
            pageName = 'product';

        subPageName = subPageName || 'doneProduct';

        var foundNav = navInfo.find(function(n) {
            return n.pageName == pageName
                && n.subPage == subPageName
                && n.prodName == prodName;
        });

        return foundNav;
    }

    goHome() {

        const ref = this.modalService.open(ConfirmationComponent, {
            animation: true,
            //            centered: true,
            size: 'lg',
            windowClass: 'confirmation-leaving'
        });
        ref.componentInstance.title = 'Leaving session';
        ref.componentInstance.body = '';
        ref.componentInstance.body2 = this.domSanitizer.bypassSecurityTrustHtml(
            "This action will return you to the SAP Search screen.<br/><br/>Do you wish to continue?");
        ref.result.then(({ value }) => {
            if (value !== 'yes') {
                return;
            }

            //TODO: Clear current state!

            this.router.navigate(['/search']);
        });

    }

    async nextPage() {
        var currentPage = this.getPage(),
            subPage = this.getSubPage();

        console.log('nextPage: currentPage=' + currentPage, subPage);

        var prevNav = this.getActiveNav(),
            nextNav: INavItem,
            prodDef: IProductInfo;
        console.log('prevNav=', JSON.stringify(prevNav, null, 2));

        // For some one-off pages, they don't exist in the normal flow, so need special handling instead.
        if (prevNav == null) {
            nextNav = this.getNextNav();
        } else {
            nextNav = this.figureNextNav();
        }
        console.log("nextNav=", nextNav);

        // prevNav is the information of the page we're coming from.  It can be used
        // to peform operations in a "beforeNavigate" sort of way as well as to
        // manage what pages have been completed as the user navigates through the
        // app.
        if (prevNav) {
            if (prevNav.prodName) {
                prodDef = SessionUtils.getProduct(this.session, prevNav.prodName);
                if (prodDef) {
                    prodDef.completed = prodDef.completed || {};
                    console.log("Setting completed: ", prevNav.subPage);
                    prodDef.completed[prevNav.subPage] = true;
                    console.log('prodDef.completed=', prodDef.completed);
                }
            } else if (prevNav.pageName == 'selectProducts') {
                //SessionUtils.auditSelectedProducts(this.session);
            }
        }

        console.log("nextNav=", nextNav);
        if (nextNav) {
            if (nextNav.prodName && nextNav.subPage == 'doneProduct') {
                prodDef = SessionUtils.getProduct(this.session, nextNav.prodName);
                if (prodDef) {
                    console.log("Setting prodDef to done: ", prodDef);
                    prodDef.done = true;
                    console.log("Marking product as done: ", JSON.stringify(prodDef, null, 2));
                } else {
                    console.error("Can't find product: ", nextNav.prodName);
                }
            }

            if (nextNav.subPage == 'doneProduct') {
                SessionUtils.calcTotals(this.session);
            } else if (nextNav.pageName == 'done') {
                SessionUtils.calcTotals(this.session);

                // Call audit function.  (Above statement may go away)
                //await this.appFacade.endSession();
            }
        }

        if (prevNav) {
            console.log("setting prevNav done: ", prevNav.prodName);
            prevNav.done = true;
        }

        this.setCurrentPage(nextNav);

        // This is only to update the localStorage version
        //TODO: this.setConfigData(this.getConfigData());

        //TODO: Compare this to what's getting called in setCurrentPage...
        this.setPrevNav(prevNav);

    }

    async prevPage() {
        var currentPage = this.getPage(),
            subPage = this.getSubPage();

        console.log('prevPage: currentPage=' + currentPage, subPage);

        var prevNav = this.getPrevNav();
        console.log('prevNav=', prevNav);

        // Navigate to prevPage...
        if (prevNav) {
            this.setCurrentPage(prevNav);
        } else {
            console.warn("prevNav is not defined");
        }

    }

    async goNext(nextPage: INavItem) {
        console.log("goNext: ", nextPage);
        if (!nextPage) {
            return;
        }

        if (nextPage.onNext) {
            await nextPage.onNext();
        }

        if (nextPage.action) {
            //            var success = this.appFacade.performAction(nextPage.action);
            //            if (!success) {
            //                console.error("action failed");
            //                return;
            //            }
        }

        console.log("nextButtonClicked");
        console.log("this.router.url=", this.router.url);
        var nextUrl = nextPage.link;
        console.log("nextUrl=", nextUrl);
        if (nextUrl) {
            this.router.navigate([nextUrl]);
        }
    }

    goPrev(prevPage: INavItem) {
        console.log("goPrev: ", prevPage);
        if (!prevPage) {
            return;
        }

        console.log("prevButtonClicked");
        console.log("this.router.url=", this.router.url);
        var prevUrl = prevPage.link;
        console.log("prevUrl=", prevUrl);
        if (prevUrl) {
            this.router.navigate([prevUrl]);
        }
    }

    // setNextState(nextState: INavItem) {
    //     console.log("setNextState: ", nextState);
    //     this.nextState = nextState;
    //     var navEvent: IEVCNavEvent = {
    //         name: 'nextState',
    //         nextState: this.nextState
    //     };

    //     this.stateProvider.next(navEvent);
    // }

    initNav(): INavItem[] {
        console.log('initNav');

        var navInfo = this.setupNav();
        console.log("initNav navInfo=", navInfo);

        this.setNavInfo(navInfo);

        this.setNextNav(navInfo[1]);
        this.setPrevNav(null);

        return navInfo;
    }

    initNav2(): INavItem[] {
        console.log('initNav');

        var navInfo = this.setupNav();
        console.log("initNav navInfo=", navInfo);

        this.setNavInfo(navInfo);

        this.setNextNav(navInfo[1]);
        this.setPrevNav(null);

        return navInfo;
    }

    resetNav() {
        console.log('resetNav');

        var navInfo = this.setupNav();
        console.log("navInfo here=", navInfo);

        this.setNavInfo(navInfo);

        var nextNav = this.figureNextNav(),
            prevNav = this.figurePrevNav();

        console.log("figuredNext=", nextNav);

        this.setNextNav(nextNav);
        this.setPrevNav(prevNav);

        console.log('Done resetNav');

        return navInfo;
    }

    // Set up navigation list using page definitions and selected products (if any).
    // Doesn't persist anything, that's up to the caller.
    // Does keep the currently selected active item marked as active (unless a
    // product is deselected when already on that page, but that shouldn't happen)
    setupNav() {
        console.log('setupNav');
        console.log("this.session=", this.session);

        var oldNavInfo = this.getNavInfo(),
            navInfo: INavItem[] = [],
            selectedProducts = SessionUtils.getSelectedProducts(this.session),
            activeNav = this.getActiveNav(),
            ix = 0;

        console.log("activeNav = ", activeNav);
        console.log("this.pages=", this.pages);

        console.log("selectedProducts here=", selectedProducts);

        for (var i = 0; i < this.pages.length; i++) {
            var pageDef = this.pages[i];
            //console.log("pageDef=", pageDef);
            var pageName = pageDef.pageName;
            console.log("pageName=" + pageName);

            // For product pages, first run the children, then show the evc-summary page
            if (pageName == 'product') {
                if (!selectedProducts) {
                    console.info('No products selected yet.  Will add later...');
                    break;
                }

                // Loop through selected products and add them to nav.
                for (var pix = 0; pix < selectedProducts.length; pix++) {
                    var prodDef = selectedProducts[pix];
                    console.log('prodDef=', prodDef);

                    // Loop through all subPages for product.
                    //var subPageIx = 0;
                    for (var spix = 0; spix < pageDef.children.length; spix++) {
                        var subPageDef = pageDef.children[spix];
                        console.log("subPageDef=", subPageDef);

                        // var subPages = JSON.parse(JSON.stringify(pageDef.children));

                        var uid = pageName + '.' + subPageDef.pageName + '.' + prodDef.name,
                            subPageInfo: INavItem = {
                                uid: uid,
                                //value: uid,
                                pageName: pageName,
                                subPage: subPageDef.pageName,
                                link: subPageDef.link + '/' + prodDef.name + '/' + subPageDef.pageName, // TODO: Add prod name in url for lookup?
                                displayName: subPageDef.displayName,
                                //pageText: subPageDef.text,
                                prodName: prodDef.name,
                                enabled: false,
                                //isShown: subPageDef.isShown === false ? false : true,
                                icon: subPageDef.icon,
                                ix: ix++
                            };

                        if (activeNav && activeNav.uid == subPageInfo.uid) {
                            subPageInfo.enabled = true;
                        }

                        //console.log("Pushing subPageInfo to navInfo: ", subPageInfo);

                        navInfo.push(subPageInfo);
                        //console.log("navInfo now: ", JSON.stringify(navInfo, null, 2));
                    }

                    // Insert evc-summary page between each product and at the end of all products.
                    var landing: INavItem = {
                        uid: 'evc-summary',
                        //value: pageName,
                        pageName: 'evc-summary',
                        displayName: 'Process',
                        link: '/evc-summary', //TODO: refactor
                        icon: 'summary',
                        enabled: false,
                        complete: false,
                        ix: ix++
                    };

                    navInfo.push(landing);
                }
            } else {

                if (pageName == 'evc-summary') {
                    // Skip.  Handled above per product.
                    continue;
                }

                var oldInfo = oldNavInfo == null ? null : oldNavInfo.find(function(n) {
                    return n.uid == pageName;
                });

                var info: INavItem = {
                    uid: pageName,
                    //value: pageName,
                    pageName: pageName,
                    displayName: pageDef.displayName,
                    link: pageDef.link,
                    //pageText: pageDef.text,
                    icon: pageDef.icon,
                    enabled: false,
                    complete: false,
                    ix: ix++
                };

                if (activeNav && activeNav.uid == info.uid) {
                    info.enabled = true;
                }
                if (oldInfo) {
                    // console.log("oldInfo=", JSON.stringify(oldInfo));
                    // console.log("info=", JSON.stringify(info));
                    info.done = oldInfo.done;
                }

                //console.log("Pushing info to navInfo: ", info);
                navInfo.push(info);
                // console.log("navInfo now2: ", JSON.stringify(navInfo, null, 2));

                if (pageName == 'sapSearch') {
                    info.done = true;
                }
            }
        }

        console.log("at end here: ", navInfo);

        return navInfo;

    }

    getActiveNav(): INavItem {
        var activeNav: INavItem,
            navInfo = this.getNavInfo();

        if (!navInfo) {
            console.warn("navInfo is null!");
            activeNav = null;
        } else {
            for (var nix = 0; nix < navInfo.length; nix++) {
                var nav = navInfo[nix];
                if (nav.enabled === true) {
                    activeNav = nav;
                    break;
                }
            }
        }

        console.log("getActiveNav: ", activeNav);

        return activeNav;
    }

    setActiveNav(activeNav: INavItem) {
        console.log("setActiveNav: ", activeNav);
        // console.trace("here");
        var navInfo = this.getNavInfo();

        if (!navInfo) {
            console.error('navInfo is null!');
            activeNav = null;
        } else {
            navInfo.forEach(function(nav) {
                if (nav.uid == activeNav.uid) {
                    nav.enabled = true;
                } else {
                    nav.enabled = false;
                }
            });

        }

    }

    figureNextNav() {
        console.log("figureNextNav");
        var activeNav = this.getActiveNav(),
            nextNav: INavItem,
            selectedProducts = SessionUtils.getSelectedProducts(this.session),
            pageName = activeNav ? activeNav.pageName : null,
            subPageName = activeNav ? activeNav.subPage : null,
            prodName = activeNav ? activeNav.prodName : null,
            done = activeNav ? activeNav.done : null,
            ix = activeNav ? activeNav.ix : null,
            nextSub: INavItem,
            nextProd: IProductInfo,
            nextPageName: string,
            navList = this.getNavInfo();
        console.log("pageName=" + pageName);
        console.log("subPageName=" + subPageName);
        console.log("done=" + done);
        console.log("prodName=" + prodName);
        console.log("ix=" + ix);

        if (pageName == 'selectProducts') {

            if (done == true && selectedProducts.length > 1) {
                nextPageName = 'evc-summary';
                nextNav = navList.find(function(p) { return p.pageName == nextPageName; });
            } else {
                nextProd = SessionUtils.getNextProduct(this.session);
                if (nextProd) {
                    nextNav = navList.find(function(p) { return p.prodName == nextProd.name; });
                } else {
                    nextPageName = 'evc-summary';
                    nextNav = navList.find(function(p) { return p.pageName == nextPageName; });
                }
            }
        } else if (pageName == 'product') {

            if (subPageName == 'adjustCalc') {
                nextNav = this.findProductNav(prodName, 'doneProduct');
            } else {
                nextSub = this.getNextItem(navList, ix);
                console.log("nextSub=", nextSub);
                if (nextSub) {
                    console.log("here: ", nextSub);
                    var uid = 'product.' + nextSub.pageName + '.' + prodName;
                    nextNav = navList.find(function(p) {
                        return p.uid == uid;
                    });
                    if (!nextNav) {
                        console.warn("nextNav not found: navList=", navList);
                    }
                    console.log("nextNav=", nextNav);
                } else {
                    nextPageName = 'evc-summary';
                    nextNav = navList.find(function(p) { return p.pageName == nextPageName; });
                    console.log("nextNav here=", nextNav);
                }
            }
        } else if (pageName == 'evc-summary') {
            console.log("here getting nextProd");
            nextProd = SessionUtils.getNextProduct(this.session);
            console.log("here: ", nextProd);
            if (nextProd) {
                console.log("navList=", navList);
                nextNav = navList.find(function(p) { return p.prodName == nextProd.name; });
                console.log("nextNav=", nextNav);
            } else {
                nextPageName = 'done';
                nextNav = navList.find(function(p) { return p.pageName == nextPageName; });
            }
        }

        if (!nextNav) {
            console.log("after");
            //var ix = navList.findIndex(function(p) { return p.pageName == pageName; });
            nextNav = this.getNextItem(navList, ix);
        }

        return nextNav;
    }

    figurePrevNav() {
        console.log("figurePrevNav");
        var activeNav = this.getActiveNav(),
            prevNav: INavItem;

        var navInfo = this.getNavInfo(),
            navIndex = this.getNavIndex(navInfo, activeNav);

        console.log("navInfo prev=", JSON.stringify(navInfo));

        prevNav = this.getPrevItem(navInfo, navIndex);
        console.log("prevNav = ", prevNav);

        return prevNav;
    }

    getNavIndex(navInfo: INavItem[], navItem: INavItem) {
        var navIx = -1;

        if (navInfo && navItem) {
            for (var ix = 0; ix < navInfo.length; ix++) {
                var nav = navInfo[ix];
                if (nav.uid == navItem.uid) {
                    navIx = ix;
                    break;
                }
            }
        }

        console.log("getNavIndex: ", navIx);

        return navIx;
    }

    getNavInfo(): INavItem[] {
        return this.navInfo;
    }

    setNavInfo(navInfo: INavItem[]) {
        console.log('setNavInfo: ', JSON.stringify(navInfo, null, 2));
        this.navInfo = navInfo;
    }

    setNextNav(navInfo: INavItem) {
        console.log('setNextNav: ', navInfo);
        this.nextState = navInfo;
        var navEvent: IEVCNavEvent = {
            name: 'nextState',
            nextState: this.nextState
        }
        this.stateProvider.next(navEvent);
    }

    getNextNav() {
        return this.nextState;
    }

    getPrevNav() {
        var prevNav = this.prevState;
        console.log('getPrevNav3 returns: ', prevNav);
        return prevNav;
    }

    setPrevNav(navInfo: INavItem) {
        console.log('setPrevNav: ', navInfo);
        this.prevState = navInfo;
        var navEvent: IEVCNavEvent = {
            name: 'prevState',
            prevState: this.prevState
        }
        this.stateProvider.next(navEvent);
    }

    getNextItem(list: INavItem[], index: number): INavItem {
        if (!list || list.length - 1 <= index) {
            return null;
        }
        return list[index + 1];
    }

    getPrevItem(list: INavItem[], index: number): INavItem {
        if (!list || index < 1) {
            return null;
        }
        return list[index - 1];
    }

    startNav() {
        console.log("startNav");
        try {
            //var navInfo = this.getNavInfo();
            //            this.setCurrentPage(navInfo[1]);

            // Disable continue button until user selects at least one product...
            this.validateSelectedProducts();

        } catch (e) {
            console.error("Error: ", e);
        }
    }

    setCurrentPage(pageNav: INavItem) {
        console.log("setCurrentPage: ", pageNav);
        // console.trace("here");

        if (!pageNav) {
            console.error('No pageNav defined!');
            return;
        }

        this.setPageInfo(pageNav);

        this.setPage(pageNav.pageName);
        this.setSubPage(pageNav.subPage);
        console.log("router.navigate: ", pageNav.link);
        if (pageNav.link) {
            this.router.navigate([pageNav.link]);
        }

        if (pageNav.pageName == 'evc-summary') {
            SessionUtils.calcTotals(this.session);
        }

        // this.setPageText(pageNav.pageText);

        this.setActiveNav(pageNav);
        //this.setConfigData(this.getConfigData));

        var figuredNext = this.figureNextNav(),
            figuredPrev = this.figurePrevNav();

        console.log("figuredNext=", figuredNext);

        console.log("figuredNext=", figuredNext);
        console.log("figuredPrev=", figuredPrev);

        if (pageNav.pageName == 'selectProducts') {
            this.setPrevNav(null);
        } else {
            this.setPrevNav(figuredPrev);
        }
        this.setNextNav(figuredNext);

        this.updateProfitDisplay();

    }

    setCurrentPage2(pageNav: INavItem) {
        console.log("setCurrentPage2: ", pageNav);
        // console.trace("here");

        if (!pageNav) {
            console.error('No pageNav defined!');
            return;
        }

        this.setPageInfo(pageNav);

        this.setPage(pageNav.pageName);
        this.setSubPage(pageNav.subPage);
        console.log("router.navigate: ", pageNav.link);
        // if (pageNav.link) {
        //     this.router.navigate([pageNav.link]);
        // }

        if (pageNav.pageName == 'evc-summary') {
            SessionUtils.calcTotals(this.session);
        }

        // this.setPageText(pageNav.pageText);

        this.setActiveNav(pageNav);
        //this.setConfigData(this.getConfigData));

        var figuredNext = this.figureNextNav(),
            figuredPrev = this.figurePrevNav();

        console.log("figuredNext=", figuredNext);

        console.log("figuredNext=", figuredNext);
        console.log("figuredPrev=", figuredPrev);

        if (pageNav.pageName == 'selectProducts') {
            this.setPrevNav(null);
        } else {
            this.setPrevNav(figuredPrev);
        }
        this.setNextNav(figuredNext);

        this.updateProfitDisplay();

    }

    page: string;
    subPage: string;
    pageInfo: any;
    setPageInfo(pageInfo: any) {
        this.pageInfo = pageInfo;
    }

    getPage(): string {
        return this.page;
    }

    setPage(page: string) {
        console.log("setPage: " + page);
        this.page = page;
    }

    getSubPage() {
        return this.subPage;
    }

    setSubPage(subPage: string) {
        console.log("setSubPage: " + subPage);
        this.subPage = subPage;
    }

    updateProfitDisplay() {
        console.log("updateProfitDisplay");
        if (this.session) {
            var showProfit = this.shouldShowProfit();
            this.session.showProfit = showProfit;
        }
    }

    shouldShowProfit(): boolean {
        console.log("shouldShowProfit");
        var retVal: boolean,
            selectedProduct = this.session ? this.session.selectedProduct : null,
            page = this.getPage();
        console.log("selectedProduct=", selectedProduct);
        console.log("page=", page);
        if (page === 'product'
            && selectedProduct
            && this.session.totalSavings > 0) {
            retVal = true;
        } else {
            retVal = false;
        }
        console.log("shouldShowProfit=" + retVal);
        return retVal;
    }

    validateSelectedProducts() {
        console.log("validateSelectedProducts");
        // Make sure all selected products are done.  Specifically, if user
        // selects 4dx, make sure they select pathType of defense vs sell
        if (!this.session) {
            return;
        }

        var selectedProducts = SessionUtils.getSelectedProducts(this.session),
            isValid = true;
        if (!selectedProducts || selectedProducts.length < 1) {
            console.log("No selected products here");
            isValid = false;
        }
        this.session.valid = isValid;
        //TODO: REFACTOR cmp.set("v.isValid", isValid);
        //console.log("isValid=" + isValid);
    }

}

//TODO: refactor
// const STATES: Record<string, INavItem> = {
//     PROFILES: { uid: 'navigation.Profile_creation', name: 'navigation.Profile_creation', link: '/profiles', enabled: false },
//     ROI: { name: 'navigation.ROI_calculator', link: '/roi', enabled: false },
//     ENROLL: { name: 'navigation.Enrollment', link: '/enroll', enabled: false },
//     REVIEW: { name: 'navigation.Profile_summary', link: '/review', enabled: false },
//     DONE: { name: 'navigation.Done', link: '/done', enabled: false }
// };

export interface INavItem {
    uid?: string;
    pageName?: string;
    //name?: string;
    displayName: string;
    link: string;
    enabled: boolean | (() => boolean);
    action?: string;
    onNext?: () => Promise<boolean>;
    subPage?: string;
    prodName?: string;
    icon?: any;
    ix?: number;
    complete?: boolean;
    done?: boolean;
    children?: INavItem[];
}

export interface IEVCNavEvent {
    name: string;
    states?: INavItem[],
    currentState?: INavItem,
    nextState?: INavItem,
    prevState?: INavItem
}
