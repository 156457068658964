<ng-container *ngIf="page != 'sapSearch'">
  <div class="around-small">
    <button [disabled]="prevNav == null" (click)="prevPage()" class="spot-button spot-button--secondary spot-button--with-icon navButton">
	  <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
		<title>Previous</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#previous"></use>
	  </svg>
	  <span class="spot-button__text"> Previous </span>
	</button>
  </div>
  <div *ngIf="page != 'done'" class="around-small">
	<button class="spot-button spot-button--primary spot-button--with-icon navButton" [disabled]="isValid == false || nextNav == null " (click)="nextPage()">
	  <span class="spot-button__text"> Continue </span>
	  <svg class="spot-icon spot-button__icon spot-button__icon--right" aria-labelledby="title">
		<title>Next</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#next"></use>
	  </svg>
	</button>
  </div>

</ng-container>

