import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppFacade } from '../../../facade/app.facade';
import { ISession, SessionUtils } from '@common/model/session';

@Component({
    selector: 'evc-edit-growth',
    templateUrl: './edit-growth.component.html',
    styleUrls: ['./edit-growth.component.scss']
})
export class EditGrowthComponent implements OnInit {

    session: ISession;
    saving: boolean = false;

    profitGrowth: number;
    percentGrowth: number;
    yearsGrowth: number;

    years: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    constructor(
        private activeModal: NgbActiveModal,
        private appFacade: AppFacade
    ) { }

    ngOnInit(): void {
        this.session = this.appFacade.getSession();
        if (this.session) { // For testing
            this.percentGrowth = this.session.growthPercent;// || 5;
            this.yearsGrowth = this.session.growthYears;// || 6;
            this.profitGrowth = this.session.profitGrowth || 0;
        } else {
            this.percentGrowth = 5;
            this.yearsGrowth = 6;
            this.profitGrowth = 0;
        }

        this.ensureValues();

    }

    closeModal() {
        console.log("closeModal");
        this.activeModal.close(false);
    }

    ensureValues() {
        if (this.percentGrowth === null || this.percentGrowth === undefined) {
            this.percentGrowth = 5;
        }
        if (this.yearsGrowth === null || this.yearsGrowth === undefined) {
            this.yearsGrowth = 6;
        }
        if (this.profitGrowth === null || this.profitGrowth === undefined) {
            this.profitGrowth = 0;
        }
    }

    async save() {
        this.ensureValues();

        this.session.growthPercent = this.percentGrowth;
        this.session.growthYears = this.yearsGrowth;
        SessionUtils.calcTotals(this.session);

        this.saving = true;
        await this.appFacade.saveSession();
        this.saving = false;

        this.activeModal.close(true);
    }

    recalcTotals() {
        console.log("recalcTotals()");
        console.log("percentGrowth=", this.percentGrowth);
        if (this.yearsGrowth > 10) {
            this.yearsGrowth = 10;
        } else if (this.yearsGrowth < 0) {
            this.yearsGrowth = 0;
        }

        try {
            this.profitGrowth = SessionUtils.calcGrowth(this.yearsGrowth,
                this.percentGrowth,
                this.session.totalSavings);
            console.log("this.profitGrowth=", this.profitGrowth);
        } catch (err) {
            console.log("Error: ", err);
        }
    }

}
