<button class="spot-modal__header-cancel-button"
		title="Close"
		style="float:right;"
		(click)="closeModal()">
  <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
	<title>Close</title>
	<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#cancel"></use>
  </svg>
</button>
<div class="evc-email-modal">
  <div>
	<header class="email-header" >
	  <h1>Email summary confirmation</h1>
	</header>
	<div class="email-body">
	  <p>Confirm sending summary PDF to {{email}}</p>
	  <div *ngIf="isCanada == true">
		<br/>
		<mat-radio-group
		  aria-label="Select an option"
		  style="display:flex;justify-content:space-around;width:200px;margin:auto"
		  [(ngModel)]="selectedLang">
		  <mat-radio-button value="en-CA">English</mat-radio-button>
		  <mat-radio-button value="fr-CA">French</mat-radio-button>
		</mat-radio-group>
	  </div>
	</div>
	<footer class="email-button-wrapper">
	  <button class="spot-modal__footer-cancel-button spot-button--large" title="Cancel" (click)="closeModal()">Cancel</button>
	  <button class="spot-button spot-button--primary spot-button--large"
			  title="Send"
			  [disabled]="sending"
			  (click)="sendEmail()">Send</button>
	</footer>
  </div>
