<div class="spot-modal" style="max-width:100%;width:100%;overflow:auto;">
  <header class="spot-modal__header">
	<div class="spot-modal__titles">
	  <h2 class="spot-modal__title adjust-heading" style="font-size:30px;">Additional profit generated from recommended follow-up actions.</h2>
	</div>
	<button class="spot-modal__header-cancel-button"
			title="Close"
			(click)="closeModal()">
	  <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
		<title>Close</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#cancel"></use>
	  </svg>
	</button>
  </header>
  <div class="spot-modal__content" style="overflow:initial;">
	<div class="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top"></div>
	<div class="spot-modal__content-wrapper" style="margin:1em;">
	  <div class="profit-table">
		<table>
		  <tr>
			<th style="width:58%;font-size:26px;" class="adjust-heading">Recommended follow-up</th>
			<th style="width:42%;text-align:right;font-size:20px;">
			  Additional profit per test ordered *
			</th>
		  </tr>
		  <tr>
			<td class="actName" style="font-size:20px;font-weight:bold;">
			  {{ selectedActivity?.display_name }}
			  <div style="font-size:13px;">* Net Profit x expected incremental findings (for this activity) = Additional profit per test ordered</div>
			</td>
			<td style="text-align:right;font-size:26px;" class="highlightNumbers inputNumber">
			  {{profitPer | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
			</td>
		  </tr>
		</table>
	  </div>
	  <form id="priceForm"  name="priceForm" style="padding:10px;font-size:18px;" class="price">
		<table>
		  <tr>
			<td>
			  <label for="totalRevenue" style="font-size:26px;" class="adjust-heading">Revenue</label>
			  <p style="font-size:20px;padding-left:20px;">Enter the total revenue generated for your practice with this action.  Be sure to include any practice and appointment charges along with the total panel profit.</p>
			</td>
			<td style="width:120px;"></td>
			<td class="highlightNumbers inputNumber" align="right" style="font-size:26px;width:125px;">
			  <div class="form-group" >
				<div class="input-group" style="border: solid green 2px;padding:1px;border-radius:.25em;">
				  <div class="input-group-prepend">
					<span class="input-group-text">$</span>
				  </div>
				  <input
					type="number"
					step="1"
					min="0"
					class="form-control"
					name="totalRevenue"
					[(ngModel)]="totalRevenue"
					(focus)="ensureVisible($event)"
					(change)="recalcTotals()"/>
				</div>
			  </div>
			</td>
		  </tr>
		  <tr>
			<td colspan=3>&nbsp;</td>
		  </tr>
		  <tr>
			<td>
			  <label for="totalCost" style="font-size:26px;" class="adjust-heading">Cost</label>
			  <p style="font-size:20px;padding-left:20px;">Enter the total cost for your practice with this action.</p>
			</td>
			<td  style="width: 120px;font-size:48px;padding-right:10px;" class="highlightNumbers inputNumber">- </td>
			<td class="highlightNumbers inputNumber" style="font-size:26px;width:125px;">
			  <div class="form-group" style="margin-top:auto;margin-bottom:auto;">
				<div class="input-group" style="border: solid red 2px;padding:1px;border-radius:.25em;">
				  <div class="input-group-prepend">
					<span class="input-group-text">$</span>
				  </div>
				  <input
					type="number"
					step="1"
					min="0"
					class="form-control"
					name="totalCost"
					[(ngModel)]="totalCost"
					(focus)="ensureVisible($event)"
					(change)="recalcTotals()"/>
				</div>
			  </div>
			</td>
		  </tr>
		  <tr>
			<td colspan=3>&nbsp;</td>
		  </tr>
		  <tr *ngIf="totalProfit">
			<td></td>
			<td style="width:120px;"></td>
			<td style="margin-top:10px;border-top:10px double black;width:125px;" align="right"></td>
		  </tr>
		  <tr *ngIf="totalProfit">
			<td colspan=2 style="font-weight:bold;font-size:26px;text-align:right;padding-right:10px;">Net Profit</td>
			<td style="font-size:26px;font-weight:bold;text-align:right;display:flex;flex-direction:row;justify-content:flex-end;width:125px;">
			  {{totalProfit | currency : session?.currencyCd : 'symbol-narrow' : '1.0-0' : session?.locale}}
			</td>
		  </tr>
		</table>

	  </form>
	</div>
	<div class="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom"></div>
  </div>
  <footer class="spot-modal__footer">
	<button class="spot-modal__footer-cancel-button spot-button--large" title="Cancel" (click)="closeModal()">Cancel</button>
	<button class="spot-button spot-button--primary spot-button--large"
			title="Send"
			[disabled]="saving || invalid"
			(click)="save()">Save</button>
  </footer>
</div>
