<form id="productForm" #productForm="ngForm" name="productForm">
  <div class="form-group">
	<label for="name">Name</label>
	<input type="text" class="form-control" name="name" [(ngModel)]="product.name"/>
  </div>
  <div class="form-group">
	<label for="displayName">Display Name</label>
	<input type="text" class="form-control" name="displayName" [(ngModel)]="product.displayName"/>
  </div>
  <div class="form-group">
	<label for="localName">Local Name</label>
	<input type="text" class="form-control" name="localName" [(ngModel)]="product.localName"/>
  </div>
  <div class="form-group">
	<label for="description">Description</label>
	<input type="text" class="form-control" name="description" [(ngModel)]="product.description"/>
  </div>
  <div class="form-group">
	<label for="display_order">Display Order</label>
	<input type="text" class="form-control" name="display_order" [(ngModel)]="product.display_order"/>
  </div>
  <div class="form-group">
	<label for="active">Active</label>
	<input type="text" class="form-control" name="active" [(ngModel)]="product.active"/>
  </div>

  <h2>Activities</h2>
  <table class="table table-hover">
	<thead>
	  <tr>
		<th scope="col">ID</th>
		<th scope="col">Name</th>
		<th scope="col">Developer Name</th>
		<th scope="col">Compliance</th>
		<th scope="col">Active?</th>
		<th scope="col">Savings</th>
		<th scope="col">Currency</th>
		<th scope="col">Display Order</th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let act of product.activities" (click)="editActivity(act)">
		<td>{{act.evc_activity_id}}</td>
		<td>{{act.display_name}}</td>
		<td>{{act.name}}</td>
		<td>{{act.compliance}}</td>
		<td>{{act.active}}</td>
		<td>{{act.savings}}</td>
		<td>{{act.currency_cd}}</td>
		<td>{{act.display_order}}</td>
	  </tr>
	</tbody>
  </table>

  <h2>Product Details</h2>
  <table class="table table-hover">
	<thead>
	  <tr>
		<th scope="col">ID</th>
		<th scope="col">Country</th>
		<th scope="col">Name</th>
		<th scope="col">URL</th>
		<th scope="col">Product Dashboard</th>
		<th scope="col">Patient Results Dashboard</th>
		<th scope="col">Incremental Percent</th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let pd of product.detailsList" (click)="editProductDetails(pd)">
		<td>{{pd.evc_product_detail_id}}</td>
		<td>{{pd.country_cd}}</td>
		<td>{{pd.name }}</td>
		<td>{{pd.video_url}}</td>
		<td>{{pd.product_dashboard_url}}</td>
		<td>{{pd.patient_results_dashboard_url}}</td>
		<td>{{pd.inc_percent}}</td>
	  </tr>
	</tbody>
  </table>

</form>

<button class="spot-button spot-button--primary" (click)="saveProduct()" [disabled]="!saveEnabled" >Save</button>
<button class="spot-button spot-button--secondary" (click)="cancel()" >Cancel</button>
