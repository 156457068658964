import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppFacade } from '../../../facade/app.facade';
import { EVCAlertService } from '../../../service/alert.service';

@Component({
    selector: 'evc-email-dialog',
    templateUrl: './email-dialog.component.html',
    styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit {

    @Input() email: string;
    @Input() isCanada: boolean = false;

    selectedLang: string = 'en-US';
    sending: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private appFacade: AppFacade,
        private alertService: EVCAlertService
    ) { }

    ngOnInit(): void {
        if (this.isCanada === true) {
            this.selectedLang = 'fr-CA';
        }
    }

    closeModal() {
        console.log("closeModal");
        this.activeModal.close(false);
    }

    async sendEmail() {
        console.log("sendEmail");
        this.sending = true;

        this.alertService.setBusy(true, 'Submitting...');

        try {
            var result = await this.appFacade.sendEmail(this.selectedLang);
            console.log("sendEmail result=", result);
            if (result == true) {
                this.alertService.showToast(null, 'Email sent successfully');
                this.closeModal();
            } else {
                this.alertService.showError(null, 'Failed to send email');
            }
        } catch (err) {
            console.error("Error sending email", err);
            this.alertService.showError(null, 'Failed to send email');
        } finally {
            this.alertService.setBusy(false);
            this.sending = false;
        }
    }
}
