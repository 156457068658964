<h1>Admin Tool</h1>

<a [routerLink]="['/admin/products']">Products</a>
<br/>
<a [routerLink]="['/admin/activities']">Activities</a>
<br/>
<!-- <a [routerLink]="['/admin/product-details']">Product Details</a> -->
<!-- <br/> -->
<a [routerLink]="['/admin/resource']">Resource</a>
<br/>
<a [routerLink]="['/admin/codes']">Code Definitions</a>
<br/>
<a [routerLink]="['/admin/usage']">Product Usage</a>
<br/>
<a [routerLink]="['/admin/sessions']">Sessions</a>
<br/>
<a [routerLink]="['/admin/user']">Users</a>
<br/>
