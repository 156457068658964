<div class="ps-modal">
  <header class="ps-header" >
    <div class="ps-header-title">
	  Incomplete EVC session found
	</div>
  </header>

  <div class="ps-modal-body">
	Last update: {{oldSession?.updated_on | date}} - {{oldSession?.updated_by.name || oldSession?.created_by.name}}
	<br/>
	<br/>
	<div style="display:flex;flex-direction:row;align-items:center;">
	  <span style="flex:1">Would you like to review the incomplete session?</span>
	</div>
  </div>
  <footer class="ps-footer">
    <a class="spot-link" style="background-color:inherit;" (click)="cancelPartial()">No, discard the incomplete session</a>
	  <button class="spot-button spot-button--primary" (click)="retrievePartial()" ngbAutofocus >Yes</button>

  </footer>
</div>
