<div class="login">
	<div class="sapId">
	  <div class="sapHeader">
		<div class="idexxLogo">
		  <img src="/assets/images/idexx_us.png" class="headerLogo" />
		</div>
	  </div>
	  <div>
		<h1 style="font-size:12px;text-align:center;">IDEXX <strong>Preventative Care Challenge</strong></h1>
	  </div>
	  <form (ngSubmit)="submit()" style="padding:10px;margin-left:auto;margin-right:auto;width:300px;">
		<h4 *ngIf="error">{{error}}</h4>

		<div class="form-group" style="text-align:center">
		  <button class="spot-button spot-button--primary login-button" type="submit">Login</button>
		</div>
	  </form>

	</div>

</div>
