<div class="evcHome" style="display:flex;flex-direction:column;">
  <button class="spot-button spot-button--link" *ngIf="hasAdmin" (click)="showAdmin()" style="justify-content: end;color:black">Admin Tool</button>
  <div class="slds-align_absolute-center" style="flex:1;">
	<div class="sapId">
	  <div class="sapHeader">
		<img src="/assets/images/IDEXX_logo.resource" class="headerLogo" />
		<h1 style="font-size:1.3em;">Economic Value Calculator</h1>
	  </div>
	  <div class="sapInput-wrapper">
		<div class="sapInput" style="text-align:center;">
		  <div style="font-weight:400;margin:0 0 12px 0;">Enter SAP ID</div>
		  <input
			class="form-control highlightSavings2"
			style="font-size:1em"
			type="number"
			id="sapId"
			name="sapId"
			min="0"
			(selectItem)="accountSelected($event)"
			[(ngModel)]="sapId"
			(keyup)="sapIdKey($event)"
			required
			autofocus/>
			<!--UNCOMMENT for incremental search 
				[ngbTypeahead]="findPartial"
				[resultTemplate]="rt"
			  -->
		  <ng-template #rt let-r="result" let-t="term">
			<div style="display:flex;width:100%">
			  <span style="flex:1">{{r.name }}</span>&nbsp;&nbsp;<ngb-highlight [result]="r.sap_id" [term]="t"></ngb-highlight>
			</div>
		  </ng-template>
		</div>

		<div *ngIf="accountError" class="spot-message priority-high--danger animated">
		  <div class="message--content">
			<div class="message--body">{{ accountError }}</div>
		  </div>
		  <div class="icon-tab">
			<svg class="spot-icon" aria-labelledby="title">
			  <title>Error</title>
			  <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#alert-notification"></use>
			</svg>
		  </div>
		</div>

		<div style="margin:1em;">
		  <button (click)="findAccount()" class="spot-button spot-button--primary" [disabled]="searching || !sapId">Continue&nbsp; <i class="fas fa-chevron-right"></i></button>
		</div>
	  </div>
	</div>
  </div>
  <div *ngIf="systemError" class="slds-notify slds-notify_alert bottom-alert" role="alert" id="bottomError">
	<span class="slds-assistive-text">error</span>
	<span class="slds-icon_container slds-icon-utility-error slds-m-right_x-small" >
	  <span title="Error!" class="danger"><i class="fas fas-times"></i></span>

	</span>
	<h2>{{ systemError }}
	  <!-- <a href="javascript:void(0);">More Information</a> -->
	</h2>
	<div class="slds-notify__close">
	  <button
		class="closeBottomError"
		title="Close"
		(click)="closeLoadError()"><i class="fas fa-times"></i></button>
	</div>
  </div>
  <div class="bottom-panel">
	<div class="outside-link" role="alert" id="outsideLink">
	  <a href="https://evcpc.idexx.com" target="new"><img src="/assets/images/evc.png" style="width:5em;height:5em"></a>
	  <span>
		<a href="https://evcpc.idexx.com" target="new">Go to the Economic Value Calculator for Preventive Care</a>
	  </span>
	</div>
  </div>
</div>
