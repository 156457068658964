import { Component, OnInit } from '@angular/core';
import { AdminFacade } from '../../../facade/admin.facade';

import { Sort } from '@angular/material/sort';

import { ITestCode } from '@common/model/test-code';

@Component({
    selector: 'evc-codes',
    templateUrl: './codes.component.html',
    styleUrls: ['./codes.component.scss']
})
export class CodesComponent implements OnInit {
    codes: ITestCode[];
    selectedCode: ITestCode;

    sortedData: ITestCode[] = [];
    lastSort: Sort;

    constructor(
        private adminFacade: AdminFacade
    ) { }

    async ngOnInit() {
        try {
            var prodResp = await this.adminFacade.getCodes();
            this.codes = prodResp.codes;
            this.sortedData = this.codes.slice();

            this.resort();
        } catch (err) {
            console.error("Error retrieving products: ", err);
        }
    }

    selectCode(code: ITestCode) {
        console.log("selectCode: ", code);
        this.selectedCode = code;

        //TODO: Navigate to code-edit
    }

    resort() {
        console.log("resort");
        if (this.lastSort) {
            this.sortData(this.lastSort);
        } else {
            this.sortData({ active: "id", direction: "asc" })
        }
        console.log("lastSort not defined, not sorting...");
    }

    sortData(sort: Sort) {
        console.log("sortData: ", sort);
        const data = this.codes.slice();

        this.lastSort = sort;

        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a: ITestCode, b: ITestCode) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'id': return compare(a.evc_test_code_id, b.evc_test_code_id, isAsc);
                case 'name': return compare(a.name, b.name, isAsc);
                case 'code': return compare(a.code, b.code, isAsc);
                case 'contextCode': return compare(a.contextCode, b.contextCode, isAsc);
                case 'groupName': return compare(a.groupName, b.groupName, isAsc);
                case 'active': return compare(a.active, b.active, isAsc);
                case 'level': return compare(a.level, b.level, isAsc);
                case 'created_on': return compare(a.created_on, b.created_on, isAsc);
                case 'updated_on': return compare(a.updated_on, b.updated_on, isAsc);
                default: return 0;
            }
        });
    }
}
function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
