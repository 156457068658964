import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'evc-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

    @Input() title = 'Important';
    @Input() body = 'Are you sure you want to continue?';
    @Input() body2 = 'Are you sure you want to continue?';
    @Input() cancelButton = { text: 'No', value: 'no', cls: 'btn-danger' };
    @Input() button1 = { text: 'Yes', value: 'yes', cls: 'spot-button--primary' };
    @Input() button2: any;// = { text: 'confirmation.No', value: 'no' };
    @Input() param: any;

    constructor(
        private activeModal: NgbActiveModal
    ) {
    }

    onButtonClick(button: 'cancelButton' | 'button1' | 'button2') {
        this.activeModal.close(this[button]);
    }

}
