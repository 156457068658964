<div class="loading-overlay" *ngIf="alertService.isBusy()">
  <div id='ajax_loader' class="loading-indicator" id="loading-indicator">
	<div>
      <svg class="spot-icon spot-loading-spinner spot-loading-spinner--large" aria-labelledby="title" style="fill:teal">
		<title>spinner</title>
		<use xlink:href="/assets/images/spot_icons.svg#spinner"></use>
      </svg>
	</div>
	<div style="margin-top: 1em;color:#2d2e2f;">
      <font size="+1" ><strong>{{alertService.busyMsg}}</strong></font>
	</div>
  </div>
  <!--
     -<div id='ajax_loader' class="loading-indicator" style="z-index: 400; background-color: rgba(175, 217, 238,.9); text-align: center;" id="loading-indicator">
     -  <i class="fas fa-4x fa-circle-notch fa-spin"></i>
     -  <div style="background-color: rgba(250,250,250,.05);border-radius: 10px;padding:5px;margin-top: 5px;">
     -  </div>
     -</div>
     -->
</div>

<a *ngIf="updateRequired" href="javascript:void(0)" (click)="onUpdateClick()" class="update-required-header">The application has been updated.  Complete any open enrollments and click here to refresh.</a>

<div class="router-outlet-wrapper">
<router-outlet></router-outlet>
</div>
