<div class="evcCard">
  <div class="prodTitle">
	<p class="heading">Thank you for your time!</p>
  </div>
  <div style="display:flex;flex-direction:row;margin:1em;">
	<p class="subheading" style="flex:1">
	  These results demonstrate how IDEXX diagnostics impact your practice.
	</p>
	<button class="spot-button spot-button--secondary spot-button--with-icon" (click)="editProducts()">
	  <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
		<title>Edit</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#edit"></use>
	  </svg>
	  <span class="spot-button__text"> Edit </span>

	</button>

  </div>
  <table class="evcSummaryTable">
	<tr class="headRow">
	  <th style="width:250px;">
		IDEXX test(s) evaluated
	  </th>
	  <th style="width:200px;text-align:right;">
		Recommended follow-up
	  </th>
	  <th style="width:50px;" class="space"></th>
	  <th style="width:12%;text-align: center;">
		Estimated compliance rate
	  </th>
	  <th style="width:2%;" class="space"></th>
	  <th style="width:14%;text-align: center;">
		Average tests per month
	  </th>
	  <th style="width:1%;" class="space"></th>
	  <th style="width:14%;text-align:right;">
		Additional profit per month
	  </th>
	</tr>
	<ng-container *ngFor="let sProd of session?.selectedProducts">
	  <ng-container *ngIf="sProd.done == true">
		<tr class="prodRow">
		  <td class="prodTotal2" valign="top">
			<table class="prodItemTable">
			  <tr>
				<td rowSpan="2" style="padding-right: 15px;width:40px;">
				  <mat-icon aria-hidden="false" aria-label="{{sProd.name}}" [svgIcon]="sProd.iconName + '.svg'" class="mediumIcon fillTeal"></mat-icon>
				</td>
				<td class="prodName">
		  		  {{ sProd.displayName }}
				</td>
			  </tr>
			  <tr>
				<td class="highlightNumbers">
				  {{sProd.flatProfitPer | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
				</td>
			  </tr>
			</table>
		  </td>
		  <td class="prodTotal2">
			<table>
			  <tr *ngFor="let act of sProd.activities">
				<td>
				  <div class="actName" style="text-align:right;">
					{{ act.display_name }}
					<br/>
					<span class="highlightNumbers">
					  {{act.savings | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
					</span>
				  </div>
				</td>
			  </tr>

			</table>
		  </td>
		  <td class="calcSymbol">X</td>
		  <td class="prodTotal2">
			<table>
			  <tr *ngFor="let act of sProd.activities">
				<td>
				  <div class="actName">
					<!-- &nbsp;<br/> -->
					<span class="highlightNumbers">{{ act.compliance }}%</span>
				  </div>
				</td>
			  </tr>
			</table>
		  </td>
		  <td class="calcSymbol">X</td>
		  <td class="prodTotal2 highlightNumbers" align="center">
		  	{{ sProd.runsPerMonth  | number:'1.0':'en-US'}}
		  </td>
		  <td class="calcSymbol">=</td>
		  <td class="amount highlightNumbers prodTotal" style="text-align: right;padding: 36px 5px 3px 5px !important;">
			{{sProd.totalSavings | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
		  </td>
		</tr>
		<tr *ngIf="sProd.video_url">
		  <td colspan="8" align="left" style="padding-top: 30px">
			<a href="{{ sProd.video_url }}" target="_new">
			  <div style="display:inline-flex;">
				<div>
				  <mat-icon aria-hidden="false" aria-label="Video" svgIcon="video_play.svg" class="smallIcon"></mat-icon>
				</div>
				<div class="video">
				  Learn how {{ sProd.displayName }} profitability is calculated
				</div>
			  </div>
			</a>
		  </td>
		</tr>
		<tr>
		  <td colspan="8">&nbsp;</td>
		</tr>
	  </ng-container>
	</ng-container>
  </table>
  <div style="display:flex;flex-direction:row;">
	<div class="add-box" style="flex:3;">
	  <p style="font-size:18px;font-weight:bold;">Additional pets helped*</p>
	  <div style="display:flex;flex-direction">
		<img src="/assets/images/canine-feline0.png" height="75" style="margin-right:2em;">
		<div style="flex:1;">
		  <span class="highlightNumbers">{{session?.totalPetsHelped}}</span> per month
		</div>
	  </div>
	  <p style="font-size: .8em;line-height:1em;margin-top:2em;">*Estimate based on {{countryName}}-wide averages of incremental findings. Actual patient numbers at your practice may vary.</p>
	</div>
	<div class="add-box" style="flex:4;">
	  <p style="font-size:18px;font-weight:bold;">Additional profit from using IDEXX diagnostics</p>
	  <div class="add-row">
		<div class="add-icon">
		  <img src="/assets/images/plus.png" height="45">
		  <!-- <svg class="spot-icon spot-button__icon smallIcon" aria-labelledby="title" style="fill:#00A7B5;"> -->
		  <!-- 	<title>plus</title> -->
		  <!-- 	<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#plus"></use> -->
		  <!-- </svg> -->
		</div>
		<div class="add-desc">
		  Per month
		</div>
		<div class="add-value">
		  <span class="highlightNumbers">
			{{session?.totalSavings | currency : session?.currencyCd : 'symbol-narrow' : '1.2-2' : session?.locale}}
		  </span>
		</div>

	  </div>
	  <div class="add-row">
		<div class="add-icon" style="color:#00A7B5;">
		  <img src="/assets/images/report.png" height="45">
		</div>
		<div class="add-desc">
			Over {{session?.growthYears}} years
			<span style="text-align: right;font-size:12px;">&nbsp;({{session?.growthPercent}}% growth)</span>
					  <button class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon" (click)="editGrowth()" style="background-color:inherit;box-shadow:none;width:20px;">
			<svg class="spot-icon spot-button__icon" aria-labelledby="title">
			  <title>Edit</title>
			  <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#edit"></use>
			</svg>
		  </button>
		</div>
		<div class="add-value">
			<span class="highlightNumbers">
			  {{session?.profitGrowth | currency : session?.currencyCd : 'symbol-narrow' : '1.0-0' : session?.locale}}
			</span>
		</div>

	  </div>
	</div>
  </div>
  <div style="text-align:right;">
	<button (click)="emailEVC()" class="spot-button spot-button--primary spot-button--sales spot-button--with-icon">
	  <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
		<title>Email</title>
		<use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#email"></use>
	  </svg>
	  <span class="spot-button__text"> Email </span>
	</button>
  </div>
</div>
