import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'callback', component: AuthCallbackComponent
    }
    // {
    //     path: '**', redirectTo: 'login'
    // }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
